import { default as Vuex, Module, ActionContext } from "vuex";

const getRestProtocol = (secure: boolean) => (secure ? "https" : "https");
const getWebSocketProtocol = (secure: boolean) => (secure ? "wss" : "wss");

class SettingsState {
  hsl = {
    address: "pi.gigalot.systems"
  };
  allflex = {
    port: "8003"
  };
  scale = {
    port: "8002"
  };
  drafter = {
    port: "8009"
  };
  rest = {
    //Hardware Service Layer
    port: "9000",
    processTag: "/HardwareServiceLayer/api/Process/Cattle",
    desktopReaderReadTag: "/HardwareServiceLayer/api/Process/sgtin",
    readTag: "/HardwareServiceLayer/api/Rfid/sgtin",
    openGate: "/HardwareServiceLayer/api/Process/Gate/?Gate=",
    startDrafter: "/HardwareServiceLayer/api/Drafter/Start",
    stopDrafter: "/HardwareServiceLayer/api/Drafter/Stop",
    pauseDrafter: "/HardwareServiceLayer/api/Drafter/Pause",
    getDraftSetup: "/HardwareServiceLayer/api/Drafter/Setup",
    getBatchInfo: "/HardwareServiceLayer/api/Drafter/BatchInfo",
    clearBatch: "/HardwareServiceLayer/api/Drafter/Reset"
  };
  numAnimalsPerDownload: number = 5000;
}

class Settings implements Module<SettingsState, any> {
  namespaced = true;
  state: SettingsState = new SettingsState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    // hslAddress(state: SettingsState, hslAddress: any) {
    //   state.hsl.address = hslAddress;
    // },
    allflex(state: SettingsState, allflex: any) {
      Object.assign(state.allflex, allflex);
    },
    scale(state: SettingsState, scale: any) {
      Object.assign(state.scale, scale);
    },
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
  };
  getters = {
    /*
    getter(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    hslAddress(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return state.hsl.address;
      };
    },
    scaleAddress(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getWebSocketProtocol(getters["secure"]())}:${getters["hslAddress"]()}:${state.scale.port}`;
      };
    },
    allflexAddress(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getWebSocketProtocol(getters["secure"]())}:${getters["hslAddress"]()}:${state.allflex.port}`;
      };
    },
    processTagUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.processTag}`;
      };
    },
    openGateUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.openGate}`;
      };
    },
    desktopReaderReadTagUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.desktopReaderReadTag}`;
      };
    },
    readTagUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.readTag}`;
      };
    },
    configDataUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}/processing-app-config-data`;
      };
    },
    proxyCertUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}`;
      };
    },
    startDrafterUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.startDrafter}`;
      };
    },
    stopDrafterUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.stopDrafter}`;
      };
    },
    pauseDrafterUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.pauseDrafter}`;
      };
    },
    getDraftSetupUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.getDraftSetup}`;
      };
    },
    putDraftSetupUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.getDraftSetup}`;
      };
    },
    getBatchInfoUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.getBatchInfo}`;
      };
    },
    clearBatchUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getRestProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.rest.port}${state.rest.clearBatch}`;
      };
    },
    drafterSocketUrl(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return `${getWebSocketProtocol(getters["secure"]())}://${getters["hslAddress"]()}:${state.drafter.port}`;
      };
    },
    secure(state: SettingsState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        //if environment not testing then connection must be secure
        return rootState.environment !== "testing";
        //return true;
      };
    }
  };
}

export default new Settings()
