import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ProcessAnimal from "../views/ProcessAnimal.vue";
import DraftAnimal from "../views/DraftAnimal.vue";
import SelectVaccination from "../views/SelectVaccination.vue";
import Settings from "../views/Settings.vue";
import SelectBatchSetup from "../views/SelectBatchSetup.vue";
import BatchSetup from "../views/BatchSetup.vue";
import History from "../views/History.vue";
import Hospital from "../views/Hospital.vue";
import Retag from "../views/Retag.vue";
import Login from "../views/Login.vue";
import Location from "@/views/Location.vue";
import Certificates from "../views/Certificates.vue";
import Sync from "../views/Sync.vue";
import store from "@/store";
import { updateAppBarText } from "@/helpers/app-bar-text";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: ["/index.html", "/index"],
    name: "home",
    component: Home,
    meta: { requiresRegistration: true }
  },
  {
    path: "/process-animal",
    name: "process-animal",
    component: ProcessAnimal,
    meta: { requiresRegistration: true }
  },
  {
    path: "/draft-animal",
    name: "draft-animal",
    component: DraftAnimal,
    meta: { requiresRegistration: true }
  },
  {
    path: "/select-vaccination",
    name: "select-vaccination",
    component: SelectVaccination,
    meta: { requiresRegistration: true }
  },
  {
    path: "/select-batch-setup",
    name: "select-batch-setup",
    component: SelectBatchSetup,
    props: true,
    meta: { requiresRegistration: true }
  },
  {
    path: "/batch-setup",
    name: "batch-setup",
    component: BatchSetup,
    props: true,
    meta: { requiresRegistration: true }
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { requiresRegistration: true }
  },
  {
    path: "/history",
    name: "history",
    component: History,
    meta: { requiresRegistration: true }
  },
  {
    path: "/hospital",
    name: "hospital",
    component: Hospital,
    meta: { requiresRegistration: true }
  },
  {
    path: "/retag",
    name: "retag",
    component: Retag,
    props: true,
    meta: { requiresRegistration: true }
  },
  {
    path: "/certificates",
    name: "certificates",
    component: Certificates,
    meta: { requiresRegistration: true }
  },
  {
    path: "/sync",
    name: "sync",
    component: Sync,
    meta: { requiresRegistration: true }
  },
  {
    path: "/location",
    name: "location",
    component: Location
  },
  {
    path: "/login",
    name: "login",
    component: Login
  }
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name) updateAppBarText(to.name);

  ////////
  // Login
  let requiresRegistration: boolean = to.matched.some(record => record.meta.requiresRegistration);
  let isUserRegistered: boolean = store.getters["user/isUserRegistered"]();

  if (requiresRegistration && !isUserRegistered) {
    next("login");
    return;
  }
  //
  ////////

  //console.log(store);
  let state: any = store.state;
  if (state.processing.busyProcessing && to.name !== "login") {
    if (to.name === "settings" || to.name === "process-animal" || to.name === "retag") {
      next();
    } else {
      next({ name: "process-animal" });
    }
  } else next();
});

export default router;
