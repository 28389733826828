import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import * as RtcClient from "@gigalot/rtc-client";
import ReconnectingWebSocket from "reconnecting-websocket";
import mitt from "mitt";

Vue.config.productionTip = false;

let firebaseConfig: any;
if (store.state.environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyAnm2wf7gkEjHhNi59Fv2-U8-isAZhD6UA",
    authDomain: "gigalot-cloud.firebaseapp.com",
    databaseURL: "https://gigalot-cloud.firebaseio.com",
    projectId: "gigalot-cloud",
    storageBucket: "gigalot-cloud.appspot.com",
    messagingSenderId: "820134020112",
    appId: "1:820134020112:web:7b66654bf8c742a90e3695",
    measurementId: "G-29D3BKD2B5"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCxvvbSahUACHC8z76pXKVQex9XCGB5iY4",
    authDomain: "gigalot-testing.firebaseapp.com",
    databaseURL: "https://gigalot-testing.firebaseio.com",
    projectId: "gigalot-testing",
    storageBucket: "gigalot-testing.appspot.com",
    messagingSenderId: "17723559284",
    appId: "1:17723559284:web:6eccc09a12923e1f137021",
    measurementId: "G-TS0V73KY5J"
  };
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const rtcReadyCallbacks: any[] = [];
let rtcInitialized: boolean = false;

export function addRtcReadyCallback(callback: () => void) {
  if (rtcInitialized) callback();
  else rtcReadyCallbacks.push(callback);
}

function fireRtcReadyCallbacks() {
  rtcInitialized = true;
  let callback: () => void;
  while ((callback = rtcReadyCallbacks.shift())) callback();
}

type RtcEvents = {
  connectionEvent: "connected-cloud" | "connected-local" | "disconnected" | "connecting";
};
export const rtcEmitter = mitt<RtcEvents>();

type UploadEvents = {
  upload: "success" | "error";
};
export const uploadEmitter = mitt<UploadEvents>();

export function initRtc() {
  try {
    if (!(store.state as any)?.user?.location?.guid) {
      console.error("Can not init WebRTC, no locationGuid found");
      return;
    }
    store.commit("rtcSignalling", "connecting");
    RtcClient.initialize({
      locationGuid: (store.state as any).user.location.guid,
      // configuration: {
      //   iceServers: [
      //     {
      //       urls: ["turn:34.141.192.53:3478"],
      //       username: "gigalot",
      //       credential: "xN4m7kxH2wNRuCda"
      //     }
      //   ],
      //   iceCandidatePoolSize: 10
      // },
      firebaseConfig: firebaseConfig,
      server: "office",
      debug: {
        messaging: {
          sending: false,
          receiving: false,
        },
        signalling: false,
        iceCandidates: false,
        offers: false,
        answers: false,
      },
      onConnectionChange: (connected, signalling, state) => {
        console.log("onConnectionChange");
        console.log("connected", connected);
        console.log("signalling", signalling);

        if (state === "connecting") {
          store.commit("rtcSignalling", "connecting");
        } else if (state === "connected-local") {
          store.commit("rtcSignalling", "local");
          rtcEmitter.emit("connectionEvent", "connected-local");
        } else if (state === "connected-cloud") {
          store.commit("rtcSignalling", "cloud");
          rtcEmitter.emit("connectionEvent", "connected-cloud");
        } else if (state === "disconnected") {
          store.commit("rtcSignalling", "disconnected");
          rtcEmitter.emit("connectionEvent", "disconnected");
        }

        if (connected) fireRtcReadyCallbacks();
      },
      getJwt: () => store.dispatch("user/getOnlineIdToken", undefined, { root: true }),
      project: store.state.environment === "production" ? "gigalot-cloud" : "gigalot-testing",
      reconnect: {
        retryTime: 5000,
        maxRetryTime: 30 * 1000
      }
    });
  } catch (err) {
    console.error(err);
  }
}

store.dispatch("user/addFirebaseCallback", () => {
  initRtc();
});

firebase.auth().onAuthStateChanged(function (user) {
  console.log("firebase.auth().onAuthStateChanged()");
  store.dispatch("user/firebaseOnAuthStateChanged", user);
  store.dispatch("certificates/getCertificateCloud");
  //TODO: going to need to rethink this
});

store.commit("version");

//const hasConfig
async function getConfigFromHsl() {
  try {
    let response = await fetch(store.getters["settings/configDataUrl"]());
    let json;
    if (response.ok) {
      console.log("config data response ok");
      json = await response.json();
      if (!json.processingStation) throw Error(`Field "processingStation" not found in config JSON.`);
      if (!json.processingStation.description) throw Error(`Field "processingStation.description" not found in config JSON.`);
      if (!json.processingStation.guid) throw Error(`Field "processingStation.guid" not found in config JSON.`);

      if (!json.location) throw Error(`Field "location" not found in config JSON.`);
      if (!json.location.type) throw Error(`Field "location.type" not found in config JSON.`);
      if (!json.location.name) throw Error(`Field "location.name" not found in config JSON.`);
      if (!json.location.guid) throw Error(`Field "location.guid" not found in config JSON.`);

      //if (!json.hardware) throw Error(`Field "hardware" not found in config JSON.`);
      if (!json.gates) throw Error(`Field "gates" not found in config JSON.`);

      if (store.state.location && store.state.location.guid && store.state.location.guid !== json.location.guid) {
        throw Error(`Location guid mismatch! App's location: ${store.state.location.name}, Station's location: ${json.location.name}`);
      }

      const processingStation = store.getters["getField"]("processingStation");
      if (processingStation && processingStation.guid !== json.processingStation.guid)
        throw Error(`Error: Processing Station can not change. It was ${processingStation.description} but is now ${json.processingStation.description}`);

      //TODO: set to something like sortingGates.hslCurrentGates
      //store.commit("updateField", { path: "sortingGates.gates", value: json.gates });
      store.commit("updateField", { path: "sortingGates.currentGates", value: json.gates });
      store.commit("updateField", { path: "location", value: json.location });
      store.commit("updateField", { path: "processingStation", value: json.processingStation });
      //this.hasConfig = true;
    } else {
      console.error("config data response not ok");
      const getConfigMessage = "Error getting config data from HSL.";
      store.commit("popup/displayOk", getConfigMessage);
      throw Error(`Error: no config json. Url: ${store.getters["settings/configDataUrl"]()}`);
    }
  } catch (err) {
    const getConfigMessage = `v${store.state.version} getConfigFromHsl: ${err} - Url: ${store.getters["settings/configDataUrl"]()}`;
    store.commit("popup/displayOk", getConfigMessage);
    console.error(getConfigMessage);
    throw err;
  }
}

const webSocketConnectedToProxy = new ReconnectingWebSocket(`wss://${store.getters["settings/hslAddress"]()}:9000/webSocketConnectedToProxy`);
webSocketConnectedToProxy.addEventListener("open", event => {
  console.log("webSocketConnectedToProxy open");
  if (store.state.connectedToProxy !== true) store.commit("connectedToProxy", true);
  startMessageInterval();
  (async () => {
    await getConfigFromHsl();
  })();

  //store.dispatch("sync/onConnectedToProxy");
  store.dispatch("certificates/onConnectedToProxy");
});
webSocketConnectedToProxy.addEventListener("message", event => {
  console.log("webSocketConnectedToProxy message: ", event.data);
  const numInstances = parseInt(event.data);
  if (!isNaN(numInstances)) {
    store.commit("isSingleInstance", numInstances === 1);
  }
});
webSocketConnectedToProxy.addEventListener("close", event => {
  console.log("webSocketConnectedToProxy close");
  if (store.state.connectedToProxy !== false) store.commit("connectedToProxy", false);
  stopMessageInterval();
});

////////

let t: number | undefined;

function startMessageInterval() {
  if (t) return;
  t = setInterval(() => {
    try {
      webSocketConnectedToProxy.send("x");
    } catch (err) {
      console.error("failed to send heartbeat message:", err);
    }
  }, 10 * 1000);
}

function stopMessageInterval() {
  clearInterval(t);
  t = undefined;
}

////////

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
