import { default as Vuex, Module, ActionContext } from "vuex";
import { UploadableBatchSetup, UploadableHospitalResult, UploadableProcessedAnimal } from "@/models/uploadable";
import * as Models from "@gigalot/data-models";
import store from "@/store";
import mitt from "mitt";
import { uploadEmitter } from "@/main";

const sleep = async () => new Promise((resolve, _) => setTimeout(resolve, 250))

/*
This upload module is for uploading data from the app to the server. 
This module has nothing to do with the uploading of data from the app to the proxy.
*/
export class UploadState {
  isBusyUploading: boolean = false;
  numUnUploadedItems: number | "" = "";
}

async function uploadBatchSetup(batchSetup: UploadableBatchSetup, context: ActionContext<UploadState, any>) {
  //Get animals from IndexedDB and add them to batchSetup
  if (!batchSetup.metadata) batchSetup.metadata = store.getters["user/getUpstreamMetadata"]();
  let location = context.rootGetters["getField"]("location");
  if (!batchSetup.metadata.feedlot) batchSetup.metadata.feedlot = "";
  if (!batchSetup.metadata.gcp) batchSetup.metadata.gcp = "";

  if (batchSetup.processingResult && !batchSetup.processingResult.metadata.feedlot) batchSetup.processingResult.metadata.feedlot = "";
  if (batchSetup.processingResult && !batchSetup.processingResult.metadata.gcp) batchSetup.processingResult.metadata.gcp = "";

  if (batchSetup.processingResult) {
    for (let sc of batchSetup.processingResult.sortingConfig) {
      for (let c of sc.condition) {
        if ((c.min as any) === "") c.min = undefined;
        if ((c.max as any) === "") c.max = undefined;
      }
    }
  }

  delete batchSetup.uploaded;

  let json = await context.dispatch(
    "graphQl",
    {
      gql: `mutation batchSetup($guid: String!, $batchSetup: BatchSetupInput!) {
              batchSetup(guid: $guid, batchSetup: $batchSetup)
            }`,
      variables: { guid: location.guid, batchSetup: batchSetup },
      destination: "office-server",
      timeout: 10 * 1000
    },
    { root: true }
  );
  console.log("graphQL: " + JSON.stringify(json));
  batchSetup.uploaded = true;
  await context.dispatch("dataManager/saveData", { data: batchSetup, objectStore: "BatchSetup" }, { root: true });
}

async function uploadProcessedAnimal(processedAnimal: UploadableProcessedAnimal, context: ActionContext<UploadState, any>) {

  let location = context.rootGetters["getField"]("location");

  delete processedAnimal.uploaded;

  let json = await context.dispatch(
    "graphQl",
    {
      gql: `mutation processedAnimal($guid: String!, $processedAnimal: ProcessedAnimalInput!) {
              processedAnimal(guid: $guid, processedAnimal: $processedAnimal)
            }`,
      variables: { guid: location.guid, processedAnimal: processedAnimal },
      destination: "office-server",
      timeout: 10 * 1000
    },
    { root: true }
  );
  console.log("graphQL: " + JSON.stringify(json));
  processedAnimal.uploaded = true;
  await context.dispatch("dataManager/saveData", { data: processedAnimal, objectStore: "ProcessedAnimal" }, { root: true });
  await context.dispatch("dataManager/deleteData", { guid: processedAnimal.guid, objectStore: "UnUploadedProcessedAnimalGuids" }, { root: true });
};

async function uploadHospitalResult(hospitalResult: UploadableHospitalResult, context: ActionContext<UploadState, any>) {
  if (!hospitalResult.metadata) hospitalResult.metadata = store.getters["user/getUpstreamMetadata"]();
  if (!hospitalResult.metadata.feedlot) hospitalResult.metadata.feedlot = "";
  if (!hospitalResult.metadata.gcp) hospitalResult.metadata.gcp = "";

  let location = context.rootGetters["getField"]("location");
  delete hospitalResult.uploaded;
  let json = await context.dispatch(
    "graphQl",
    {
      gql: `mutation hospitalResult($guid: String!, $hospitalResult: HospitalResultInput!) {
              hospitalResult(guid: $guid, hospitalResult: $hospitalResult)
            }`,
      variables: { guid: location.guid, hospitalResult: hospitalResult },
      destination: "office-server",
      timeout: 10 * 1000
    },
    { root: true }
  );
  console.log("graphQL: " + JSON.stringify(json));
  hospitalResult.uploaded = true;
  await context.dispatch("dataManager/saveData", { data: hospitalResult, objectStore: "HospitalResult" }, { root: true });
}

class Upload implements Module<UploadState, any> {
  namespaced = true;
  state: UploadState = new UploadState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    setIsBusyUploading(state: UploadState, payload: boolean) {
      state.isBusyUploading = payload;
    },
    numUnUploadedItems(state: UploadState, payload: "" | number | "decrement" | "increment") {
      if (payload === "decrement") {
        if (state.numUnUploadedItems !== "") state.numUnUploadedItems--;
      } else if (payload === "increment") {
        if (state.numUnUploadedItems !== "") state.numUnUploadedItems++;
      } else {
        state.numUnUploadedItems = payload;
      }
    }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async upload(context: ActionContext<UploadState, any>) {
      if (context.state.isBusyUploading) return;

      const uploadTryThrice = async () => {
        const MAX_ATTEMPTS = 3;
        let attempt = 1;
        while (attempt <= MAX_ATTEMPTS) {
          try {
            await context.dispatch("uploadBatchSetups");
            await context.dispatch("uploadHospitalResults");
            await context.dispatch("uploadProcessedAnimals");
            break;
          } catch (error) {
            if (attempt === MAX_ATTEMPTS) {
              throw error;
            }
          }
          attempt++;
        }
      }

      console.log("upload");
      context.commit("setIsBusyUploading", true);
      try {
        // await context.dispatch("uploadBatchSetups");
        // await context.dispatch("uploadHospitalResults");
        // await context.dispatch("uploadProcessedAnimals");

        await uploadTryThrice();

        context.commit("setIsBusyUploading", false);
        uploadEmitter.emit("upload", "success");
      } catch (error) {
        context.commit("setIsBusyUploading", false);
        uploadEmitter.emit("upload", "error");
        throw error;
      }
    },
    async uploadBatchSetups(context: ActionContext<UploadState, any>) {
      let batchSetups: UploadableBatchSetup[] = await context.dispatch("dataManager/getData", { objectStore: "BatchSetup" }, { root: true });
      let batchSetupsToUpload = batchSetups.filter(batchSetup => !batchSetup.uploaded && batchSetup.finished);
      for (let i = 0; i < batchSetupsToUpload.length; ++i) {
        await uploadBatchSetup(batchSetupsToUpload[i], context);
        context.commit("numUnUploadedItems", "decrement");
        await sleep();
      }
    },
    async uploadHospitalResults(context: ActionContext<UploadState, any>) {
      let hospitalResults: UploadableHospitalResult[] = await context.dispatch("dataManager/getData", { objectStore: "HospitalResult" }, { root: true });
      let hospitalResultsToUpload = hospitalResults.filter(hospitalResult => !hospitalResult.uploaded);
      for (let i = 0; i < hospitalResultsToUpload.length; ++i) {
        await uploadHospitalResult(hospitalResultsToUpload[i], context);
        context.commit("numUnUploadedItems", "decrement");
        await sleep();
      }
    },
    async uploadProcessedAnimals(context: ActionContext<UploadState, any>) {

      const processedAnimalGuids: string[] = await context.dispatch(
        "dataManager/getUnUploadedProcessedAnimalGuids",
        undefined,
        { root: true }
      );

      for (const paGuid of processedAnimalGuids) {

        const processedAnimal: UploadableProcessedAnimal = await context.dispatch(
          "dataManager/getProcessedAnimal",
          { guid: paGuid },
          { root: true }
        );

        await uploadProcessedAnimal(processedAnimal, context);
        context.commit("numUnUploadedItems", "decrement");
        await sleep();
      }
    }
  };
  getters = {
    /*
    getter(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
}

export default new Upload();
