
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import * as sgtinH from "@/helpers/sgtin";
import { mapAllflexFields } from "@/store/modules/allflex";
import { drawChart, initializeChart } from "@/scale-graph";
import { getAnimalListItems } from "@/helpers/animal-list";

@Component({
  components: { DimssaButton },
  computed: {
    ...mapAllflexFields(["allflex"]),
  },
})
export default class Retag extends Vue {
  get currentAnimal() {
    return this.$store.getters["processing/getField"]("currentAnimal");
  }

  get currentHospitalResult() {
    return this.$store.getters["getField"]("currentHospitalResult");
  }
  /*
  Mode for different circumstances of retagging:
  standalone: an animal was found without a tag that needs to be retagged. The animal is not in a processing queue nor going to hospital
  processing: an animal in a processing queue needs to be retagged.
  hospital: a sick animal being treated that needs to be retagged
  */
  @Prop() mode!: "standalone" | "processing" | "hospital";
  mode_?: "standalone" | "processing" | "hospital";

  visualAllflex: string = "";
  visualTag: string = "";
  batchReference: string = "";
  breed: string = "";
  gender: string = "";
  sourceKraalId: string = "";
  notes: string = "";

  retag: Models.Retag | "" = "";

  selectedAnimal: any = ""; //{ sgtin: string; breed: string; gender: string, visualSgtin: string }
  animals: any[] = [];

  currentMass: number | "" = "";
  scaleActivityToggle: boolean = false;

  kraals: Models.Kraal[] = [];

  visual(sgtin: string) {
    return sgtinH.visual(sgtin);
  }

  get captureMassButtonState(): ButtonState {
    if (!this.$store.state.scale.connected) return "error";
    switch (this.$store.state.scale.massCaptureState) {
      case "not-capturing":
        return "ready";
      case "capturing":
        return "busy";
      case "captured":
        return "success";
      case "failed":
        return "error";
      default:
        return "ready";
    }
  }

  @Watch("$store.state.scale.massCaptureState")
  massCaptureStateChange(newVal: any, oldVal: any) {
    if (this.mode_ === "standalone" && newVal === "failed") this.$store.commit("log/message", { message: "Failed to capture mass.", type: "error" });
  }

  @Watch("$store.state.scale.capturedMass")
  onMassChanged(val: any, oldVal: any) {
    if (!isNaN(val)) this.$store.commit("log/message", { message: "Mass captured successfully.", type: "info" });
  }

  async onClickCaptureMass() {
    try {
      this.$store.commit("log/message", { message: "Capturing mass...", type: "info" });
      await this.$store.dispatch("scale/captureMass");
    } catch (err) {
      this.$store.commit("log/message", { message: "Error capturing mass: " + (err.message ? err.message : err), type: "error" });
    }
  }

  get scaleActivityColour() {
    return this.scaleActivityToggle ? "lime" : "white";
  }

  setMass(mass: number) {
    this.currentMass = mass;
    let scale = this.$store.state.scale;
    let hLines = [{ value: scale.minMass, colour: "grey", id: "horizontal-graph-line" }];
    if (scale.massCaptureState === "captured") hLines.push({ value: scale.capturedMass, colour: "lime", id: "min-graph-line" });
    drawChart(this.$store.getters["scale/historyMassQueue"](), hLines, scale.massQueueSize);
    //drawChart(this.$store.getters["scale/historyMassQueue"](), scale.massCaptureState === "captured", scale.capturedMass, scale.massQueueSize, scale.minMass);
    this.scaleActivityToggle = !this.scaleActivityToggle;
    let scaleMassText = document.getElementById("scaleMassText");
    if (scaleMassText) scaleMassText.innerHTML = `${mass} kg`;
    let scaleActivityCircle = document.getElementById("scaleActivityCircle");
    if (scaleActivityCircle) (scaleActivityCircle as any).attributes.fill.nodeValue = this.scaleActivityColour;
  }

  selectedAnimalChange(selectedAnimal: any) {
    //console.log("selectedAnimalChange: " + JSON.stringify(selectedAnimal));
    console.log(JSON.stringify(this.selectedAnimal));
    if (this.retag && this.selectedAnimal) this.retag.sgtin = this.selectedAnimal.sgtin;
    this.$store.commit("updateField", { path: `${this.retagPath(this.mode_)}.sgtin`, value: this.selectedAnimal ? this.selectedAnimal.sgtin : undefined });
    this.$store.commit("updateField", { path: `${this.retagPath(this.mode_)}.gender`, value: this.selectedAnimal ? this.selectedAnimal.gender : undefined });
    this.gender = this.selectedAnimal ? this.selectedAnimal.gender : "";
    this.breed = this.selectedAnimal ? this.selectedAnimal.breed : "";
    if (this.retag) {
      this.retag.gender = this.gender;
      this.retag.breed = this.breed;
    }
    this.$store.commit("updateField", { path: `${this.retagPath(this.mode_)}.breed`, value: this.selectedAnimal ? this.selectedAnimal.breed : undefined });
  }

  processTagButtonState: ButtonState = "ready";
  async programTag() {
    try {
      this.processTagButtonState = "busy";
      this.$store.commit("log/message", { message: "Programming tag...", type: "info" });
      let sgtin = await this.$store.dispatch("programTag", { busyProcessing: this.mode_ === "processing" });

      if (this.mode_ === "standalone") throw Error("TODO: programTag standalone retag");
      else if (this.mode_ === "processing") this.$store.commit("processing/updateField", { path: "currentAnimal.sgtin", value: sgtin });
      else if (this.mode_ === "hospital") this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.sgtin", value: sgtin });
      else throw Error("Retag programTag(): no mode_");

      this.processTagButtonState = "success";
      this.$store.commit("log/message", { message: `Tag successfully programmed.\n(${sgtin})`, type: "info" });
    } catch (err) {
      this.processTagButtonState = "error";
      //this.$store.commit("processing/updateField", { path: "currentAnimal.sgtin", value: "" });
      this.$store.commit("log/message", { message: "Error programming tag: " + (err.message ? err.message : err), type: "error" });
      throw err;
    }
  }

  clear() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: () => {
        this.$store.commit("popup/hide");
        this.processTagButtonState = "ready";
        this.$store.commit("allflex/allflex", "");
        this.selectedAnimal = "";
        this.visualAllflex = "";
        this.visualTag = "";
        this.batchReference = "";
        this.breed = "";
        this.gender = "";
        this.sourceKraalId = "";
        this.notes = "";
        let path = this.retagPath(this.mode_);
        this.$store.commit("updateField", { path: path, value: new Models.Retag() });
        this.retag = this.$store.getters["getField"](path) as Models.Retag;
        if (this.mode_ === "standalone") throw Error("TODO");
        else if (this.mode_ === "processing") this.$store.commit("processing/updateField", { path: "currentAnimal.sgtin", value: "" });
        else if (this.mode_ === "hospital") this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.sgtin", value: "" });
      },
    });
  }

  beforeCreate() {}

  retagPath(mode: any) {
    switch (mode) {
      case "standalone":
        return `retag`;
      case "processing":
        return `processing.currentAnimal.retag`;
      case "hospital":
        return `currentHospitalResult.morbidity.retag`;
      default:
        throw Error("retagPath(): no mode has been set for Retag.vue");
    }
  }

  state: "loading" | "ready" = "loading";

  async created() {
    this.state = "loading";
    if (this.mode) {
      this.$store.commit("updateField", { path: `retagMode`, value: this.mode });
      this.mode_ = this.mode;
    } else {
      this.mode_ = this.$store.getters["getField"]("retagMode");
    }
    //console.log("created(): mode_: " + this.mode_);
    if (!this.mode_) {
      this.$router.push({ name: "home" });
      throw Error("mounted(): no mode has been set for Retag.vue");
    }
    let path = this.retagPath(this.mode_);
    this.retag = this.$store.getters["getField"](path) as Models.Retag;
    if (!this.retag) {
      this.$store.commit("updateField", { path: path, value: new Models.Retag() });
      this.retag = this.$store.getters["getField"](path) as Models.Retag;
    }

    let visualAllflex = this.retag.otherTags.find((tag) => tag.type === "allflex" && tag.entry === "manual");
    this.visualAllflex = visualAllflex ? visualAllflex.value : "";
    let visualTag = this.retag.otherTags.find((tag) => tag.type === "visual" && tag.entry === "manual");
    this.visualTag = visualTag ? visualTag.value : "";
    if (this.retag.batchReference) this.batchReference = this.retag.batchReference;
    if (this.retag.breed) this.breed = this.retag.breed;
    if (this.retag.gender) this.gender = this.retag.gender;
    if (this.retag.sourceKraalId) this.sourceKraalId = this.retag.sourceKraalId;
    //mass?
    //notes
    if (this.retag.notes.length) this.notes = this.retag.notes[0];

    const hasIncomingAnimals = this.$store.state.processing?.currentBatchSetup?.batchDetails?.allowedAnimals?.length > 0;
    //If re-tag is being done for an incoming batch then only allow sgtins from that incoming batch to be in the list
    if (this.mode === "processing" && hasIncomingAnimals) {
      this.animals = this.$store.state.processing.currentBatchSetup.batchDetails.allowedAnimals;
      this.animals = this.animals.map((a) => ({ ...a, visualSgtin: sgtinH.visual(a.sgtin) }));
    }
    //Otherwise use sgtins from all animals currently at location
    //Remember that re-tag can't happen in a new intake batch (user can just program a different tag that works)
    else {
      // this.animals = await this.$store.dispatch("dataManager/mapOverAnimals", (a: Models.Animal & { visualSgtin?: string }) => {
      //   return {
      //     breed: a.breed,
      //     gender: a.gender,
      //     sgtin: a.sgtin,
      //     customFeeder: a.customFeeder,
      //     visualSgtin: a.visualSgtin,
      //   };
      // });
      this.animals = await getAnimalListItems();
    }
    console.log("animals.length: " + this.animals.length);

    if (this.retag.sgtin) {
      let animal = {
        sgtin: this.retag.sgtin,
        visualSgtin: sgtinH.visual(this.retag.sgtin),
      };
      this.animals = [animal, ...this.animals];
      this.selectedAnimal = animal;
    }

    if (this.mode === "processing") {
      /* TODO: remove animals that have already been processed in batch */
    }

    this.kraals = await this.$store.dispatch("dataManager/getData", { objectStore: "Kraal" });
    this.state = "ready";
  }

  async mounted() {
    initializeChart(800);

    console.log("if animal has sgtin from hospital or processing then don't allow retag. allow tag to be cleared in case wrong tag was read.");

    await this.$store.dispatch("scale/connect");
    this.$store.dispatch("allflex/connect");

    if (this.mode_ === "standalone") this.$store.commit("scale/massCallback", this.setMass);

    if (this.retag) {
      let allflexAuto = this.retag.otherTags.find((tag) => tag.type === "allflex" && tag.entry === "auto");
      if (allflexAuto) this.$store.commit("allflex/allflex", allflexAuto.value);

      switch (this.mode_) {
        case "standalone":
          throw Error("TODO");
        case "processing":
          if (this.currentAnimal.sgtin) this.processTagButtonState = "success";
          break;
        case "hospital":
          if (this.currentHospitalResult.morbidity.sgtin) this.processTagButtonState = "success";
          break;
      }
    }
  }

  destroyed() {
    this.$store.commit("scale/reset");
    this.$store.dispatch("scale/disconnect");
    this.$store.commit("scale/massCallback", undefined);

    this.$store.commit("allflex/reset");
    this.$store.dispatch("allflex/disconnect");
  }

  get colClass() {
    return this.$store.state.lightDarkMode === "light" ? "grey lighten-1" : "grey darken-4";
  }

  isRetagEmpty() {
    if (!this.retag) throw Error("isRetagEmpty(): No retag");
    let retag: Models.Retag = this.retag;
    let hasAnythingOfValue =
      retag.sgtin ||
      retag.visualSgtin ||
      retag.otherTags.length ||
      retag.batchReference ||
      retag.breed ||
      retag.gender ||
      retag.mass ||
      retag.sourceKraalId ||
      (retag.notes.length && retag.notes[0]) ||
      //|| (this.mode_ === "standalone")
      (this.mode_ === "processing" && this.currentAnimal.sgtin) ||
      (this.mode_ === "hospital" && this.currentHospitalResult.morbidity.sgtin);
    return !hasAnythingOfValue;
  }

  finish() {
    switch (this.mode_) {
      case "standalone":
        //TODO: ask user if sure, store and save this somewhere for standalone (remember StandaloneRetag)
        this.$router.push({ name: "home" });
        break;
      case "processing":
        //TODO: update current animal's retag
        this.$router.push({ name: "process-animal" });
        break;
      case "hospital":
        //TODO: update morbidity's retag
        this.$router.push({ name: "hospital" });
        break;
      default:
        throw Error("finish(): no mode has been set for Retag.vue");
    }

    if (!this.retag) throw Error("finish(): no mode has been set for Retag.vue");
    //this.retag.sgtin = "TODO"
    //this.retag.visualSgtin = "TODO";
    if (this.$store.state.allflex.allflex) {
      let allflexAutoIndex = this.retag.otherTags.findIndex((tag) => tag.type === "allflex" && tag.entry === "auto");
      if (allflexAutoIndex >= 0) {
        this.retag.otherTags[allflexAutoIndex].value = this.$store.state.allflex.allflex;
      } else {
        this.retag.otherTags.push({ entry: "auto", type: "allflex", value: this.$store.state.allflex.allflex });
      }
    }
    if (this.visualAllflex) {
      let allflexManualIndex = this.retag.otherTags.findIndex((tag) => tag.type === "allflex" && tag.entry === "manual");
      if (allflexManualIndex >= 0) {
        this.retag.otherTags[allflexManualIndex].value = this.visualAllflex;
      } else {
        this.retag.otherTags.push({ entry: "manual", type: "allflex", value: this.visualAllflex });
      }
    }
    if (this.visualTag) {
      let visualTagIndex = this.retag.otherTags.findIndex((tag) => tag.type === "visual" && tag.entry === "manual");
      if (visualTagIndex >= 0) {
        this.retag.otherTags[visualTagIndex].value = this.visualTag;
      } else {
        this.retag.otherTags.push({ entry: "manual", type: "visual", value: this.visualTag });
      }
    }
    this.retag.batchReference = this.batchReference;
    this.retag.breed = this.breed;
    this.retag.gender = this.gender;
    this.retag.sourceKraalId = this.sourceKraalId;
    this.retag.notes = [this.notes];

    /*
      check if retag is empty, if it is then set retag to undefined
      this will help with the cloud queries since then we can check if the retag object is not undefined and if so then we know a retag happened
    */
    let path = this.retagPath(this.mode_);
    this.$store.commit("updateField", { path: path, value: this.isRetagEmpty() ? undefined : this.retag });
    //reset retag mode
    this.$store.commit("updateField", { path: `retagMode`, value: undefined });

    this.$store.commit("allflex/allflex", "");
  }
}
