
import { Component, Vue, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import { mapProcessingFields } from "@/store/modules/processing";
import * as Models from "@gigalot/data-models";
import { gatesSummary } from "@/helpers/gates-summary";
import AnimalDraftIcons from "@/components/AnimalDraftIcons.vue";
import { initializeChart, drawChart } from "../scale-graph";
import lodash from "lodash";

@Component({
  components: {
    DimssaButton,
    AnimalDraftIcons,
    Keypress: () => import("vue-keypress"),
  },
  computed: {
    ...mapProcessingFields(["currentBatchSetup.batchDetails"]),
  },
})
export default class DraftAnimal extends Vue {
  gender: string = "";
  breed: string = "";
  visualTag: string = "";
  state: string = "";
  gateColor: string = "";
  vaccinationDescription: string = "";

  async mounted() {
    //send batch setup to drafter
    this.$store.commit("drafting/updateField", { path: "currentAnimal", value: "" });
    this.$store.commit("drafting/updateField", { path: "currentGate", value: "" });
    await this.$store.dispatch("drafting/connect");
    await this.$store.dispatch("drafting/putDraftSetup");
    await this.$store.dispatch("drafting/getBatchInfo");
    this.$store.commit("scale/reset");
    await this.$store.dispatch("scale/connect");
    this.$store.commit("scale/massCallback", this.setMass);
    await this.calcGatesSummary(null, null);
    initializeChart(200);
  }

  async startDrafter() {
    const use = this.$store.state.drafting.useSgtins;
    await this.$store.dispatch("drafting/startDrafter", use);
  }

  async pauseDrafter() {
    await this.$store.dispatch("drafting/pauseDrafter");
  }

  async stopDrafter() {
    await this.$store.dispatch("drafting/stopDrafter");
  }

  async onClickFinishDrafting() {
    //upload draft record?
    await this.$store.dispatch("drafting/getBatchInfo");
    //store/save draft operation
    let draftingResult: Models.DraftingResult = this.$store.getters["processing/getField"]("currentBatchSetup.draftingResult") as Models.DraftingResult;
    if (draftingResult.draftedAnimals.length === 0) {
      console.log("finish() with no animals drafted, not saving this batch setup");
      this.$store.commit("drafting/updateField", { path: "busyDrafting", value: false });
      this.$router.push({ name: "home" });
      return;
    }

    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: async () => {
        this.$store.commit("popup/hide");
        try {
          this.finishButtonState = "busy";
          //TODO: Populate reference and batch guids
          this.$store.commit("processing/updateField", {
            path: "currentBatchSetup.draftingResult.batchGuid",
            value: this.$store.state.processing.currentBatchSetup.guid,
          });
          //  this.$store.commit("processing/updateField", { path: "currentBatchSetup.draftingResult.reference", value: this.$store.state.processing.currentBatchSetup.reference });
          // //TODO: Populate destination kraal ids on each animal

          let sortingConfig: Models.GateConfigComponent[] | undefined = this.$store.state.processing.currentBatchSetup.draftingResult.sortingConfig;
          if (!sortingConfig) {
            throw Error("sorting gate configuration not found!");
          }

          let animals: [Models.DraftedAnimal] = this.$store.state.processing.currentBatchSetup.draftingResult.draftedAnimals;
          for (let animal of animals) {
            animal.destinationKraalId =
              sortingConfig.find((g) => {
                return g.gate.id === animal.gate.toString();
              })?.destinationKraalId ?? "";
          }

          this.$store.commit("processing/updateField", { path: "currentBatchSetup.draftingResult.draftedAnimals", value: animals });

          this.$store.commit("processing/updateField", { path: "currentBatchSetup.finished", value: true });
          await this.$store.dispatch("dataManager/saveData", { data: this.$store.state.processing.currentBatchSetup, objectStore: "BatchSetup" });

          this.$store.commit("processing/updateField", { path: "currentAnimal", value: undefined });
          this.$store.commit("processing/updateField", { path: "currentBatchSetup", value: undefined });
          this.$store.commit("processing/updateField", { path: "quantity", value: undefined });
          this.$store.commit("processing/updateField", { path: "busyProcessing", value: false });
          this.$store.commit("processing/updateField", { path: "isAnimalSick", value: false });
          // clear used fields in drafting module
          this.$store.commit("drafting/updateField", { path: "currentAnimal", value: "" });
          this.$store.commit("drafting/updateField", { path: "currentGate", value: "" });
          this.$store.commit("drafting/updateField", { path: "quantity", value: 0 });
          this.$store.commit("drafting/updateField", { path: "busyDrafting", value: false });

          //  this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult", value: undefined });
          this.finishButtonState = "success";
          await this.stopDrafter();
          this.$store.dispatch("upload/upload"); //don't await we can let this one fire off in the background
          this.$router.push({ name: "home" });
        } catch (err: any) {
          this.finishButtonState = "error";
          //console.log("finish() error: ", err);
          this.$store.commit("log/message", { message: "Error: " + err.message, type: "error" });
          throw err;
        }
      },
    });
  }

  get draftingResult() {
    return this.$store.getters["processing/getField"]("currentBatchSetup")?.draftingResult;
  }

  draftedAnimalHeaders = [
    { text: "#", value: "numberInQueue", sortable: false, align: "center" },
    { text: "Tag ID", value: "sgtin", sortable: false, align: "center" },
    { text: "Mass", value: "mass", sortable: false, align: "center" },
    { text: "Gate", value: "gate", sortable: false, align: "center" },
    // { text: "Destination", value: "destination", sortable: false, align: "center" },
    // { text: "Kraal/Camp", value: "kraalId", sortable: false, align: "center" },
    //  { text: "Vaccination", value: "vaccinationDescription", sortable: false, align: "center" },
    { text: "Time", value: "time", sortable: false, align: "center" },
  ];

  rerenderTableKey: number = 0;
  rerenderTable() {
    this.rerenderTableKey += 1;
  }

  @Watch("$store.state.drafting.currentGate")
  OnGateChanged(newVal: any, oldVal: any) {
    let gate = newVal;
    switch (gate) {
      case "1":
        this.gateColor = "red";
        break;
      case "2":
        this.gateColor = "pink";
        break;
      case "3":
        this.gateColor = "blue";
        break;
      case "4":
        this.gateColor = "green";
        break;
      case "5":
        this.gateColor = "orange";
        break;
    }
    // let gateNum = parseInt(newVal);
    // if (gateNum > 0) {
    this.vaccinationDescription = this.$store.getters["processing/getField"]("currentBatchSetup")?.draftingResult?.appliedVaccination.description;
    // } else this.vaccinationDescription = "";
  }

  @Watch("$store.state.drafting.draftState")
  OnStateChanged(newVal: any, oldVal: any) {
    this.state = newVal;
  }

  gatesSummary: any[] = [];

  get stopDrafterButtonState() {
    return "ready";
  }

  get startDrafterButtonState() {
    return "ready";
  }

  @Watch("$store.state.drafting.draftedAnimals")
  async calcGatesSummary(newval: any, oldval: any) {
    this.gatesSummary = await gatesSummary(this.$store.state.processing.currentBatchSetup);
  }

  get draftedAnimals() {
    // return [{"sgtin":"testSGTN", "mass": 200}]
    return this.$store.getters["drafting/getDraftedAnimals"];
  }

  get moment() {
    return this.$store.state.moment;
  }
  finishButtonState: ButtonState = "ready";
  get finishAnimalButtonState(): ButtonState {
    return "ready";
  }

  sortingGate: { gate?: Models.SortingGate; destination?: { type: string /*"ranch" | "feedlot"*/; kraalId: string } } | "" = "";
  sortingGateButtonStateError: boolean = false;
  sortingGateErrorMessage: string = "";

  get colClass() {
    return this.$store.state.lightDarkMode === "light" ? "grey lighten-1" : "grey darken-4";
  }

  firstMassCaptured: boolean = false;
  currentMass: number | "" = "";
  drawGraphCount = 0;
  drawLightCount = 0;
  delta: number = 250; //ms
  scaleActivityToggle: boolean = false;

  get scaleActivityColour() {
    return this.scaleActivityToggle ? "lime" : "white";
  }
  zeroScaleTimer() {
    this.$store.commit("scale/zeroScaleCurrentTime", this.$store.state.scale.zeroScaleCurrentTime + this.delta);

    if (!this.$store.state.scale.connected || this.currentMass === "" || Math.abs(this.currentMass) > 2) this.$store.commit("scale/zeroScaleCurrentTime", 0);

    if (this.$store.state.scale.zeroScaleCurrentTime > this.$store.state.scale.zeroScaleMaxTime) {
      this.$store.commit("scale/zeroScalePopup", false);
    } else lodash.debounce(this.zeroScaleTimer, this.delta)();
  }
  setMass(mass: number) {
    if (!this.firstMassCaptured) {
      this.firstMassCaptured = true;
      //check if scale has been zeroed
      if (Math.abs(mass) > 2 && mass < this.$store.state.scale.minMass) {
        //if (mass < this.$store.state.scale.minMass) {
        this.$store.commit("scale/zeroScalePopup", true);
        this.$store.commit("scale/zeroScaleCurrentTime", 0);
        lodash.debounce(this.zeroScaleTimer)();
      }
    }

    this.currentMass = mass;

    let scaleMassText = document.getElementById("scaleMassText");
    if (scaleMassText) scaleMassText.innerHTML = `${mass} kg`;

    this.drawGraphCount++;
    if (this.drawGraphCount > 5) {
      this.drawGraphCount = 0;
      let scale = this.$store.state.scale;
      let hLines = [{ value: scale.minMass, colour: "grey", id: "horizontal-graph-line" }];
      if (scale.massCaptureState === "captured") hLines.push({ value: scale.capturedMass, colour: "lime", id: "min-graph-line" });
      drawChart(this.$store.getters["scale/historyMassQueue"](), hLines, scale.massQueueSize);
    }

    this.drawLightCount++;
    if (this.drawLightCount >= 10) {
      this.drawLightCount = 0;
      this.scaleActivityToggle = !this.scaleActivityToggle;
      let scaleActivityCircle = document.getElementById("scaleActivityCircle");
      if (scaleActivityCircle) (scaleActivityCircle as any).attributes.fill.nodeValue = this.scaleActivityColour;
    }
  }
}
