
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import GateSetup from "@/components/GateSetup.vue";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import { mapProcessingFields } from "@/store/modules/processing";
import { prettyProcessingFunction } from "@/helpers/pretty-processing-function";
import { updateAppBarText } from "@/helpers/app-bar-text";
import { UploadableBatchSetup, UploadableProcessedAnimal } from "@/models/uploadable";

@Component({
  components: {
    DimssaButton,
    GateSetup,
  },
  computed: {
    ...mapProcessingFields([
      //"currentBatchSetup.processingResult",
      //"currentBatchSetup.processingResult.reference",
      //"currentBatchSetup.batchDetails",
      //"currentBatchSetup.batchDetails.quantity",
      //"currentBatchSetup.batchDetails.notes",
    ]),
  },
})
export default class BatchSetupView extends Vue {
  @Prop() modeProp?: "edit" | "view"; //when "view" then land on summary tab
  //mode: "edit" | "view" = "view";

  draftMode: boolean = false;
  sgtinScan: boolean = true;

  @Watch("draftMode")
  onDraftModeChanged(draftMode: boolean) {
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.draftMode", value: draftMode });
    if (draftMode) {
      const pf = this.currentBatchSetup.processingResult?.processingFunction;
      for (const sortConfig of this.processingResult.sortingConfig) sortConfig.gender = "any";
      if (pf && ["reprocess", "reimplant", "reweigh"].includes(pf)) {
        let draftingResult: Models.DraftingResult = new Models.DraftingResult(pf, Date.now(), this.$store.getters["user/getUpstreamMetadata"]());
        this.$store.commit("processing/updateField", { path: "currentBatchSetup.draftingResult", value: draftingResult });
      }
    }
  }

  prettyProcessingFunction = prettyProcessingFunction;

  isReprocess(processingFunction: Models.ProcessingFunction | undefined) {
    return processingFunction && ["reprocess", "reweigh", "reimplant"].includes(processingFunction);
  }

  //This is ignored if processFunction is for new or dispatch batches
  reprocessType: Models.ProcessingFunction = "reprocess";

  @Watch("reprocessType")
  reprocessTypeChanged(reprocessType: Models.ProcessingFunction) {
    console.log("reprocessType: ", reprocessType);
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.processingFunction", value: reprocessType });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingFunction", value: reprocessType });
    updateAppBarText("batch-setup");
    this.getBatchDetails();
  }

  customFeeders: Models.CustomFeeder[] = [];
  kraals: Models.Kraal[] = [];
  vaccinations: Models.Vaccination[] = [];

  moment = this.$store.state.moment;

  tab = 0;

  changed: boolean = false;

  //rememberSettingTagTypes: boolean = false;
  rememberSettingAllflexTag: boolean = false;
  rememberSettingVisualTag: boolean = false;
  rememberSettingCountTeeth: boolean = false;
  rememberSettingCheckNonStandard: boolean = false;

  // rememberSettingTagTypesChanged(remember: any) {
  //   if (remember) this.$store.commit("processing/updateField", { path: "savedSettings.tagTypes", value: this.selectedTagTypes });
  //   else this.$store.commit("processing/updateField", { path: "savedSettings.tagTypes", value: undefined });
  // }

  rememberSettingAllflexTagChanged(remember: any) {
    if (remember) this.$store.commit("processing/updateField", { path: "savedSettings.allflexTag", value: this.allflexTag });
    else this.$store.commit("processing/updateField", { path: "savedSettings.allflexTag", value: undefined });
  }

  rememberSettingVisualTagChanged(remember: any) {
    if (remember) this.$store.commit("processing/updateField", { path: "savedSettings.visualTag", value: this.visualTag });
    else this.$store.commit("processing/updateField", { path: "savedSettings.visualTag", value: undefined });
  }

  rememberSettingCountTeethChanged(remember: any) {
    if (remember) this.$store.commit("processing/updateField", { path: "savedSettings.countTeeth", value: this.countTeeth });
    else this.$store.commit("processing/updateField", { path: "savedSettings.countTeeth", value: undefined });
  }

  rememberSettingCheckNonStandardChanged(remember: any) {
    if (remember) this.$store.commit("processing/updateField", { path: "savedSettings.checkNonStandard", value: this.checkNonStandard });
    else this.$store.commit("processing/updateField", { path: "savedSettings.checkNonStandard", value: undefined });
  }

  @Watch("changed")
  onChangeChanged() {
    if (this.changed) this.$store.commit("navFuncs", { save: this.save, back: this.back });
    else
      this.$store.commit("navFuncs", {
        save: undefined,
        back: () => {
          this.$store.commit("processing/updateField", { path: "currentBatchSetup", value: undefined });
          this.$router.push({ name: "home" });
        },
      });
  }

  get validValueColumns() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 12;
      case "sm":
        return 6;
      case "md":
        return 3;
      case "lg":
        return 2;
      case "xl":
        return 2;
    }
  }
  validReference: boolean = false;
  validQuantity: boolean = false;
  validBreeds: boolean = false;
  validGenders: boolean = false;
  validGates: boolean = false;
  validCustomFeeder: boolean = false;
  appliedVaccination: any = {};

  checkValidValues() {
    this.validReference = this.currentBatchSetup.batchDetails.reference ? true : false;
    let qty = this.currentBatchSetup.batchDetails.quantity as any;
    this.validQuantity = ![undefined, "", null].includes(qty);
    this.validBreeds = this.selectedBreeds.length ? true : false;
    this.validGenders = this.selectedGenders.length ? true : false;
    this.validGates = this.gateSummaryItems.length ? true : false;
    this.validCustomFeeder = this.customFeeder ? true : false;
  }

  @Watch("currentBatchSetup.batchDetails.reference")
  onReferenceChanged(reference: any) {
    this.validReference = reference ? true : false;
    this.updateBeginProcessingButtonState();
  }

  @Watch("currentBatchSetup.batchDetails.quantity")
  onQuantityChanged(quantity: any) {
    this.validQuantity = quantity !== "" ? true : false;
    this.updateBeginProcessingButtonState();
  }

  @Watch("selectedBreeds")
  onSelectedBreedsChanged(selectedBreeds: any[]) {
    this.validBreeds = selectedBreeds.length ? true : false;
    this.updateBeginProcessingButtonState();
  }
  @Watch("selectedGenders")
  onSelectedGendersChanged(selectedGenders: any[]) {
    this.validGenders = selectedGenders.length ? true : false;
    this.updateBeginProcessingButtonState();
  }

  //gate summary items, at least 1 gate must be setup
  @Watch("gateSummaryItems")
  onGateSummaryItemsChanged(gateSummaryItems: any[]) {
    this.validGates = gateSummaryItems.length ? true : false;
    this.updateBeginProcessingButtonState();
  }

  @Watch("customFeeder")
  onCustomFeederChanged(customFeeder: any) {
    this.validCustomFeeder = customFeeder ? true : false;
    this.updateBeginProcessingButtonState();
  }

  // @Watch("selectedTagTypes")
  // onSelectedTagTypesChanged(tagTypes: any) {
  //   if (this.rememberSettingTagTypes) this.$store.commit("processing/updateField", { path: "savedSettings.tagTypes", value: this.selectedTagTypes });
  // }

  @Watch("allflexTag")
  onAllflexTagChanged(allflexTag: any) {
    if (this.rememberSettingAllflexTag) this.$store.commit("processing/updateField", { path: "savedSettings.allflexTag", value: this.allflexTag });
  }

  @Watch("visualTag")
  onVisualTagChanged(visualTag: any) {
    if (this.rememberSettingVisualTag) this.$store.commit("processing/updateField", { path: "savedSettings.visualTag", value: this.visualTag });
  }

  @Watch("countTeeth")
  onCountTeethChanged(countTeeth: any) {
    if (this.rememberSettingCountTeeth) this.$store.commit("processing/updateField", { path: "savedSettings.countTeeth", value: this.countTeeth });
  }

  @Watch("checkNonStandard")
  onCheckNonStandardChanged(checkNonStandard: any) {
    if (this.rememberSettingCheckNonStandard)
      this.$store.commit("processing/updateField", { path: "savedSettings.checkNonStandard", value: this.checkNonStandard });
  }

  async created() {
    //check if processingResult.sortingConfig is empty. If it is then populate it with gates
    //TODO: check for length mismatch, to detect if gate config has changed
    if (this.processingResult.sortingConfig?.length === 0) {
      for (let gate of this.gates) {
        let sortGateConfig = new Models.GateConfigComponent();
        sortGateConfig.gate = new Models.SortingGate();
        sortGateConfig.gate.id = gate.id;
        sortGateConfig.gate.description = gate.description;
        sortGateConfig.gate.gateKraalId = gate.gateKraalId;
        sortGateConfig.condition.push(new Models.SortingCondition());
        sortGateConfig.vaccinations = [];
        sortGateConfig.vaccinations.push({ description: "normal", vaccination: undefined });
        sortGateConfig.vaccinations.push({ description: "sick", vaccination: undefined });
        this.processingResult.sortingConfig.push(sortGateConfig);
      }
      this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.sortingConfig", value: this.processingResult.sortingConfig });
    }

    //this.rememberSettingTagTypes = this.$store.state.processing.savedSettings.tagTypes !== undefined;
    this.rememberSettingAllflexTag = this.$store.state.processing.savedSettings.allflexTag !== undefined;
    this.rememberSettingVisualTag = this.$store.state.processing.savedSettings.visualTag !== undefined;
    this.rememberSettingCountTeeth = this.$store.state.processing.savedSettings.countTeeth !== undefined;
    this.rememberSettingCheckNonStandard = this.$store.state.processing.savedSettings.checkNonStandard !== undefined;

    //Sync reprocessType with possibly already selected processingFunction
    const pf = this.currentBatchSetup.processingResult?.processingFunction;
    if (pf && ["reprocess", "reimplant", "reweigh"].includes(pf)) {
      this.reprocessType = pf;
    }

    this.draftMode = this.currentBatchSetup.draftMode ?? false;
    if (this.draftMode) {
      this.appliedVaccination = this.currentBatchSetup?.draftingResult?.appliedVaccination;
    }
    if (this.currentBatchSetup.processingResult) {
      this.selectedBreeds = this.currentBatchSetup.processingResult.breeds;
      this.selectedGenders = this.currentBatchSetup.processingResult.genders;

      // if (this.$store.state.processing.savedSettings.tagTypes) this.selectedTagTypes = this.$store.state.processing.savedSettings.tagTypes;
      // else
      //   this.selectedTagTypes = this.currentBatchSetup.processingResult.tagTypes.map((t) => {
      //     return this.tagTypes.find((tagType) => tagType.tagType === t);
      //   });
      if (this.$store.state.processing.savedSettings.allflexTag) this.allflexTag = this.$store.state.processing.savedSettings.allflexTag;
      else this.allflexTag = this.currentBatchSetup.processingResult.tagTypes.find((t) => t === "allflex") !== undefined ?? false;

      if (this.$store.state.processing.savedSettings.visualTag) this.visualTag = this.$store.state.processing.savedSettings.visualTag;
      else this.visualTag = this.currentBatchSetup.processingResult.tagTypes.find((t) => t === "visual") !== undefined ?? false;

      if (this.$store.state.processing.savedSettings.countTeeth) this.countTeeth = this.$store.state.processing.savedSettings.countTeeth;
      else this.countTeeth = this.currentBatchSetup.processingResult.countTeeth ?? false;

      if (this.$store.state.processing.savedSettings.checkNonStandard) this.checkNonStandard = this.$store.state.processing.savedSettings.checkNonStandard;
      else this.checkNonStandard = this.currentBatchSetup.processingResult.checkNonStandard ?? false;
    }

    this.customFeeder = this.currentBatchSetup.batchDetails.customFeeder || "";

    this.$store.dispatch("dataManager/addOnInitializedCallback", async () => {
      for (let kraal of await this.$store.dispatch("dataManager/getData", { objectStore: "Kraal" })) {
        this.kraalTypes[kraal.kraalId] = kraal.kraalType;
      }
      this.vaccinations = await this.$store.dispatch("dataManager/getData", { objectStore: "Vaccination" });
      this.customFeeders = await this.$store.dispatch("dataManager/getData", { objectStore: "CustomFeeder" });
      this.customFeeders.sort((i1, i2) => (i1.name < i2.name ? -1 : 1));
      this.kraals = await this.$store.dispatch("dataManager/getData", { objectStore: "Kraal" });

      if (this.currentBatchSetup.processingFunction === "dispatch-to-abattoir-processed") {
        //adding a fake abattoir kraal, so if the sorting gate's kraalid is abattoir then it was dispatched
        this.kraals = [
          { typename: "Kraal", guid: "", kraalId: "Abattoir", kraalType: "Abattoir", active: true, changeTracking: new Models.ChangeTrackComponent() },
          //...this.kraals,
        ];
        this.kraalTypes["Abattoir"] = "Abattoir";
      } else if (this.currentBatchSetup.processingFunction === "dispatch-to-site-processed") {
        //adding a fake abattoir kraal, so if the sorting gate's kraalid is abattoir then it was dispatched
        this.kraals = [
          { typename: "Kraal", guid: "", kraalId: "Site", kraalType: "Site", active: true, changeTracking: new Models.ChangeTrackComponent() },
          //...this.kraals,
        ];
        this.kraalTypes["Site"] = "Site";
      }
    });

    this.updateGateSummaryItems();

    this.checkValidValues();
  }

  beforeDestroy() {
    this.$store.commit("navFuncs", { save: undefined, back: undefined });
  }

  mounted() {
    //TODO: back button should back to select batch setups if that's where we came from
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("processing/updateField", { path: "currentBatchSetup", value: undefined });
        this.$router.push({ name: "home" });
      },
    });

    if (this.modeProp === "view") this.tab = 2;

    this.getBatchDetails();
  }

  customFeeder: Models.CustomFeeder | "" = "";

  customFeederChange(customFeeder: any) {
    this.customFeeder = customFeeder;
    if (["reprocess", "reweigh", "reimplant"].includes(this.currentBatchSetup.processingFunction)) {
      this.$store.commit("processing/updateField", {
        path: "currentBatchSetup.batchDetails.customFeeder",
        value: this.customFeeder === "" ? undefined : this.customFeeder,
      });
      this.$store.commit("processing/updateField", {
        path: "currentBatchSetup.processingResult.customFeederGuid",
        value: this.customFeeder === "" ? undefined : this.customFeeder.guid,
      });
      // this.$store.commit("processing/updateField", {
      //   path: "currentBatchSetup.processingResult.customFeederVersion",
      //   value: this.customFeeder === "" ? undefined : this.customFeeder.changeTracking.current?.guid,
      // });
      this.$forceUpdate();
    }
  }

  kraalTypes: { [kraalId: string]: string } = {}; //TODO: remove

  // get tagTypes() {
  //   //TODO: allflex should only be an option if the hardware is available
  //   return [
  //     { description: "Low Freq RFID Tag", tagType: "allflex" },
  //     { description: "Visual Tag ", tagType: "visual" },
  //   ];
  // }

  get breeds() {
    if (!this.$store.state.breeds) throw Error("No breeds available, data must be downloaded");
    return this.$store.state.breeds;
  }

  get genders() {
    return ["male", "female"];
  }

  selectedBreeds: any[] = [];
  selectedTagTypes: any[] = [];
  selectedGenders: any[] = [];
  allflexTag: boolean = false;
  visualTag: boolean = false;
  countTeeth: boolean = false;
  checkNonStandard: boolean = false;

  get gates() {
    return this.$store.getters["getField"]("sortingGates.gates");
  }

  get processingResult(): Models.ProcessingResult {
    if (!this.currentBatchSetup.processingResult) throw Error("No processing result!");
    return this.currentBatchSetup.processingResult;
  }

  get currentBatchSetup(): Models.BatchSetup {
    if (!this.$store.getters["processing/getField"]("currentBatchSetup")) throw Error("BatchSetup.vue (get currentBatchSetup): currentBatch not found");
    return this.$store.getters["processing/getField"]("currentBatchSetup");
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: () => {
        this.$store.commit("popup/hide");

        if (!this.currentBatchSetup.batchDetails.reference) {
          this.$store.commit("popup/displayOk", "No reference.");
          return;
        }

        // if (this.currentBatchSetup.processingFunction === "new") {
        //   if (this.selectedBreeds.length === 0) {
        //     this.$store.commit("popup/displayOk", "No breeds selected.");
        //     return;
        //   }
        //   if (this.selectedGenders.length === 0) {
        //     this.$store.commit("popup/displayOk", "No genders selected.");
        //     return;
        //   }
        // }

        for (let i = 0; i < this.processingResult.sortingConfig.length; ++i) {
          if (!this.checkSaveSortingGate(i)) {
            let gateDesc = this.processingResult.sortingConfig[i].gate.description;
            let message = `Sorting Gate ${gateDesc} must be cleared or fully completed.`;
            this.$store.commit("popup/displayOk", message);
            return;
          }
        }

        if (this.processingResult.processingFunction === "dispatch-to-abattoir-processed") {
          //if dispatching to abattoir then at least one sorting gate must have Abattoir selected as destination
          if (!this.processingResult.sortingConfig.find((sc) => sc.destinationKraalId === "Abattoir")) {
            this.$store.commit("popup/displayOk", "At least one gate must have Abattoir selected.");
            return;
          }
          //if a sorting gate has Abattoir selected as destination then selected vaccinations may not have any doses
          for (let sortingConfig of this.processingResult.sortingConfig) {
            if (sortingConfig.destinationKraalId === "Abattoir") {
              let vaccinationHasDoses = sortingConfig.vaccinations[0].vaccination && sortingConfig.vaccinations[0].vaccination.doses.length > 0;
              if (vaccinationHasDoses) {
                let vaccination = sortingConfig.vaccinations[0].vaccination;
                this.$store.commit(
                  "popup/displayOk",
                  `Vaccinations for abattoir sorting gates must have no doses. (${vaccination?.description}) has ${vaccination?.doses.length} doses`
                );
                return;
              }
              let sickVaccinationHasDoses = sortingConfig.vaccinations[1].vaccination && sortingConfig.vaccinations[1].vaccination.doses.length > 0;
              if (sickVaccinationHasDoses) {
                let vaccination = sortingConfig.vaccinations[1].vaccination;
                this.$store.commit(
                  "popup/displayOk",
                  `Vaccinations for abattoir sorting gates must have no doses. (${vaccination?.description}) has ${vaccination?.doses.length} doses`
                );
                return;
              }
            }
          }
        }

        // console.log(JSON.stringify(this.selectedBreeds));
        // console.log(JSON.stringify(this.selectedTagTypes));
        // console.log(JSON.stringify(this.selectedGenders));
        this.currentBatchSetup.batchDetails.type = this.currentBatchSetup.processingFunction;
        if (this.currentBatchSetup.processingResult) {
          this.currentBatchSetup.processingResult.breeds = this.selectedBreeds;
          this.currentBatchSetup.processingResult.genders = this.selectedGenders;
          //this.currentBatchSetup.processingResult.tagTypes = this.selectedTagTypes.map((t: any) => t.tagType);
          this.currentBatchSetup.processingResult.tagTypes = [];
          if (this.allflexTag) this.currentBatchSetup.processingResult.tagTypes.push("allflex");
          if (this.visualTag) this.currentBatchSetup.processingResult.tagTypes.push("visual");
          this.currentBatchSetup.processingResult.reference = this.currentBatchSetup.batchDetails.reference
            ? this.currentBatchSetup.batchDetails.reference
            : "";
          this.currentBatchSetup.processingResult.countTeeth = this.countTeeth;
          this.currentBatchSetup.processingResult.checkNonStandard = this.checkNonStandard;
        }
        this.$store.commit("processing/updateField", { path: "currentBatchSetup", value: this.currentBatchSetup });
        // if (["reprocess", "reweigh", "reimplant"].includes(this.currentBatchSetup.processingFunction)) {
        //   this.$store.commit("processing/updateField", {
        //     path: "currentBatchSetup.batchDetails.customFeeder",
        //     value: this.customFeeder === "" ? undefined : this.customFeeder,
        //   });
        // }
        this.$store.dispatch("dataManager/saveData", { data: this.currentBatchSetup, objectStore: "BatchSetup" });
        //this.mode = "view";
        this.changed = false;
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("popup/hide");
        this.$store.commit("processing/updateField", { path: "currentBatchSetup", value: undefined });
        this.$router.push({ name: "home" });
      },
    });
  }

  async deleteBatchSetup() {
    await this.$store.dispatch("dataManager/deleteBatchSetup", this.currentBatchSetup.guid);
    this.$store.commit("processing/updateField", { path: "currentBatchSetup", value: undefined });
    this.$router.push({ name: "home" });
  }

  // selectVaccination(gateIndex: number, vaccinationType: "normal" | "sick") {
  //   let vaccinations = this.$store.getters["getField"](`processing.currentBatchSetup.processingResult.sortingConfig[${gateIndex}].vaccinations`);
  //   let vaccinationIndex = lodash.findIndex(vaccinations, { description: vaccinationType });
  //   let gateConfigPath = `processing.currentBatchSetup.processingResult.sortingConfig[${gateIndex}].vaccinations[${vaccinationIndex}].vaccination`;
  //   this.$store.commit("updateField", { path: "selectVaccinationSetPath", value: gateConfigPath });
  //   this.$store.commit("updateField", { path: "selectVaccinationReturnRoutePushData", value: { name: "batch-setup", params: { modeProp: "edit" } } });
  //   this.$router.push({ name: "select-vaccination" });
  // }
  get filteredVaccinations() {
    return this.vaccinations.filter((vac) => {
      let dosage = vac.doses.filter((dose: Models.Dose) => {
        return dose.type === "cc-mass-dependent";
      });
      if (dosage.length > 0) return false;
      else return true;
    });
  }

  appliedVaccinationSelected(vaccination: Models.Vaccination | undefined, type: "normal" | "sick") {
    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.draftingResult.appliedVaccination`,
      value: vaccination,
    });

    if (this.draftMode) {
      this.appliedVaccination = this.currentBatchSetup?.draftingResult?.appliedVaccination;
    }
  }

  vaccinationSelected(vaccination: Models.Vaccination | undefined, gateIndex: number, type: "normal" | "sick") {
    let gateVaccinations = this.$store.getters["getField"](`processing.currentBatchSetup.processingResult.sortingConfig[${gateIndex}].vaccinations`);
    let vaccinationIndex = lodash.findIndex(gateVaccinations, { description: type });
    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig[${gateIndex}].vaccinations[${vaccinationIndex}].vaccination`,
      value: vaccination,
    });
  }

  clearSortingGate(index: number) {
    let sortGateConfig = this.processingResult.sortingConfig[index];
    sortGateConfig.condition = [new Models.SortingCondition()];
    sortGateConfig.vaccinations = [];
    sortGateConfig.vaccinations.push({ description: "normal", vaccination: undefined });
    sortGateConfig.vaccinations.push({ description: "sick", vaccination: undefined });
    sortGateConfig.gender = this.draftMode ? "Mixed" : "";
    sortGateConfig.destinationType = "";
    sortGateConfig.destinationKraalId = undefined;

    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig`,
      value: this.processingResult.sortingConfig,
    });
  }

  isSortingGateCleared(index: number) {
    let sortGateConfig = this.processingResult.sortingConfig[index];

    let hasGender = sortGateConfig.gender ? true : false;
    let hasMinMass = ![null, undefined, ""].includes(sortGateConfig.condition[0].min as any);
    let hasMaxMass = ![null, undefined, ""].includes(sortGateConfig.condition[0].max as any);
    let hasKraal = sortGateConfig.destinationKraalId ? true : false;
    let hasVaccination = sortGateConfig.vaccinations[0].vaccination ? true : false;
    let hasSickVaccination = sortGateConfig.vaccinations[1].vaccination ? true : false;

    if (this.draftMode) {
      if (!hasMinMass && !hasMaxMass && !hasKraal) return true;
      else return false;
    }

    if (!hasGender && !hasMinMass && !hasMaxMass && !hasKraal && !hasVaccination && !hasSickVaccination) return true;
    return false;
  }

  isSortingGateFullyCompleted(o: { sortGateConfig?: Models.GateConfigComponent; index?: number }) {
    let sortGateConfig;
    if (o.sortGateConfig) sortGateConfig = o.sortGateConfig;
    else if (o.index !== undefined) sortGateConfig = this.processingResult.sortingConfig[o.index];
    else throw Error("Either sortGateConfig or index must be given.");

    let hasGender = sortGateConfig.gender ? true : false;
    let hasMinMass = ![null, undefined, ""].includes(sortGateConfig.condition[0].min as any);
    let hasMaxMass = ![null, undefined, ""].includes(sortGateConfig.condition[0].max as any);
    let hasKraal = sortGateConfig.destinationKraalId ? true : false;
    let hasVaccination = sortGateConfig.vaccinations[0].vaccination ? true : false;
    let hasSickVaccination = sortGateConfig.vaccinations[1].vaccination ? true : false;

    if (this.draftMode) {
      if (hasGender && hasMinMass && hasMaxMass && hasKraal) return true;
      else return false;
    }

    if (hasGender && hasMinMass && hasMaxMass && hasKraal && hasVaccination && hasSickVaccination) return true;
    return false;
  }

  //returns to true if sorting gate has been completely filled out (or not filled out at all) and is ok to save
  checkSaveSortingGate(index: number) {
    return this.isSortingGateCleared(index) || this.isSortingGateFullyCompleted({ index });
  }

  sortingGateGenderChange(gender: "male" | "female", index: number) {
    console.log("gender: " + gender);
    console.log("index: " + index);

    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig[${index}].gender`,
      value: gender,
    });
  }

  get uiText() {
    return prettyProcessingFunction(this.currentBatchSetup.processingFunction);
  }

  beginProcessingButtonState: ButtonState = "disabled";

  updateBeginProcessingButtonState() {
    const hasAllowedAnimals = !!this.currentBatchSetup?.batchDetails?.allowedAnimals?.length;
    if (!this.currentBatchSetup.batchDetails.reference) {
      this.beginProcessingButtonState = "disabled";
    } else if (this.currentBatchSetup.processingFunction === "new" && !hasAllowedAnimals && !this.selectedBreeds.length) {
      this.beginProcessingButtonState = "disabled";
    } else if (this.currentBatchSetup.processingFunction === "new" && !hasAllowedAnimals && !this.selectedGenders.length) {
      this.beginProcessingButtonState = "disabled";
    } else if (!this.gateSummaryItems.length) {
      this.beginProcessingButtonState = "disabled";
    } else if (!this.customFeeder) {
      this.beginProcessingButtonState = "disabled";
    } else this.beginProcessingButtonState = "ready";
  }

  async beginProcessing() {
    if (this.changed) {
      this.$store.commit("popup/displayOk", "Batch setup must first be saved.");
      return;
    }
    try {
      if (!this.$store.state.processing.currentBatchSetup.batchDetails.reference) throw Error("Reference must be set before processing can begin.");
      if (!this.$store.state.processing.currentBatchSetup.batchDetails.quantity) throw Error("Quantity must be set before processing can begin.");
      if (!this.$store.state.processing.currentBatchSetup.batchDetails.customFeeder) throw Error("Owner must be selected before processing can begin.");

      // check gates
      // get gate config and find highest valid gate
      const numHslGates = this.$store.state.sortingGates.currentGates.length;
      let maxValidGateIndex = 0;
      for (let i = this.processingResult.sortingConfig.length - 1; i >= 0; --i) {
        if (this.isSortingGateFullyCompleted({ sortGateConfig: this.processingResult.sortingConfig[i] })) {
          maxValidGateIndex = i;
          break;
        }
      }
      if (maxValidGateIndex >= numHslGates) throw Error(`Too many gates selected. Only ${numHslGates} gates available.`);
      console.log("maxValidGateIndex:", maxValidGateIndex);

      // check vaccinations
      for (const gate of this.processingResult.sortingConfig) {
        for (const v of gate.vaccinations) {
          if (!v.vaccination?.guid) continue; // only check if vaccination has been selected
          const vaccExists = this.vaccinations.find((v1) => v1.guid === v.vaccination?.guid && v1.description === v.vaccination?.description);
          if (!vaccExists) throw Error(`Vaccination (${v.vaccination?.description}) chosen for gate ${gate.gate.id} not found.`);
        }
      }

      // check kraals
      for (const gate of this.processingResult.sortingConfig) {
        if (!gate.destinationKraalId) continue; // only check if destination kraal has been selected
        const kraalExists = this.kraals.find((k) => k.kraalId === gate.destinationKraalId);
        if (!kraalExists) throw Error(`Kraal ${gate.destinationKraalId} chosen for gate ${gate.gate.id} not found.`);
      }
    } catch (err) {
      this.$store.commit("popup/displayOk", (err as any)?.message ?? (err as any)?.toString() ?? "no error found");
      return;
    }

    if (this.draftMode) {
      try {
        if (this.currentBatchSetup.processingResult?.sortingConfig)
          for (let i = 0; i < this.currentBatchSetup.processingResult?.sortingConfig.length; i++) {
            for (let j = 0; j < this.currentBatchSetup.processingResult?.sortingConfig[i].condition.length; j++) {
              if (this.currentBatchSetup.processingResult?.sortingConfig[i].condition[j].min === undefined) throw Error("Gate masses must be filled out.");
            }
          }
      } catch (err) {
        this.$store.commit("popup/displayOk", (err as any)?.message ?? (err as any)?.toString() ?? "no error found");
        return;
      }
      this.$store.commit("updateField", {
        path: `drafting.useSgtins`,
        value: this.sgtinScan,
      });
      let draftingResult: any = {};
      if (this.currentBatchSetup.draftingResult === undefined) {
        draftingResult = new Models.DraftingResult(this.currentBatchSetup.processingFunction, Date.now(), this.$store.getters["user/getUpstreamMetadata"]());
      } else {
        draftingResult = this.currentBatchSetup.draftingResult;
      }
      draftingResult.reference = this.$store.state.processing.currentBatchSetup.batchDetails.reference;
      draftingResult.sortingConfig = this.currentBatchSetup.processingResult?.sortingConfig ?? [];

      this.$store.commit("updateField", {
        path: `processing.currentBatchSetup.draftingResult`,
        value: draftingResult,
      });

      this.$router.push({ name: "draft-animal" });
    } else {
      this.$store.commit("updateField", {
        path: `processing.currentBatchSetup.draftingResult`,
        value: undefined,
      });

      await this.$store.dispatch("processing/initialize");

      // if (this.currentBatchSetup.processingFunction === "new" && !this.currentBatchSetup.batchDetails)
      //   throw Error("Batch details must first be selected before processing can begin.");
      //TODO: block button if processingFunction new but no breeds or genders selected
      this.$store.commit("updateField", {
        path: `processing.currentBatchSetup.processingResult.time`,
        value: Date.now(),
      });
      this.$store.commit("processing/updateField", { path: "busyProcessing", value: true });
      this.$router.push({ name: "process-animal" });
    }
  }

  ////////
  // Batch Details

  batchDetailsToSelect: Models.ProcessingBatchDetails[] = [];

  getBatchDetailsButtonState: ButtonState = "ready";

  searchBatchDetails: string = "";

  formatArrivalDate = (arrivalDate: number) => this.moment(arrivalDate).format("YYYY-MM-DD");

  filterBatchDetails(value: string, search: string, item: Models.ProcessingBatchDetails) {
    if (item.reference && item.reference.indexOf(search) !== -1) return true;
    if (item.customFeeder) {
      if (item.customFeeder.name.indexOf(search) !== -1) return true;
      if (item.customFeeder.companies.find((c) => c.name.indexOf(search) !== -1) !== undefined) return true;
    }
    if (item.arrivalDate && this.formatArrivalDate(item.arrivalDate).indexOf(search) !== -1) return true;
    if (item.quantity !== undefined && item.quantity.toString().indexOf(search) !== -1) return true;
    return false;
  }

  get headersBatchDetails() {
    const f = (customFeeder: Models.CustomFeeder, search: string, item: any) => {
      //if (customFeeder.name.indexOf(search) !== -1)
      // return customFeeder.name.indexOf(search) !== -1 || customFeeder.companies.find((c) => c.name.indexOf(search) !== -1) !== undefined;
      // console.log("filter");
      return true;
    };
    if (this.currentBatchSetup.processingFunction === "new")
      return [
        { text: "Reference", value: "reference", align: "left" },
        { text: "Owner", value: "customFeeder", align: "left", filter: f },
        { text: "Quantity", value: "quantity", align: "left" },
        { text: "From Site", value: "originName", align: "left" },
        { text: "Arrival Date", value: "arrivalDate", align: "left" },
        { text: "Description", value: "description", align: "left" },
      ];
    else if (["dispatch-to-abattoir-processed", "dispatch-to-site-processed"].includes(this.currentBatchSetup.processingFunction)) {
      const isSite = this.currentBatchSetup.processingFunction === "dispatch-to-site-processed";
      return [
        { text: "Reference", value: "reference", align: "left" },
        { text: "Owner", value: "customFeeder", align: "left", filter: f },
        { text: isSite ? "Destination Site" : "Abattoir", value: "destinationName" },
        { text: "Quantity", value: "quantity", align: "left" },
        { text: "Description", value: "description", align: "left" },
      ];
    } else {
      return [
        { text: "Reference", value: "reference", align: "left" },
        { text: "Type", value: "type", align: "left" },
        { text: "Owner", value: "customFeeder", align: "left", filter: f },
        { text: "New Owner", value: "newCustomFeeder", align: "left", filter: f },
        { text: "Quantity", value: "quantity", align: "left" },
        { text: "Description", value: "description", align: "left" },
      ];
    }
  }

  async getBatchDetails() {
    this.getBatchDetailsButtonState = "busy";
    try {
      this.batchDetailsToSelect = (await this.$store.dispatch("dataManager/getData", { objectStore: "BatchDetails" })) as Models.ProcessingBatchDetails[];
      this.batchDetailsToSelect = this.batchDetailsToSelect.filter((pbd) => pbd.type === this.currentBatchSetup.processingFunction);

      ////////
      //
      /*
        Check for animals that have been processed since the last data sync
        download. Reduce the amount of animals found from the amount in the
        batch details.
        
        If the quantity to process in the batch details subtracted by the
        amount processed is 0 or below then remove those batch details from
        the list.
      */

      let batchGuidToNumProcessedAnimals: { [batchGuid: string]: number } = await this.$store.dispatch(
        "dataManager/getNumProcessedAnimalsSinceDownload",
        this.currentBatchSetup.processingFunction
      );

      //////////////
      // Remove unuploaded animals from quantity

      const previousBatchSetups: UploadableBatchSetup[] = (await this.$store.dispatch(
        "dataManager/getBatchSetups",
        this.currentBatchSetup.processingFunction
      ));
      
      const processedAnimalGuids: string[] = await this.$store.dispatch(
        "dataManager/getUnUploadedProcessedAnimalGuids",
        undefined,
        { root: true }
      );

      let unUploadedAnimals: UploadableProcessedAnimal[] = [];

      for (const paGuid of processedAnimalGuids) {

        const processedAnimal: UploadableProcessedAnimal = await this.$store.dispatch(
          "dataManager/getProcessedAnimal",
          { guid: paGuid },
          { root: true }
        );

        if (!processedAnimal.uploaded) {
          unUploadedAnimals.push(processedAnimal);
        }
      }

      for (const bd of this.batchDetailsToSelect) {

        if (bd.batchGuid === undefined) {
          continue;
        }

        // Get batch setups that have been done for the same batch details
        const matchingBatchSetups = previousBatchSetups.filter(b => b.processingResult?.batchGuid === bd.batchGuid);

        // Get the processing result guids involved
        const prGuids = matchingBatchSetups.map(mbs => mbs.processingResult?.guid);

        let num = 0;

        for (const prGuid of prGuids) {
          // Get number of un uploaded animals for batch
          num += unUploadedAnimals.filter(a => a.processingResultGuid === prGuid).length;
        }

        if (!batchGuidToNumProcessedAnimals[bd.batchGuid]) {
          batchGuidToNumProcessedAnimals[bd.batchGuid] = 0;
        }

        batchGuidToNumProcessedAnimals[bd.batchGuid] += num;
      }

      //
      /////////////

      // Remove batch details that are already in batch setups
      const batchSetups: UploadableBatchSetup[] = await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", this.currentBatchSetup.processingFunction);
      this.batchDetailsToSelect = this.batchDetailsToSelect.filter(
        // Keep the batch details if they can't be found in the batch setups.
        (bd) => !batchSetups.find((bs) => bs.batchDetails.reference === bd.reference && bs.batchDetails.type === bd.type)
      );

      this.batchDetailsToSelect = this.batchDetailsToSelect
        .map((bd) => {
          //Subtract from batch details the quantity of processed animals that haven't been uploaded yet.
          if (bd.batchGuid && batchGuidToNumProcessedAnimals[bd.batchGuid] !== undefined) {
            const numProcessed = batchGuidToNumProcessedAnimals[bd.batchGuid];
            if (bd.quantity !== undefined) bd.quantity -= numProcessed;
          }
          return bd;
        })
        .filter((bd) => {
          //Remove batches that don't have any animals left to processed
          if (bd.quantity !== undefined && bd.quantity <= 0) return false;
          else return true;
        });

      this.batchDetailsToSelect = this.batchDetailsToSelect.sort((a, b) => {
        let t1 = parseInt(a?.guid?.split("-")[0] ?? "");
        let t2 = parseInt(b?.guid?.split("-")[0] ?? "");
        return t2 - t1;
      });

      this.getBatchDetailsButtonState = "success";
      this.$forceUpdate();
    } catch (err) {
      this.getBatchDetailsButtonState = "error";
      console.log("getBatchDetails error: ", err);
    }
  }

  batchDetailsSelected(o: Models.ProcessingBatchDetails) {
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.batchDetails", value: lodash.cloneDeep(o) });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingFunction", value: o.type });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.processingFunction", value: o.type });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.batchGuid", value: o.batchGuid });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.transportGuid", value: o.transportGuid });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.reference", value: o.reference });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.description", value: o.description });

    this.$store.commit("processing/updateField", {
      path: "currentBatchSetup.processingResult.customFeederGuid",
      value: o.customFeeder?.guid,
    });
    // this.$store.commit("processing/updateField", {
    //   path: "currentBatchSetup.processingResult.customFeederVersion",
    //   value: o.customFeeder?.changeTracking.current?.guid,
    // });

    this.$store.commit("processing/updateField", {
      path: "currentBatchSetup.processingResult.newCustomFeederGuid",
      value: o.newCustomFeeder?.guid,
    });
    // this.$store.commit("processing/updateField", {
    //   path: "currentBatchSetup.processingResult.newCustomFeederVersion",
    //   value: o.newCustomFeeder?.changeTracking.current?.guid,
    // });

    this.customFeeder = o.customFeeder ?? "";
    this.checkValidValues();
    this.$forceUpdate();
  }

  clearSelectedBatchDetails() {
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.batchDetails", value: { typename: "ProcessingBatchDetails", allowedAnimals: [] } });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.batchGuid", value: "" });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.transportGuid", value: "" });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.reference", value: "" });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.description", value: "" });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.customFeederGuid", value: undefined });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.customFeederVersion", value: undefined });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.newCustomFeederGuid", value: undefined });
    this.$store.commit("processing/updateField", { path: "currentBatchSetup.processingResult.newCustomFeederVersion", value: undefined });
    this.customFeeder = "";
    this.$forceUpdate();
  }

  sortingGateToItem(sortingConfig: Models.GateConfigComponent) {
    const sc = sortingConfig;
    let gender = sc.gender;
    if (sc.gender === "any") gender = "Mixed";
    else if (sc.gender) gender = sc.gender[0].toUpperCase() + sc.gender.slice(1).toLowerCase();

    const vaccination = sortingConfig.vaccinations.find((v) => v.description === "normal")?.vaccination;
    const sickVaccination = sortingConfig.vaccinations.find((v) => v.description === "sick")?.vaccination;

    return {
      gate: sc.gate.description,
      gender: gender,
      min: sc.condition[0].min,
      max: sc.condition[0].max,
      vaccination: vaccination?.description,
      sickVaccination: sickVaccination?.description,
      kraal: `${sc.destinationKraalId} (${sc.destinationType})`,
    };
  }

  gateSummaryItems: any[] = [];

  updateGateSummaryItems() {
    this.gateSummaryItems = this.processingResult.sortingConfig
      ? this.processingResult.sortingConfig.filter((s) => this.isSortingGateFullyCompleted({ sortGateConfig: s })).map((s) => this.sortingGateToItem(s))
      : [];
  }

  gateSummaryHeaders = [
    { value: "gate", text: "Gate" },
    { value: "gender", text: "Gender" },
    { value: "min", text: "Min (kg)" },
    { value: "max", text: "Max (kg)" },
    { value: "kraal", text: "Kraal" },
    { value: "vaccination", text: "Vaccination" },
    { value: "sickVaccination", text: "Sick Vaccination" },
  ];
}
