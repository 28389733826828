import { default as Vuex, Module, ActionContext } from "vuex";
import * as Models from "@gigalot/data-models";
import store from "../../store";
const vuexMapFields = require("vuex-map-fields");
import ReconnectingWebSocket from "reconnecting-websocket";

export const { mapFields: mapProcessingFields } = vuexMapFields.createHelpers({
  getterType: "processing/getField",
  mutationType: "processing/updateField"
});

class DraftingState {
  currentAnimal: any;
  currentGate: any;
  connected: boolean = false;
  quantity: any;
  busyDrafting: boolean = false;
  updatingBatchDetails: boolean = false;
  draftedAnimals: any[] = [];
  draftState: string = "";
  waitForMassState: string = "";
  waitForTagState: string = "";
  waitForGateState: string = "";
  useSgtins: boolean = true;
}

let webSocket: ReconnectingWebSocket | undefined = undefined;

class Drafting implements Module<DraftingState, any> {
  namespaced = true;
  state: DraftingState = new DraftingState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    setConnected(state: DraftingState, payload: any) {
      state.connected = payload;
    },
    updatingBatchDetails(state: DraftingState, payload: any) {
      state.updatingBatchDetails = payload;
    },
    mutateDraftedAnimals(state: DraftingState, payload: any) {
      state.draftedAnimals = payload;
    },
    updateGate(state: DraftingState, payload: any) {
      state.currentGate = payload;
    },
    updateDraftState(state: DraftingState, payload: any) {
      if (state.draftState != payload){
        state.draftState = payload;
      }   
    },
    mutateCurrentAnimal(state: DraftingState, payload: any) {
      state.currentAnimal = payload;
    },
    updateField: vuexMapFields.updateField
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    connect(context: ActionContext<DraftingState, any>) {
      webSocket = new ReconnectingWebSocket(context.rootGetters["settings/drafterSocketUrl"]());
      webSocket.onopen = event => {
        context.commit("setConnected", true);
      };
      webSocket.onerror = event => {
        context.commit("setConnected", false);
        console.log("drafter webSocket error: ", event);
      };
      webSocket.onclose = event => {
        context.commit("setConnected", false);
      };
      webSocket.onmessage = message => {
       // console.log("drafter onmessage " + message.data); //TODO: only in debug
        let content = JSON.parse(message.data);
        context.commit("mutateCurrentAnimal", content.Tag);
        context.commit("updateDraftState", content.DraftState);
        if (content.DraftState.startsWith("Send")) {
          let gate = content.DraftState.substring(4,5);
          context.commit("updateGate", gate);
          context.commit("updateDraftState", "Send");
          context.commit("updatingBatchDetails", false);
          context.commit("scale/capturedMass", content.StableWeight, { root: true });
          context.commit("updateField", { path: "waitForMassState", value: "success" });
          context.commit("updateField", { path: "waitForTagState", value: "success" });
          context.commit("updateField", { path: "waitForGateState", value: "success" });
        }
        if (content.DraftState.startsWith("Load")) {
          context.commit("updateField", { path: "waitForMassState", value: "ready" });
          context.commit("updateField", { path: "waitForTagState", value: "ready" });
          context.commit("updateField", { path: "waitForGateState", value: "ready" });
        }
        if (content.DraftState.startsWith("Weigh")) {
          if (content.DraftState.StableWeight) {
            //waitformass state
            context.commit("updateField", { path: "waitForMassState", value: "success" });
          }
          else {
             //waitformass state
             context.commit("updateField", { path: "waitForMassState", value: "busy" });
             context.commit("updateField", { path: "waitForGateState", value: "busy" });
          }
          if (content.DraftState.Tag) {
            //waitfortag state
            context.commit("updateField", { path: "waitForTagState", value: "success" });
          }
          else {
             //waitfortag state
             context.commit("updateField", { path: "waitForTagState", value: "busy" });
          }
          
        }
        if (content.DraftState.startsWith("Load") || content.DraftState.startsWith("Weigh")) {
          if (!context.state.updatingBatchDetails) {
            context.commit("updatingBatchDetails", true);
            context.dispatch("getBatchInfo");
            context.commit("updateGate", "");
        }
      }
      };
    },
    disconnect(context: ActionContext<DraftingState, any>) {
      if (webSocket) webSocket.close();
      context.commit("setConnected", false);
    },
    async startDrafter(context: ActionContext<DraftingState, any>, useSgtinScan: boolean) {
      let response = await fetch(`${store.getters["settings/startDrafterUrl"]()}?RFID=${useSgtinScan}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },

      });
      if (response.ok) {
        if (response.ok){
          context.commit("updateField", { path: "busyDrafting", value: true });
        }
      }
    },
    async pauseDrafter(context: ActionContext<DraftingState, any>) {
      let response = await fetch(`${store.getters["settings/pauseDrafterUrl"]()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },

      });
      if (response.ok){
        context.commit("updateField", { path: "busyDrafting", value: false });
      }
    },
    async stopDrafter(context: ActionContext<DraftingState, any>) {
      let response = await fetch(`${store.getters["settings/stopDrafterUrl"]()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
     
      });
      if (response.ok){
        context.commit("updateField", { path: "busyDrafting", value: false });
      }
    },
    async getDraftSetup(context: ActionContext<DraftingState, any>) {
      let response = await fetch(`${store.getters["settings/getDraftSetupUrl"]()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },

      });
      if (response.ok) {
        let json = await response.json();
        console.log(json);
      }
    },
    async putDraftSetup(context: ActionContext<DraftingState, any>) {
      let bod = context.getters.getDraftBody();
      let data = JSON.stringify(bod);
      let response = await fetch(`${store.getters["settings/putDraftSetupUrl"]()}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: data
      });

    },
    async getBatchInfo(context: ActionContext<DraftingState, any>) {

      let response = await fetch(`${store.getters["settings/getBatchInfoUrl"]()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },

      });
      if (response.ok) {
        let json = await response.json();
        let formatted = json.map((animal: any) => { return { "sgtin": animal.ElectNo, "mass": animal.Weight, "gate": animal.Gate, "numberInQueue": animal.RecNo, "time":  new Date(parseInt(animal.datestamp.substring(6, 19))).getTime() } });

        context.commit("mutateDraftedAnimals", formatted);
        context.commit("processing/updateField", { path: "currentBatchSetup.draftingResult.draftedAnimals", value: formatted }, { root: true });
        // console.log(json);
      }

    },
    async clearBatch(context: ActionContext<DraftingState, any>) {
      let response = await fetch(`${store.getters["settings/clearBatchUrl"]()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },

      });
      if (response.ok) {
        let json = await response.json();
        console.log(json);
      }
    },
  };
  getters = {
    /*
    getter(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    getDraftedAnimals(state: DraftingState, getters: any, rootState: any, rootGetters: any) {
      return state.draftedAnimals;
      // return state.draftedAnimals.map((animal) => { return { "sgtin": animal.ElectNo, "mass": animal.Weight, "gate": animal.Gate, "numberInQueue": animal.RecNo, "time":  new Date(parseInt(animal.datestamp.substring(6, 19))).getTime() } });
    },
    getDraftBody(state: DraftingState, getters: any, rootState: any, rootGetters: any) {
      let currentBatch: Models.BatchSetup = store.getters["processing/getField"]("currentBatchSetup");
      let body:any = [];
      if (currentBatch.draftingResult?.sortingConfig){
      for (let sortingConfig of currentBatch.draftingResult?.sortingConfig) {
        let val = { "Key": sortingConfig.gate.id, "Value" : [sortingConfig.condition[0].min,sortingConfig.condition[0].max]}
        body.push(val);
      }
      }

      return () => {
        return body;
      }
    },
    getField: vuexMapFields.getField
  };
}

export default new Drafting()
