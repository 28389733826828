import * as Models from "@gigalot/data-models";

export function prettyProcessingFunction(processingFunction: Models.ProcessingFunction) {
  switch (processingFunction) {
    case "new":
      return "New Intake";
    case "reprocess":
      return "Re-Process";
    case "reweigh":
      return "Re-Weigh";
    case "reimplant":
      return "Re-Implant";
    case "dispatch-to-abattoir-processed":
      return "Dispatch to Abattoir";
    case "dispatch-to-site-processed":
      return "Dispatch to Site";
    default:
      return "";
  }
}
