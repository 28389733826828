import store from "@/store";
import * as Models from "@gigalot/data-models";

export function updateAppBarText(name: string) {
  let appBarText = "";
  switch (name) {
    case "home":
      appBarText = "Main Menu";
      break;
    case "process-animal":
      {
        let processingFunction: Models.ProcessingFunction = store.getters["processing/getField"]("currentBatchSetup.processingResult.processingFunction");
        if (processingFunction === "new") appBarText = "New Intake Batch";
        else if (processingFunction === "reprocess") appBarText = "Re-Process";
        else if (processingFunction === "reweigh") appBarText = "Re-Weigh";
        else if (processingFunction === "reimplant") appBarText = "Re-Implant";
        else if (processingFunction === "dispatch-to-abattoir-processed") appBarText = "Dispatch to Abattoir";
        else if (processingFunction === "dispatch-to-site-processed") appBarText = "Dispatch to Site";
      }
      break;
    case "select-vaccination":
      appBarText = "Select Vaccination";
      break;
    case "select-intake-batch-details":
      appBarText = "Select Batch Details";
      break;
    case "select-batch-setup":
      appBarText = "Select Batch";
      break;
    case "batch-setup":
      {
        let processingFunction: Models.ProcessingFunction = store.getters["processing/getField"]("currentBatchSetup.processingResult.processingFunction");
        if (processingFunction === "new") appBarText = "New Intake Batch Setup";
        else if (processingFunction === "reprocess") appBarText = "Re-Process Setup";
        else if (processingFunction === "reweigh") appBarText = "Re-Weigh Setup";
        else if (processingFunction === "reimplant") appBarText = "Re-Implant Setup";
        else if (processingFunction === "dispatch-to-abattoir-processed") appBarText = "Dispatch to Abattoir Setup";
        else if (processingFunction === "dispatch-to-site-processed") appBarText = "Dispatch to Site Setup";
      }
      break;
    case "settings":
      appBarText = "Settings";
      break;
    case "history":
      appBarText = "History";
      break;
    case "hospital":
      appBarText = "Hospital";
      break;
    case "retag":
      appBarText = "Re-Tag";
      break;
    case "login":
      appBarText = "Login";
      break; 
    case "certificates":
      appBarText = "Certificates";
      break;
    case "draft-animal":
      appBarText = "Drafting";
    case "sync":
        appBarText = "Download";
  }
  store.commit("updateField", { path: "appBarText", value: appBarText });
}
