
import { Component, Prop, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import { SavedConfig } from "@/store/modules/sorting-gates";

@Component({
  components: {
    DimssaButton,
  },
})
export default class GateSetup extends Vue {
  //In setup mode the user can setup the gates.
  //In load mode the user can load saved setups.
  mode: "load" | "setup" = "setup";

  configName: string = "";

  gateSetupTableUpdateKey = 0;

  get savedConfigNames() {
    return this.$store.state.sortingGates.savedConfigs.map((sc: any) => sc.name);
  }

  get headers() {
    let ret: any[] = [{ value: "gate", text: "Gate", width: "75px" }];
    if (!this.draftMode) {
      ret.push({ value: "gender", text: "Gender", width: "125px" });
    }
    ret.push(
      ...[
        { value: "min", text: "Min (kg)", width: "100px" },
        { value: "max", text: "Max (kg)", width: "100px" },
        { value: "kraal", text: "Kraal" },
        { value: "vaccination", text: "Vaccination" },
        { value: "sickVaccination", text: "Sick Vaccination" },
        { value: "clear", text: "" },
      ]
    );
    return ret;
  }

  @Prop() items!: {
    gate: string | undefined;
    gender: string | undefined;
    min: number | undefined;
    max: number | undefined;
    vaccination: string | undefined;
    sickVaccination: string | undefined;
    kraal: string;
  }[];

  //sortingConfig!: Models.GateConfigComponent[];
  @Prop() vaccinations!: Models.Vaccination[];
  @Prop() kraals!: Models.Kraal[];
  @Prop() draftMode!: boolean;
  @Prop() processingResult!: Models.ProcessingResult;

  created() {
    if (!this.processingResult) throw Error("GateSetup error, no processingResult set.");
  }

  unmounted() {
    console.log("unmounted");
  }

  onUnmounted() {
    console.log("onUnmounted");
  }

  getIndex(sortGateConfig: Models.GateConfigComponent) {
    return this.processingResult.sortingConfig.findIndex((sgf) => sgf.gate.id === sortGateConfig.gate.id);
  }

  newConfig() {
    this.mode = "setup";
    for (const g of this.processingResult.sortingConfig) {
      this.clearGateConfigComponent(g);
    }
    this.gateSetupTableUpdateKey++;
    this.configName = "";
    this.saveButtonSateInternal = "ready";
    this.$emit("change");
  }

  save() {
    this.mode = "setup";
    console.log("Saving gate setup.");
    if (!this.configName) return;
    if (this.$store.state.sortingGates.savedConfigs.length > 100) {
      this.$store.commit("popup/displayOk", "Too many gate configurations already saved.");
    } else {
      const config: SavedConfig = { name: this.configName, config: lodash.cloneDeep(this.processingResult.sortingConfig) };
      this.$store.commit("sortingGates/saveGateConfig", config);
      this.gateConfigLog(config);
      this.saveButtonSateInternal = "success";
    }
  }

  async load() {
    this.mode = this.mode === "load" ? "setup" : "load";
    this.saveButtonSateInternal = "ready";
  }

  saveButtonSateInternal: ButtonState = "ready";
  get saveButtonState(): ButtonState {
    if (!this.configName) return "disabled";
    return this.saveButtonSateInternal;
  }

  get loadButtonState(): ButtonState {
    return this.$store.state.sortingGates.savedConfigs.length ? "ready" : "disabled";
  }

  gateConfigLog(config: SavedConfig) {
    console.log(`Config name: ${config.name}`);
    let i = 0;
    config.config.map((c) => {
      const gender = c.gender ? c.gender : "N/A";
      const min = c.condition.length && c.condition[0].min ? c.condition[0].min : "N/A";
      const max = c.condition.length && c.condition[0].max ? c.condition[0].max : "N/A";
      const kraal = c.destinationKraalId ? c.destinationKraalId : "N/A";
      const v = c.vaccinations[0].vaccination?.description ?? "N/A";
      const sv = c.vaccinations[1].vaccination?.description ?? "N/A";
      console.log(`Gate ${++i}: ${gender}, ${min}, ${max}, ${kraal}, ${v}, ${sv}`);
    });
  }

  loadConfigSelect(savedConfig: SavedConfig) {
    //console.log(JSON.stringify(savedConfig, null, 2));
    console.log("Loading gate setup.");
    this.gateConfigLog(savedConfig);
    this.$store.commit("processing/updateField", {
      path: "currentBatchSetup.processingResult.sortingConfig",
      value: lodash.cloneDeep(savedConfig.config),
    });
    this.configName = savedConfig.name;
    this.mode = "setup";
    this.$emit("change");
  }

  deleteConfig(configName: string) {
    console.log("delete", configName);
    this.$store.commit("sortingGates/deleteGateConfig", configName);
    if (this.$store.state.sortingGates.savedConfigs.length === 0) {
      this.configName = "";
      this.mode = "setup";
    }
  }

  genderChange(g: Models.GateConfigComponent, gender: string | null) {
    console.log("g:", JSON.stringify(g));
    console.log("index:", this.getIndex(g));
    console.log(JSON.stringify(gender));

    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig[${this.getIndex(g)}].gender`,
      value: gender === null ? undefined : gender,
    });
  }

  minMassChange(g: Models.GateConfigComponent, minMass: any) {
    console.log("index:", this.getIndex(g));
    console.log(JSON.stringify(minMass));
    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig[${this.getIndex(g)}].condition[0].min`,
      value: minMass === "" ? undefined : parseFloat(minMass),
    });
  }

  maxMassChange(g: Models.GateConfigComponent, maxMass: any) {
    console.log("index:", this.getIndex(g));
    console.log(JSON.stringify(maxMass));
    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig[${this.getIndex(g)}].condition[0].max`,
      value: maxMass === "" ? undefined : parseFloat(maxMass),
    });
  }

  kraalChange(g: Models.GateConfigComponent, kraal: Models.Kraal | null) {
    console.log("index:", this.getIndex(g));

    let kraalId = undefined;
    let kraalType = undefined;
    if (kraal) {
      kraalId = kraal.kraalId;
      kraalType = kraal.kraalType;
    }

    if (kraalId && ["Abattoir", "Site"].includes(kraalId)) kraalType = kraalId;

    console.log(`kraalId: ${kraalId}, kraalType: ${kraalType}`);

    const index = this.getIndex(g);
    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig[${index}].destinationKraalId`,
      value: kraalId,
    });
    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig[${index}].destinationType`,
      value: kraalType,
    });
  }

  vaccinationChange(g: Models.GateConfigComponent, vaccination: Models.Vaccination | null) {
    console.log("index:", this.getIndex(g));
    console.log(JSON.stringify(vaccination));
    this.vaccinationSelected(g, vaccination === null ? undefined : vaccination, "normal");
  }

  sickVaccinationChange(g: Models.GateConfigComponent, sickVaccination: Models.Vaccination | null) {
    console.log("index:", this.getIndex(g));
    console.log(JSON.stringify(sickVaccination));
    this.vaccinationSelected(g, sickVaccination === null ? undefined : sickVaccination, "sick");
  }

  // Deep cloning was causing problems when creating new gate setup,
  // saving and clearing setup left UI stuck.
  clearGateConfigComponent(g: Models.GateConfigComponent) {
    g.condition = [new Models.SortingCondition()];
    g.vaccinations = [];
    g.vaccinations.push({ description: "normal", vaccination: undefined });
    g.vaccinations.push({ description: "sick", vaccination: undefined });
    g.gender = this.draftMode ? "Mixed" : "";
    g.destinationType = "";
    g.destinationKraalId = undefined;

    // Probably not needed?
    this.processingResult.sortingConfig[this.getIndex(g)] = g;//lodash.cloneDeep(g);

    this.gateConfigLog({ name: "Cleared", config: this.processingResult.sortingConfig });

    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig`,
      value: this.processingResult.sortingConfig,
    });
  }

  vaccinationSelected(g: Models.GateConfigComponent, vaccination: Models.Vaccination | undefined, type: "normal" | "sick") {
    let vaccinationIndex = lodash.findIndex(g.vaccinations, { description: type });
    this.$store.commit("updateField", {
      path: `processing.currentBatchSetup.processingResult.sortingConfig[${this.getIndex(g)}].vaccinations[${vaccinationIndex}].vaccination`,
      value: vaccination,
    });
  }
}
