
import { Component, Prop, Vue } from "vue-property-decorator";
import * as Models from "@gigalot/data-models";

@Component
export default class AnimalEventHistory extends Vue {
  @Prop() animal!: Models.Animal;

  get moment() {
    return this.$store.state.moment;
  }
}
