
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { prettyProcessingFunction } from "@/helpers/pretty-processing-function";

type ReprocessType = "all-reprocess" | "reprocess" | "reweigh" | "reimplant";

@Component({
  components: {
    DimssaButton,
  },
})
export default class SelectBatchSetup extends Vue {
  @Prop() processingFunction?: Models.ProcessingFunction | "all-reprocess";
  //to help with persisting processingFunction, should not set prop directly (causes Vue to complain)
  _processingFunction?: Models.ProcessingFunction | "all-reprocess";

  prettyProcessingFunction = prettyProcessingFunction;

  selectRow(item: any) {
    this.selected = [item];
  }

  isReprocess(processingFunction: Models.ProcessingFunction | "all-reprocess" | undefined) {
    return processingFunction && ["all-reprocess", "reprocess", "reweigh", "reimplant"].includes(processingFunction);
  }

  //This is ignored if processFunction is for new or dispatch batches
  reprocessType: ReprocessType = "all-reprocess";

  @Watch("reprocessType")
  reprocessTypeChanged(reprocessType: ReprocessType) {
    console.log("reprocessType: ", reprocessType);
    this._processingFunction = reprocessType;
    this.getBatchSetups();
  }

  get headers() {
    let ret = [];
    ret.push({ text: "Reference", value: "processingResult.reference" });
    if (this.isReprocess(this._processingFunction)) {
      ret.push({ text: "Type", value: "batchDetails.type" });
    }
    ret.push({ text: "Owner", value: "batchDetails.customFeeder", align: "left" });
    if (this.isReprocess(this._processingFunction)) {
      ret.push({ text: "New Owner", value: "batchDetails.newCustomFeeder", align: "left" });
    }
    if (this._processingFunction === "dispatch-to-abattoir-processed") {
      ret.push({ text: "Abattoir", value: "batchDetails.destinationName", align: "left" });
    }
    if (this._processingFunction === "dispatch-to-site-processed") {
      ret.push({ text: "Site", value: "batchDetails.destinationName", align: "left" });
    }
    ret.push({ text: "Quantity", value: "batchDetails.quantity" });
    if (this._processingFunction === "new") {
      ret.push({ text: "Intake Batch Description", value: "batchDetails" });
      ret.push({ text: "Arrival Weighbridge ", value: "batchDetails.weighbridgeReference" });
    }
    ret.push({ text: "Notes", value: "batchDetails.notes" });
    return ret;
  }

  batchSetups: Models.BatchSetup[] = [];
  selected: Models.BatchSetup[] = [];
  search: string = "";

  async getBatchSetups() {
    if (this._processingFunction === "all-reprocess") {
      const r1 = await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", "reprocess");
      const r2 = await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", "reweigh");
      const r3 = await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", "reimplant");
      this.batchSetups = [...r1, ...r2, ...r3];
    } else {
      this.batchSetups = await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", this._processingFunction);
    }

    this.batchSetups.sort((b1, b2) => {
      let temp1 = b1.guid.split("-");
      let temp2 = b2.guid.split("-");
      if (temp1.length < 1 || temp2.length < 1) return 0;
      let time1 = parseInt(temp1[0]);
      let time2 = parseInt(temp2[0]);
      if (isNaN(time1) || isNaN(time2)) return 0;
      else return time2 - time1; //sort by guid creation time descending order
    });
  }

  async created() {
    if (!this.processingFunction) {
      this.$router.push({ name: "home" });
      return;
    }

    //Doing this so that initially all reprocess batches are displayed.
    if (this.processingFunction === "reprocess") {
      this._processingFunction = "all-reprocess";
    } else {
      this._processingFunction = this.processingFunction;
    }

    await this.getBatchSetups();
  }

  mounted() {
    this.$store.commit("navFuncs", { save: undefined, back: () => this.$router.push({ name: "home" }) });
  }

  destroyed() {
    this.$store.commit("navFuncs", { save: undefined, back: undefined });
  }

  get uiText() {
    switch (this._processingFunction) {
      case "new":
        return "New Intake";
      case "all-reprocess":
        return "Re-Work";
      case "reprocess":
        return "Re-Process";
      case "reimplant":
        return "Re-Implant";
      case "reweigh":
        return "Re-Weigh";
      case "dispatch-to-abattoir-processed":
        return "Dispatch to Abattoir";
      case "dispatch-to-site-processed":
        return "Dispatch to Site";
    }
    return "";
  }

  edit() {
    if (!this._processingFunction) throw Error("No processing function selected");
    if (!this.selected.length) throw Error("No batch setup selected.");
    const batchSetup: Models.BatchSetup = this.selected[0];
    batchSetup.processingFunction = this.selected[0].processingFunction;

    // Attach owner data to ProcessingResult
    if (batchSetup.processingResult) {
      batchSetup.processingResult.customFeederGuid = batchSetup.batchDetails.customFeeder?.guid;
      batchSetup.processingResult.newCustomFeederGuid = batchSetup.batchDetails.newCustomFeeder?.guid;
    }

    this.$store.commit("processing/updateField", { path: "currentBatchSetup", value: batchSetup });
    this.$router.push({ name: "batch-setup", params: { modeProp: "view" } });
  }
}
