import * as RtcClient from "@gigalot/rtc-client";
import * as graphQlWs from "graphql-ws";
import { v4 as uuid } from "uuid";

abstract class UsedWebSocketClient {
  static readonly CLOSED: number = 0;
  static readonly CLOSING: number = 1;
  static readonly CONNECTING: number = 2;
  static readonly OPEN: number = 3;
  abstract readyState: number;
  abstract send(data: string | ArrayBufferLike | Blob | ArrayBufferView): void;
  abstract onclose: ((this: UsedWebSocketClient, ev: CloseEvent) => any) | null;
  abstract onerror: ((this: UsedWebSocketClient, ev: Event) => any) | null;
  abstract onmessage: ((this: UsedWebSocketClient, ev: MessageEvent) => any) | null;
  abstract onopen: ((this: UsedWebSocketClient, ev: Event) => any) | null;
  abstract close(code?: number, reason?: string): void;
}

function readyState(dataChannel: RTCDataChannel | null) {
  if (!dataChannel) return undefined;
  switch (dataChannel.readyState) {
    case "closed":
      return UsedWebSocketClient.CLOSED;
    case "closing":
      return UsedWebSocketClient.CLOSING;
    case "connecting":
      return UsedWebSocketClient.CONNECTING;
    case "open":
      return UsedWebSocketClient.OPEN;
  }
}

export function createClient(reject: any, onprogress?: ((num: number, progress: number, total: number) => void)) {
  const id = uuid();
  const dataChannel = RtcClient.createDataChannel();

  if (!dataChannel) {
    reject("could not create data channel.");
    return;
  }

  let closedNormally = false;

  class RtcClientWebSocketAdapter extends UsedWebSocketClient {
    constructor() {
      super();
      this.readyState = RtcClientWebSocketAdapter.CLOSED;

      dataChannel.onbufferedamountlow = (ev) => {
        console.log(`[${id}] gql-rtc onbufferedamountlow:`, ev);
      };
      dataChannel.onclose = (ev: Event) => {
        this.readyState = readyState(dataChannel) ?? UsedWebSocketClient.CLOSED;
        console.log(`[${id}] gql-rtc adapter onclose: ` + JSON.stringify(ev));
        this.onclose?.(new CloseEvent("TODO", { code: 0, reason: "TODO", wasClean: true }));
        if (!closedNormally) {
          console.warn("data channel closed prematurely.");
          reject("data channel closed prematurely.");
          this.onerror?.(new Event("error"));
        }
      };
      dataChannel.onerror = (ev: Event) => {
        console.log(`[${id}] gql-rtc adapter onerror: `, ev);
        let readyStateVal = readyState(dataChannel);
        if (readyStateVal) this.readyState = readyStateVal;
        this.onerror?.(ev);
      };
      dataChannel.onmessage = async (ev: MessageEvent) => {
        const self = this;
        await RtcClient.receive(ev, dataChannel, (ev: any) => {
          console.log(`[${id}] gql-rtc adapter message received: ` + ev.data.slice(0,100));
          if (self.onmessage) self.onmessage(ev);
        }, onprogress);
      };
      dataChannel.onopen = (ev: Event) => {
        this.readyState = readyState(dataChannel) ?? UsedWebSocketClient.OPEN;
        console.log(`[${id}] gql-rtc adapter onopen: ` + JSON.stringify(ev));
        this.onopen?.(ev);
      };
    }
    readyState: number;
    send(data: string | ArrayBufferLike | Blob | ArrayBufferView): void {
      console.log(`[${id}] RtcClientWebSocketAdapter.send`, data);
      RtcClient.send(data, dataChannel);
    }
    onclose: ((this: UsedWebSocketClient, ev: CloseEvent) => any) | null = null;
    onerror: ((this: UsedWebSocketClient, ev: Event) => any) | null = null;
    onmessage: ((this: UsedWebSocketClient, ev: MessageEvent) => any) | null = null;
    onopen: ((this: UsedWebSocketClient, ev: Event) => any) | null = null;
    close(code?: number, reason?: string): void {
      console.log(`[${id}] Closing data channel. Code: ${code}, reason: ${reason}`);
      closedNormally = true;
      dataChannel.close();
    }
  }

  const client = graphQlWs.createClient({
    url: "It don't matter",
    webSocketImpl: RtcClientWebSocketAdapter,
  });

  return client;
}