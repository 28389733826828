
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields, mapMultiRowFields, default as store } from "@/store";

@Component({
  components: {
    DimssaButton,
  },
  computed: {},
})
export default class SelectVaccination extends Vue {
  search: string = "";
  selected: Models.Vaccination[] = [];
  mounted() {}

  get vaccinations() {
    return this.$store.getters["getField"]("vaccinations");
  }

  selectVaccination() {
    let path = this.$store.getters["getField"]("selectVaccinationSetPath");
    this.$store.commit("updateField", { path: path, value: this.selected[0] });
    this.$router.push(this.$store.getters["getField"]("selectVaccinationReturnRoutePushData"));
  }
}
