
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields, default as store } from "@/store";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields({
      hslAddress: "settings.hsl.address",
      scalePort: "settings.scale.port",
      allflexPort: "settings.allflex.port",
      restPort: "settings.rest.port",
      numAnimalsPerDownload: "settings.numAnimalsPerDownload",
      // minMass: "scale.minMass",
    }),
  },
})
export default class Settings extends Vue {
  mounted() {
    if (!store.getters["getField"]("hardwareServiceLayer")) {
      store.commit("updateField", { path: "hardwareServiceLayer", value: {} });
    }

    this.$store.commit("navFuncs", { save: undefined, back: () => this.$router.go(-1) });
  }

  destroyed() {
    this.$store.commit("navFuncs", { save: undefined, back: undefined });
  }

  set minMass(minMass: string) {
    if (minMass) this.$store.commit("scale/minMass", parseFloat(minMass));
  }

  get minMass() {
    return this.$store.state.scale.minMass;
  }
}
