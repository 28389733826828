import { Action, ActionContext, default as Vuex, Module } from "vuex";
import store from "../../store";

class CertificatesState {
  certInfo: any = {};
  certObject: any = undefined;
  getCertButtonState: string = "ready";
  setCertButtonState: string = "ready";
}

class CertificatesModule implements Module<CertificatesState, any> {
  namespaced = true;
  state: CertificatesState = new CertificatesState();
  mutations = {
    setCertificateInfo(state: CertificatesState, payload: any) {
      state.certInfo = payload;
    },
    setCertificateObject(state: CertificatesState, payload: any) {
      state.certObject = payload;
    }
  };
  actions = {
    async onConnectedToProxy(context: ActionContext<CertificatesState, any>) {
      //if we have certificate
      if (context.state.certObject) {
         context.state.setCertButtonState = "busy";
        //send cert to proxy
        try {
          await context.dispatch("getCertificateInfoProxy");
          await context.dispatch("setCertificateProxy");
          context.state.setCertButtonState = "success";
        }
        catch (err) {
          context.state.setCertButtonState = "error";
          throw Error("Could not set certificate on proxy: " + err);
  
        }
      }
    },
    //set certificate
    async setCertificateProxy(context: ActionContext<CertificatesState, any>) {
      try {
      context.state.setCertButtonState = "busy";
      const jwt = await context.dispatch("user/getOnlineIdToken", undefined, { root: true });

      const data = {
        cert: context.state.certObject.cert,
        key: context.state.certObject.key,
      };

      let response = await fetch(`${store.getters["settings/proxyCertUrl"]()}/certificateKeys`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log("Certificate sent to proxy");
        context.state.setCertButtonState = "success";
        //   this.buttonState = "success";
      } else {
        let errorJson = await response.json();
        let s = "";
        for (let error of errorJson.errors) {
          s += `\n${error.message}`;
        }
        context.state.setCertButtonState = "error";
        throw Error("Response not ok: " + s);
      }
    }catch (err) {
      context.state.setCertButtonState = "error";
    }
    },
    //get certificate
    async getCertificateCloud(context: ActionContext<CertificatesState, any>) {
      context.state.getCertButtonState = "busy";
      const jwt = await context.dispatch("user/getOnlineIdToken", undefined, { root: true });

      let options: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Bearer " + jwt },
      };
    
      let response = await fetch(context.rootGetters["urlCert"](), options);
      
      if (response.ok) {
        let json = await response.json();

        console.log("json received");
        console.log(json);
        let o = {
          "cert": json.cert,
          "key": json.key
        }
        context.commit("setCertificateObject", o);
        context.state.getCertButtonState = "success";
      } else {
        let errorJson = await response.json();
        let s = "";
        for (let error of errorJson.errors) {
          s += `\n${error.message}`;
        }
        context.state.getCertButtonState = "error";
        throw Error("Response not ok: " + s);
      }
    },
    //get certificate info
    async getCertificateInfoProxy(context: ActionContext<CertificatesState, any>) {
      const jwt = await context.dispatch("user/getOnlineIdToken", undefined, { root: true });
      
      let response = await fetch(`${store.getters["settings/proxyCertUrl"]()}/certificateInfo`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + jwt,
        },

      });
      if (response.ok) {
        let json = await response.json();

      console.log(json);
      context.commit("setCertificateInfo", json);
    }
  }

  };
}

const certificatesModule: CertificatesModule = new CertificatesModule();
export default certificatesModule;
