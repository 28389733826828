import { default as Vuex, Module, ActionContext } from "vuex";
import lodash from "lodash";
import ReconnectingWebSocket from "reconnecting-websocket";

export type MassCaptureState = "not-capturing" | "capturing" | "captured" | "failed";

class ScaleState {
  connected: boolean = false;
  massQueueSize: number = 50;
  //massQueue: number[] = [];
  //currentMass?: number = undefined;
  massCaptureState: MassCaptureState = "not-capturing";
  capturedMass?: number = undefined;

  sampleSize: number = 20;
  epsilon: number = 20 * 2.5; //50.0

  minMass: number = 50; //kg
  maxMass: number = 10000; //kg

  timeout: number = 10; //how many seconds before mass capture times out.

  zeroScalePopup: boolean = false;
  zeroScaleMaxTime = 5 * 1000; //ms WARNING: this is also set in reset to force vuex persist update, beware
  zeroScaleCurrentTime = 0; //ms
}

let webSocket: ReconnectingWebSocket | undefined = undefined;

// let cancelTimer = (context: any) => {
//   let timeout = this.setTimeout;
//   lodash.debounce(() => {

//   }, )
// }
let massCallback: any = undefined;

let cancelTimer: any = undefined;

function startTimeoutTimer(context: ActionContext<ScaleState, any>) {
  cancelTimer = lodash.debounce(() => {
    console.log("mass capture timeout");
    context.commit("setMassCaptureState", "failed");
    context.commit("capturedMass", undefined);
    massQueue = [];
  }, context.state.timeout * 1000);
  cancelTimer();
}

function cancelTimeoutTimer() {
  if (cancelTimer) cancelTimer.cancel();
}

//let currentMass: number | undefined = undefined;
let massQueue: number[] = [];
let historyMassQueue: number[] = [];

function addMass(context: ActionContext<ScaleState, any>, mass: number) {
  if (massCallback) massCallback(mass);
  //currentMass = mass;
  massQueue.push(mass);
  historyMassQueue.push(mass);
  if (massQueue.length > context.state.massQueueSize) {
    massQueue.shift();
  }
  if (historyMassQueue.length > context.state.massQueueSize) {
    historyMassQueue.shift();
  }

  if (context.state.massCaptureState === "capturing" && massQueue.length >= context.state.sampleSize) {
    let samples: number[] = massQueue.slice(context.state.sampleSize * -1);

    let avg = lodash.mean(samples);

    let total = 0;
    samples.forEach((sample: number) => {
      total += Math.abs(sample - avg);
    });

    if (total < context.state.epsilon && mass >= context.state.minMass && mass <= context.state.maxMass) {
      cancelTimeoutTimer();
      //state.capturedMass = avg;
      context.commit("capturedMass", parseFloat(avg.toFixed(1)));
      massQueue = [];
      //state.massCaptureState = "captured";
      context.commit("setMassCaptureState", "captured");
    }
  }
}

//TODO: attempt to reconnect scale after it has disconnected

class Scale implements Module<ScaleState, any> {
  namespaced = true;
  state: ScaleState = new ScaleState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    reset(state: ScaleState) {
      console.log("scale reset");
      state.connected = false;
      //state.currentMass = undefined;
      //state.massQueue = [];
      state.capturedMass = undefined;
      //currentMass = undefined;
      massQueue = [];
      state.massCaptureState = "not-capturing";
      state.zeroScalePopup = false;
      state.zeroScaleMaxTime = 5 * 1000; //force vuex persist update
    },
    // addMass(state: ScaleState, mass: number) {
    //   state.currentMass = mass;
    //   state.massQueue.push(mass);
    //   if (state.massQueue.length > state.massQueueSize) {
    //     state.massQueue.shift();
    //   }

    //   if (state.massCaptureState === "capturing" && state.massQueue.length >= state.sampleSize) {
    //     let samples: number[] = state.massQueue.slice(state.sampleSize * -1);

    //     let avg = lodash.mean(samples);

    //     let total = 0;
    //     samples.forEach((sample: number) => {
    //       total += Math.abs(sample - avg);
    //     });

    //     if (total < state.epsilon && mass >= state.minMass && mass <= state.maxMass) {
    //       cancelTimeoutTimer();
    //       state.capturedMass = avg;
    //       state.massCaptureState = "captured";
    //     }
    //   }
    // },
    capturedMass(state: ScaleState, capturedMass: number) {
      state.capturedMass = capturedMass;
    },
    setConnected(state: ScaleState, connected: boolean) {
      state.connected = connected;
    },
    setMassCaptureState(state: ScaleState, massCaptureState: MassCaptureState) {
      state.massCaptureState = massCaptureState;
    },
    massCallback(state: ScaleState, _massCallback: any) {
      console.log("massCallback");
      massCallback = _massCallback;
    },
    zeroScalePopup(state: ScaleState, zeroScalePopup: boolean) {
      state.zeroScalePopup = zeroScalePopup;
    },
    zeroScaleCurrentTime(state: ScaleState, zeroScaleCurrentTime: number) {
      state.zeroScaleCurrentTime = zeroScaleCurrentTime;
    },
    minMass(state: ScaleState, minMass: number) {
      state.minMass = minMass;
    }

    // setCapturedMass(state: ScaleState, capturedMass: number) {
    //   state.capturedMass = capturedMass;
    //   state.massCaptureState = "captured";
    // }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async onAppCreated(context: any) {
      //await context.dispatch("dataManager/onAppCreated");
      context.commit("reset");
    },
    connect(context: ActionContext<ScaleState, any>) {
      console.log("-------");
      console.log(context.rootGetters["settings/scaleAddress"]());
      webSocket = new ReconnectingWebSocket(context.rootGetters["settings/scaleAddress"]());
      webSocket.onopen = event => {
        context.commit("setConnected", true);
      };
      webSocket.onerror = event => {
        context.commit("setConnected", false);
        context.commit("setMassCaptureState", "failed");
        cancelTimeoutTimer();

        console.log("scale webSocket error: ", event);
      };
      webSocket.onclose = event => {
        cancelTimeoutTimer();
        context.commit("setConnected", false);
      };
      webSocket.onmessage = message => {
        if (!context.state.connected) context.commit("setConnected", true);
        //console.log(message.data);
        //context.commit("addMass", +message.data);
        //TODO: ability to work with decimals
        addMass(context, +message.data);
      };
    },
    disconnect(context: ActionContext<ScaleState, any>) {
      cancelTimeoutTimer();
      if (webSocket) webSocket.close();
      context.commit("setConnected", false);
    },
    captureMass(context: ActionContext<ScaleState, any>) {
      if (context.state.massCaptureState === "capturing") return;

      if (!context.state.connected) {
        throw Error("Scale not connected to web socket");
      }

      context.commit("setMassCaptureState", "capturing");
      massQueue = [];
      startTimeoutTimer(context);

      //start the cancel timer
      //if cancel timer reached before capturing mass then mass capture fails
      //if mass capture is successful then cancel the cancel timer
    }
  };
  getters = {
    /*
    getter(state: ScaleState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    massQueue(state: ScaleState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return massQueue;
      };
    },
    historyMassQueue(state: ScaleState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        return historyMassQueue;
      };
    }
    // currentMass(state: ScaleState, getters: any, rootState: any, rootGetters: any) {
    //   return () => {
    //     return currentMass;
    //   };
    // }
  };
}

export default new Scale()
