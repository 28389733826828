var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"headers":[
    { text: 'Date/Time', value: 'time' },
    { text: 'Event', value: 'type' },
    { text: 'Batch ref', value: 'reference' },
    { text: 'Mass', value: 'mass' },
    { text: 'Days', value: 'days' },
    { text: 'Mass Gain/Loss', value: 'massDiff' },
    { text: 'ADG', value: 'adg' },
    { text: 'From Kraal', value: 'sourceKraal' },
    { text: 'To Kraal', value: 'destinationKraal' },
    { text: 'Vaccination', value: 'vaccination' },
    { text: 'Ailment', value: 'ailment' },
    { text: 'Treatment', value: 'treatment' },
  ],"items":_vm.animal.events,"items-per-page":-1,"hide-default-footer":"","single-expand":"","show-expand":"","item-key":"time"},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":[
          { text: 'Dispensary', value: 'item' },
          { text: 'Dosage', value: 'dosage' },
          { text: 'Injected', value: 'injected' },
        ],"items":item.doses,"items-per-page":-1,"hide-default-footer":""}})],1)]}},{key:`item.time`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.time).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:`item.adg`,fn:function({ item }){return [_vm._v(" "+_vm._s(![null, undefined].includes(item.adg) ? Math.round(item.adg * 100) / 100 : "")+" ")]}},{key:`item.massDiff`,fn:function({ item }){return [_vm._v(" "+_vm._s(![null, undefined].includes(item.massDiff) ? Math.round(item.massDiff * 100) / 100 : "")+" ")]}},{key:`item.type`,fn:function({ item }){return [(item.type === 'processing_new')?_c('div',[_vm._v("New Intake")]):(item.type === 'processing_reprocess')?_c('div',[_vm._v("Re-Process")]):(item.type === 'processing_reweigh')?_c('div',[_vm._v("Re-Weigh")]):(item.type === 'processing_reimplant')?_c('div',[_vm._v("Re-Implant")]):(item.type === 'dispatch-to-abattoir-processed')?_c('div',[_vm._v("Dispatch")]):(item.type === 'hospital')?_c('div',[_vm._v("Hospital")]):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }