import { default as Vuex, Module } from "vuex";

type PopupMode = "ok" | "yes-no";

class PopupState {
  [key: string]: any;
  visible: boolean = false;
  popupMessage: string = "";
  mode: PopupMode = "ok";
  yesAction: Function = () => { };
}

class Popup implements Module<PopupState, any> {
  namespaced = true;
  state: PopupState = new PopupState();
  mutations = {
    hide(state: PopupState) {
      state.visible = false;
      state.popupMessage = "";
      state.yesAction = () => { };
    },
    displayOk(state: PopupState, payload: { message: string; yesAction: Function; } | string) {
      state.visible = true;
      state.mode = "ok";
      if (typeof payload === "string") {
        state.popupMessage = payload;
        state.yesAction = () => { };
      } else {
        state.popupMessage = payload.message;
        state.yesAction = payload.yesAction;
      }
    },
    displayYesNo(state: PopupState, payload: { message: string; yesAction: Function; }) {
      state.visible = true;
      state.mode = "yes-no";
      state.popupMessage = payload.message;
      state.yesAction = payload.yesAction;
    }
  };
}

export default new Popup()
