
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { uploadEmitter } from "@/main";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Home extends Vue {
  selectedBatch: any[] = [];

  tableModel: any;

  numNewIntakeBatches: number | "" = "";
  numReprocessBatches: number | "" = "";
  //numReweighBatches: number | "" = "";
  //numReimplantBatches: number | "" = "";
  numDispatchToAbattoirBatches: number | "" = "";
  numDispatchToSiteBatches: number | "" = "";

  //numUnUploadedItems: number | "?" = "?";
  get numUnUploadedItems() {
    return this.$store.state.upload.numUnUploadedItems;
  }

  get buttonState(): ButtonState {
    if (!this.$store.state.sortingGates.gates.length) return "disabled";
    //if (!this.$store.state.connectedToProxy) return "disabled";
    else return "ready";
  }

  async onUpload(ev: "success" | "error") {
    //this.numUnUploadedItems = await this.$store.dispatch("dataManager/getNumUnUploadedItems");
    if (ev === "success") this.uploadButtonState = "success";
    if (ev === "error") this.uploadButtonState = "error";
  }

  async mounted() {
    this.$store.dispatch("dataManager/addOnInitializedCallback", async () => {
      this.numNewIntakeBatches = (await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", "new")).length;

      const r1 = (await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", "reprocess")).length;
      const r2 = (await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", "reweigh")).length;
      const r3 = (await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", "reimplant")).length;
      this.numReprocessBatches = r1 + r2 + r3;

      this.numDispatchToAbattoirBatches = (await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", "dispatch-to-abattoir-processed")).length;
      this.numDispatchToSiteBatches = (await this.$store.dispatch("dataManager/getUnfinishedBatchSetups", "dispatch-to-site-processed")).length;
      const numUnUploadedItems = await this.$store.dispatch("dataManager/getNumUnUploadedItems");
      this.$store.commit("upload/numUnUploadedItems", numUnUploadedItems);
    });

    if (this.$store.state.upload.isBusyUploading) this.uploadButtonState = "busy";

    uploadEmitter.on("upload", this.onUpload);
  }

  destroyed() {
    uploadEmitter.off("upload", this.onUpload);
  }

  uploadButtonState: ButtonState = "ready";

  async upload() {
    this.uploadButtonState = "busy";
    if (this.$store.state.upload.isBusyUploading) {
      console.log("still busy uploading");
      return;
    }
    try {
      await this.$store.dispatch("upload/upload");
      this.uploadButtonState = "success";
    } catch (err) {
      this.uploadButtonState = "error";
      this.$store.commit("log/message", { message: "Upload error: " + (err as any).message ?? err, type: "error" });
    }
    //this.numUnUploadedItems = await this.$store.dispatch("dataManager/getNumUnUploadedItems");
  }

  newBatchSetup(processingFunction: Models.ProcessingFunction) {
    if (!processingFunction) throw Error("No processing function selected");
    let batchSetup: Models.BatchSetup;

    batchSetup = new Models.BatchSetup();
    let processingResult: Models.ProcessingResult = new Models.ProcessingResult(
      processingFunction,
      Date.now(),
      this.$store.getters["user/getUpstreamMetadata"]()
    );
    batchSetup.processingResult = processingResult;
    batchSetup.processingResult.station = this.$store.state.processingStation;
    batchSetup.station = this.$store.state.processingStation;
    batchSetup.metadata = this.$store.getters["user/getUpstreamMetadata"]();
    if (processingResult.processingFunction === "dispatch-to-site-processed") {
      processingResult.destinationGcp = batchSetup.batchDetails.destinationGcp;
      processingResult.destinationGuid = batchSetup.batchDetails.destinationGuid;
      processingResult.destinationType = batchSetup.batchDetails.destinationType;
      processingResult.destinationName = batchSetup.batchDetails.destinationName;
    }
    //if (["reprocess", "reimplant", "reweigh"].includes(this._processingFunction)) batchSetup.batchDetails = { quantity: "" };
    //batchSetup.batchDetails = { quantity: "" };
    batchSetup.processingFunction = processingFunction;
    batchSetup.batchDetails.reference = ""; //for UI reactivity
    this.$store.commit("processing/updateField", { path: "currentBatchSetup", value: batchSetup });
    this.$router.push({ name: "batch-setup", params: { modeProp: "edit" } });
  }
}
