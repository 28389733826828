
import { Component, Vue, Watch } from "vue-property-decorator";
import lodash from "lodash";
import wb from "./registerServiceWorker";
import VueCookies from "vue-cookies";
import { addRtcReadyCallback } from "./main";
import * as animalList from "@/helpers/animal-list";

Vue.use(VueCookies);

@Component({})
export default class App extends Vue {
  get moment() {
    return this.$store.state.moment;
  }

  navigationDrawer: boolean = false;

  async created() {
    if (wb)
      wb.addEventListener("waiting", async () => {
        //Update is ready, waiting
        this.$store.commit("popup/displayYesNo", {
          message: "An update is available. Would you like to update?",
          yesAction: async () => {
            this.$store.commit("popup/hide");
            if (wb) {
              await wb.messageSW({ type: "SKIP_WAITING" });
              window.location.reload(true);
            } else throw Error("No wb to messageSW SKIP_WAITING");
          },
        });
      });

    try {
      await this.$store.dispatch("onAppCreated");
    } catch (err) {
      this.$store.commit("popup/displayOk", "Error: " + err.message);
    }

    this.$store.dispatch("dataManager/addOnInitializedCallback", async () => {
      animalList.initialize(this.$store)
    });

    // let self = this;
    // lodash.debounce(() => {
    //   self.hasConfig = true;
    // }, 2500)();
    try {
      //await this.getConfigFromHsl();
      //if (this.$store.getters["user/isUserRegistered"]() && (this.$store.state.environment === "production" || this.$store.state.environment === "staging")) {
      if (this.$store.getters["user/isUserRegistered"]()) {
        addRtcReadyCallback(() => this.$store.dispatch("upload/upload"));
      }
    } catch (err) {
      console.log(err);
    }
  }

  beforeMount() {
    const preventNav = (event: BeforeUnloadEvent) => {
      if (this.$store.state.sync.state === "busy") {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", preventNav);
    this.$once("hook:beforeDestroy", () => window.removeEventListener("beforeunload", preventNav));
  }

  mounted() {
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }

  toggleLightDarkMode() {
    this.$store.commit("lightDarkMode", this.$store.state.lightDarkMode === "light" ? "dark" : "light");
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
  }

  logout() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to log out?",
      yesAction: () => {
        this.$store.commit("popup/hide");
        this.$store.dispatch("user/signOut");
      },
    });
  }
}
