import { default as Vuex, Module, ActionContext } from "vuex";

class LogMessage {
  message?: string;
  type?: "error" | "warn" | "info";
  time?: number;
}

class LogState {
  //state of the vuex module
  recentMessage: string = "";
  messageLimit: number = 100;
  messages: LogMessage[] = [];
}

class Log implements Module<LogState, any> {
  namespaced = true;
  state: LogState = new LogState();
  mutations = {
    /*
    mutation(state: LogState, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    message(state: LogState, payload: { message: string; type?: "error" | "warn" | "info"; }) {
      console.log(`log/message: ${JSON.stringify(payload)}`);
      //console.log("log/message: " + JSON.stringify(payload));
      state.recentMessage = payload.message;
      state.messages.push({
        message: payload.message,
        type: payload.type || "error",
        time: Date.now()
      });
      while (state.messages.length > state.messageLimit) {
        state.messages.shift();
      }
    },
    recent(state: LogState, payload: string) {
      state.recentMessage = payload;
    }
  };
  actions = {
    /*
    action(context: ActionContext<LogState, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
  };
  getters = {
    /*
    getter(state: LogState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
}

export default new Log()
