
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields, default as store } from "@/store";
import { animalQueue } from "@/helpers/downloaded-animal-queue";
import { clearAnimalListItems } from "@/helpers/animal-list";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields({
      // hslAddress: "settings.hsl.address",
      timeDownloaded: "sync.timeDownloaded",
      timeDownloadStarted: "sync.timeDownloadStarted",
    }),
  },
})
export default class Sync extends Vue {
  mounted() {
    this.$store.commit("navFuncs", { save: undefined, back: () => this.$router.go(-1) });
    this.startUpdateMemInterval();
    this.startAnimalQueueLengthInterval();
  }

  numAnimalsInDb: number = -1;
  numAnimalsInQueue: number = -1;

  get timeAgo() {
    const days = this.moment(Date.now()).diff(this.moment(this.$store.state.sync.timeDownloaded), "days");
    if (days === 0) {
      const hours = this.moment(Date.now()).diff(this.moment(this.$store.state.sync.timeDownloaded), "hours");
      if (hours === 0) return "less than an hour ago";
      if (hours === 1) return "1 hour ago";
      return `${hours} hours ago`;
    }
    if (days === 1) return "1 day ago";
    else return `${days} days ago`;
  }

  destroyed() {
    console.log("destroyed");
    this.stopUpdateMemInterval();
    this.stopAnimalQueueLengthInterval();
  }

  animalQueueInterval?: number;
  startAnimalQueueLengthInterval() {
    this.animalQueueInterval = setInterval(async () => {
      try {
        this.numAnimalsInDb = await this.$store.dispatch("dataManager/getNumItems", "Animal");
        this.numAnimalsInQueue = animalQueue.queue.length;
      } catch (err) {
        //silent error is fine here
      }
    }, 1000);
  }

  stopAnimalQueueLengthInterval() {
    if (this.animalQueueInterval !== undefined) clearInterval(this.animalQueueInterval);
  }

  get moment() {
    return this.$store.state.moment;
  }

  //animalQueue = animalQueue;

  mem: { jsHeapSizeLimit: number; totalJSHeapSize: number; usedJSHeapSize: number } = {
    jsHeapSizeLimit: -1,
    totalJSHeapSize: -1,
    usedJSHeapSize: -1,
  };

  updateMemInterval?: number;
  updateCount: number = 0;

  startUpdateMemInterval() {
    this.updateMemInterval = setInterval(() => {
      this.mem.jsHeapSizeLimit = (window.performance as any).memory.jsHeapSizeLimit;
      this.mem.totalJSHeapSize = (window.performance as any).memory.totalJSHeapSize;
      this.mem.usedJSHeapSize = (window.performance as any).memory.usedJSHeapSize;
      this.updateCount++;
    }, 2000);
  }

  stopUpdateMemInterval() {
    if (this.updateMemInterval !== undefined) clearInterval(this.updateMemInterval);
  }

  async sync() {
    this.$store.dispatch("sync/sync");
  }

  busyClearing: boolean = false;

  async clear() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to Clear Downloaded Data?",
      yesAction: async () => {
        try {
          this.busyClearing = true;
          this.$store.commit("popup/hide");
          await this.$store.dispatch("dataManager/deleteServerData");
          clearAnimalListItems();
          this.$store.commit("sync/clear");
        } catch (err) {
          this.busyClearing = false;
          throw err;
        } finally {
          this.busyClearing = false;
        }
      },
    });
  }

  get syncButtonState(): ButtonState {
    if (this.$store.state.sync.state === "idle") return "ready";
    if (["busy", "error", "success"].includes(this.$store.state.sync.state)) return this.$store.state.sync.state;
    return "ready";
  }

  get clearButtonState(): ButtonState {
    if (this.busyClearing) return "busy";
    if (["idle", "success"].includes(this.$store.state.sync.state)) return "ready";
    else return "disabled";
  }
}
