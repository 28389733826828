import store from "@/store";
import * as Models from "@gigalot/data-models";

export async function gatesSummary(batchSetup?: Models.BatchSetup) {
  if (!batchSetup?.processingResult?.guid) return [];
  //get animals from indexed db, get quickly from current batch if possible otherwise indexed db
  //const processedAnimals: Models.ProcessedAnimal[] = batchSetup.processingResult.processedAnimals;
  //const processedAnimals: Models.ProcessedAnimal[] = await store.getters["processing/processedAnimals"](batchSetup.processingResult.guid);
  //const processedAnimals: Models.ProcessedAnimal[] = await store.dispatch("processing/getProcessedAnimals", { processingResult: batchSetup.processingResult.guid });
  let sortingConfig: Models.GateConfigComponent[] | undefined = batchSetup.processingResult.sortingConfig;
  if (!sortingConfig) {
    throw Error("sorting gate configuration not found!");
  }
  const ret = [];
  if (batchSetup.draftMode) {
    if (batchSetup.draftingResult) {
      const draftedAnimals: Models.DraftedAnimal[] = batchSetup.draftingResult.draftedAnimals;
      let sortingConfig: Models.GateConfigComponent[] | undefined = batchSetup.processingResult.sortingConfig;
      if (!sortingConfig) {
        throw Error("sorting gate configuration not found!");
      }
      for (const sortingGateConfig of sortingConfig) {
        const animalsThroughGate = draftedAnimals.filter(pa => pa.gate.toString() === sortingGateConfig.gate.id);
        const quantity = animalsThroughGate.length;
        let averageMass = 0;
        if (animalsThroughGate.length) {
          averageMass = animalsThroughGate.map(pa => pa.mass).reduce((p, c) => (p ?? 0) + (c ?? 0), 0) as number;
          averageMass /= animalsThroughGate.length;
        }
        let range = "";
        if (sortingGateConfig.condition.length && sortingGateConfig.condition[0].min !== undefined && sortingGateConfig.condition[0].max !== undefined) {
          const condition = sortingGateConfig.condition[0];
          range = `${condition.min} - ${condition.max}`;
        }
        let gender = "";
        switch (sortingGateConfig.gender) {
          case "male":
            gender = "Male";
            break;
          case "female":
            gender = "Female";
            break;
          case "any":
            gender = "Mixed";
            break;
        }

        ret.push({
          description: sortingGateConfig.gate.description,
          averageMass: `${averageMass.toFixed(1)} kg`,
          range: range,
          quantity: quantity,
          gender: gender
        });
      }
    }
  }
  else {

    //const processedAnimals: Models.ProcessedAnimal[] = batchSetup.processingResult.processedAnimals;
    const processedAnimals: Models.ProcessedAnimal[] = await store.dispatch("dataManager/getProcessedAnimals", {
      processingResultGuid: batchSetup.processingResult.guid
    });
    let sortingConfig: Models.GateConfigComponent[] | undefined = batchSetup.processingResult.sortingConfig;
    if (!sortingConfig) {
      throw Error("sorting gate configuration not found!");
    }

    for (const sortingGateConfig of sortingConfig) {
      const animalsThroughGate = processedAnimals.filter(pa => pa.sorting?.gate?.id === sortingGateConfig.gate.id);
      const quantity = animalsThroughGate.length;
      let averageMass = 0;
      if (animalsThroughGate.length) {
        averageMass = animalsThroughGate.map(pa => pa.mass).reduce((p, c) => (p ?? 0) + (c ?? 0), 0) as number;
        averageMass /= animalsThroughGate.length;
      }
      let range = "";
      if (sortingGateConfig.condition.length && sortingGateConfig.condition[0].min !== undefined && sortingGateConfig.condition[0].max !== undefined) {
        const condition = sortingGateConfig.condition[0];
        range = `${condition.min} - ${condition.max}`;
      }
      let gender = "";
      switch (sortingGateConfig.gender) {
        case "male":
          gender = "Male";
          break;
        case "female":
          gender = "Female";
          break;
        case "any":
          gender = "Mixed";
          break;
      }

      ret.push({
        description: sortingGateConfig.gate.description,
        averageMass: `${averageMass.toFixed(1)} kg`,
        range: range,
        quantity: quantity,
        gender: gender
      });
    }

  }

  return ret;
}
