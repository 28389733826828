// Example sgtin
// urn:epc:tag:sgtin-96:0.6009881028.010.120270104159

//make a copy each time it's used to be safe (see comment in test())
export const sgtin96Regex = () => /^urn:epc:tag:sgtin-96:0\.[0-9]{10}\.0[0-9]{2}\.[0-9]{12}$/gm;

const test = (sgtin: string) => {
  //Something strange, if sgtin96Regex is not local and shared then it seems to crash - maybe some creepy concurrency issue O_o
  const sgtin96Regex = /^urn:epc:tag:sgtin-96:0\.[0-9]{10}\.0[0-9]{2}\.[0-9]{12}$/gm;
  if (!sgtin96Regex.test(sgtin)) throw Error("invalid sgtin: " + sgtin);
};

export function gcp(sgtin: string) {
  test(sgtin);
  return sgtin.slice(23, 33);
}

export function cycle(sgtin: string) {
  test(sgtin);
  return sgtin.slice(38, 40);
}

export function visual(sgtin: string) {
  test(sgtin);
  return (sgtin[36] + sgtin.slice(-5)).replace(/^0+/, "");
}

export function days(sgtin: string) {
  //console.log(sgtin);
  test(sgtin);
  return parseInt(sgtin.slice(40, 45));
}

export function visualGcpMatch(sgtin1: string, sgtin2: string) {
  /*
    sgtin[35] is the old species code, now being used to split up companies among a GCP
    We need to include checking for sgtin[35] because now we might have multiple animals under 1 GCP 
    but with a different sgtin[35] value for a different company
  */
  return gcp(sgtin1) === gcp(sgtin2) && sgtin1[35] === sgtin2[35] && visual(sgtin1) === visual(sgtin2);
}
