import { default as Vuex, Module, ActionContext } from "vuex";
import * as Models from "@gigalot/data-models";

export type SavedConfig = {
  name: string,
  config: Models.GateConfigComponent[]
}

class SortingGatesState {
  maxSortingGates: number = 10;
  //gates data used to come from processing-app-config-data but is now determined by maxSortingGates
  gates: Models.SortingGate[] = [];
  // currentGates will come from the HSL most recently connected to (from processing-app-config-data)
  currentGates: Models.SortingGate[] = [];

  //TODO: saved sorting configs, max 100
  savedConfigs: SavedConfig[] = [];
}

class SortingGates implements Module<SortingGatesState, any> {
  namespaced = true;
  state: SortingGatesState = new SortingGatesState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    saveGateConfig(state: SortingGatesState, config: SavedConfig) {
      const index = state.savedConfigs.findIndex(sc => sc.name === config.name);
      if (index < 0) {
        state.savedConfigs.push(config);
      } else {
        state.savedConfigs[index] = config;
      }
    },
    deleteGateConfig(state: SortingGatesState, configName: string) {
      const index = state.savedConfigs.findIndex(sc => sc.name === configName);
      if (index < 0) throw Error(`Sorting gate config ${configName} not found, can not delete.`);
      state.savedConfigs.splice(index, 1);
    },
    // clearGateConfigs(state: SortingGatesState) {
    //   state.savedConfigs = [];
    // },
    maxSortingGates(state: SortingGatesState, maxSortingGates: number) {
      state.maxSortingGates = maxSortingGates;
      state.gates = [];
      for (let i = 0; i < state.maxSortingGates; ++i) {
        state.gates.push({
          id: `${i + 1}`,
          description: `Gate ${i + 1}`,
          gateKraalId: `Sorting Pen ${i + 1}`
        })
      }
    }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async openGate(context: ActionContext<SortingGatesState, any>, gateNumber: Number) {
      let url = context.rootGetters["settings/openGateUrl"]();
      let response = await fetch(url + gateNumber, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (response.ok) {
        let json = await response.json();
      }
    },
    async closeGate(context: ActionContext<SortingGatesState, any>) {
      await context.dispatch("openGate", 0);

    },
    loadGateConfig(context: ActionContext<SortingGatesState, any>, configName: string) {
      console.log("loadConfigGate")
      console.log(configName)
      return context.state.savedConfigs.find(sc => sc.name === configName)?.config;
    },
  };
  getters = {
    /*
    getter(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
}

export default new SortingGates()
