var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('dimssa-button',{staticClass:"button",staticStyle:{"height":"32px"},attrs:{"buttonState":_vm.loadButtonState},on:{"onclick":function($event){return _vm.load()}}},[_vm._v("Load Gates")])],1),_c('v-col',[_c('dimssa-button',{staticClass:"button",staticStyle:{"height":"32px"},attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.newConfig()}}},[_vm._v("Clear Gates")])],1),_c('v-col',[_c('dimssa-button',{staticClass:"button",staticStyle:{"height":"32px"},attrs:{"buttonState":_vm.saveButtonState},on:{"onclick":function($event){return _vm.save()}}},[_vm._v("Save Gates")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Gate Setup Name"},model:{value:(_vm.configName),callback:function ($$v) {_vm.configName=$$v},expression:"configName"}})],1)],1),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode === 'load'),expression:"mode === 'load'"}],staticClass:"grey-border",attrs:{"dense":""}},[_c('v-col',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_vm._v(" Select Gate Setup ")])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.$store.state.sortingGates.savedConfigs,"headers":[
                  { value: 'name', text: 'Name' },
                  { value: 'clear', text: '' },
                ]},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('div',{on:{"click":function($event){return _vm.loadConfigSelect(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:`item.clear`,fn:function({ item }){return [_c('v-icon',{on:{"click":function($event){return _vm.deleteConfig(item.name)}}},[_vm._v("mdi-delete ")])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[(_vm.processingResult.sortingConfig)?_c('v-data-table',{attrs:{"id":`gate-setup-${_vm.gateSetupTableUpdateKey}`,"items":_vm.processingResult.sortingConfig,"headers":_vm.headers,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:`item.gate`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.gate.description)+" ")]}},{key:`item.gender`,fn:function({ item }){return [_c('v-select',{attrs:{"id":`batch-setup-gate-${item.gate.id}-gender`,"items":[
                    { text: 'Mixed', value: 'any' },
                    { text: 'Male', value: 'male' },
                    { text: 'Female', value: 'female' },
                  ]},on:{"change":function($event){_vm.genderChange(item, $event);
                    _vm.$emit('change');}},model:{value:(item.gender),callback:function ($$v) {_vm.$set(item, "gender", $$v)},expression:"item.gender"}})]}},{key:`item.min`,fn:function({ item }){return [_c('v-text-field',{staticClass:"no-spin",attrs:{"id":`batch-setup-gate-${item.gate.id}-min-mass`,"type":"number"},on:{"input":function($event){_vm.minMassChange(item, $event);
                    _vm.$emit('change');}},model:{value:(item.condition[0].min),callback:function ($$v) {_vm.$set(item.condition[0], "min", _vm._n($$v))},expression:"item.condition[0].min"}})]}},{key:`item.max`,fn:function({ item }){return [_c('v-text-field',{staticClass:"no-spin",attrs:{"id":`batch-setup-gate-${item.gate.id}-max-mass`,"type":"number"},on:{"input":function($event){_vm.maxMassChange(item, $event);
                    _vm.$emit('change');}},model:{value:(item.condition[0].max),callback:function ($$v) {_vm.$set(item.condition[0], "max", _vm._n($$v))},expression:"item.condition[0].max"}})]}},{key:`item.kraal`,fn:function({ item }){return [_c('v-autocomplete',{attrs:{"id":`batch-setup-gate-${item.gate.id}-kraal`,"items":_vm.kraals,"item-value":"kraalId","item-text":"kraalId","clearable":"","clear-icon":"delete","return-object":""},on:{"change":function($event){_vm.kraalChange(item, $event);
                    _vm.$emit('change');}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.kraalId)+" ("+_vm._s(data.item.kraalType || "N/A")+") ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.kraalId)+" ("+_vm._s(data.item.kraalType || "N/A")+") ")]}}],null,true),model:{value:(item.destinationKraalId),callback:function ($$v) {_vm.$set(item, "destinationKraalId", $$v)},expression:"item.destinationKraalId"}})]}},{key:`item.vaccination`,fn:function({ item }){return [_c('v-autocomplete',{attrs:{"id":`batch-setup-gate-${item.gate.id}-vaccination`,"items":_vm.vaccinations,"item-text":"description","return-object":"","clearable":"","clear-icon":"delete"},on:{"change":function($event){_vm.vaccinationChange(item, $event);
                    _vm.$emit('change');}},model:{value:(item.vaccinations[0].vaccination),callback:function ($$v) {_vm.$set(item.vaccinations[0], "vaccination", $$v)},expression:"item.vaccinations[0].vaccination"}})]}},{key:`item.sickVaccination`,fn:function({ item }){return [_c('v-autocomplete',{attrs:{"id":`batch-setup-gate-${item.gate.id}-sick-vaccination`,"items":_vm.vaccinations,"item-text":"description","return-object":"","clearable":"","clear-icon":"delete"},on:{"change":function($event){_vm.sickVaccinationChange(item, $event);
                    _vm.$emit('change');}},model:{value:(item.vaccinations[1].vaccination),callback:function ($$v) {_vm.$set(item.vaccinations[1], "vaccination", $$v)},expression:"item.vaccinations[1].vaccination"}})]}},{key:`item.clear`,fn:function({ item }){return [_c('v-icon',{on:{"click":function($event){_vm.clearGateConfigComponent(item);
                    _vm.$emit('change');}}},[_vm._v("mdi-delete ")])]}}],null,true)}):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }