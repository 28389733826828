
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import AnimalEventHistory from "@/components/AnimalEventHistory.vue";
import * as Models from "@gigalot/data-models";
import { UploadableBatchSetup } from "@/models/uploadable";
import { gatesSummary } from "@/helpers/gates-summary";
import { tagId } from "@/helpers/visual-tag";
import { getAnimalListItems } from "@/helpers/animal-list";

@Component({
  components: {
    DimssaButton,
    AnimalEventHistory,
  },
})
export default class History extends Vue {
  expandedBatchSetups: any[] = [];
  processingFunction: string = "new";
  batchSetups: (UploadableBatchSetup & { processedAnimalsLength: number })[] = [];
  animals: any[] = [];
  selectedAnimal: any = "";
  showSelectedAnimal: boolean = false;
  searchBatchSetup: string = "";

  fullSelectedAnimal: Models.Animal | "" = "";

  tagId = tagId;

  @Watch("selectedAnimal")
  async onSelectedAnimalChanged(selectedAnimal: any) {
    console.log("onSelectedAnimalChanged");
    if (!selectedAnimal?.sgtin) {
      this.fullSelectedAnimal = "";
    }
    this.fullSelectedAnimal = await this.$store.dispatch("dataManager/getAnimal", selectedAnimal.sgtin);
    this.showSelectedAnimal = this.fullSelectedAnimal ? true : false;
  }

  @Watch("expandedBatchSetups")
  expandedBatchSetupsChange(newVal: any, oldVal: any) {
    // console.log("watch expandedBatchSetups");
    // console.dir(newVal);
    // console.dir(oldVal);
    this.calcProcessedAnimalsForTable();
    this.calcGatesSummary();
    this.calcBatchSummary();
  }

  //This way of showing the gates summary will break if more than more batch wants to be viewed later (no big deal for now just making note)
  gatesSummary: any[] = [];
  async calcGatesSummary() {
    this.gatesSummary = await gatesSummary(this.expandedBatchSetups.length ? this.expandedBatchSetups[0] : undefined);
  }

  batchSummary: any = this.clearBatchSummary();
  clearBatchSummary() {
    return {
      all: {
        quantity: "",
        totalMass: "",
        avgMass: "",
        avgAdg: "",
      },
      female: {
        percentage: "",
        quantity: "",
        totalMass: "",
        avgMass: "",
        avgAdg: "",
      },
      male: {
        percentage: "",
        quantity: "",
        totalMass: "",
        avgMass: "",
        avgAdg: "",
      },
    };
  }
  async calcBatchSummary() {
    let batchSetup: UploadableBatchSetup = this.expandedBatchSetups.length ? this.expandedBatchSetups[0] : undefined;
    this.batchSummary = this.clearBatchSummary();

    if (!batchSetup?.processingResult?.guid) return;

    const processedAnimals: Models.ProcessedAnimal[] = await this.$store.dispatch("dataManager/getProcessedAnimals", {
      processingResultGuid: batchSetup.processingResult.guid,
    });

    const males = processedAnimals.filter((pa) => pa.gender.toLowerCase() === "male");
    const females = processedAnimals.filter((pa) => pa.gender.toLowerCase() === "female");

    const f = (processedAnimals: Models.ProcessedAnimal[]) => {
      const totalMass = processedAnimals.map((pa) => pa.mass ?? 0).reduce((p, c) => p + c, 0);
      let avgMass;
      if (processedAnimals.length) avgMass = totalMass / processedAnimals.length;
      else avgMass = 0;

      return {
        quantity: processedAnimals.length.toString(),
        totalMass: parseFloat(totalMass.toFixed(1)),
        avgMass: parseFloat(avgMass.toFixed(1)),
        avgAdg: "TODO",
      };
    };

    Object.assign(this.batchSummary.all, f(processedAnimals));
    Object.assign(this.batchSummary.male, f(males));
    Object.assign(this.batchSummary.female, f(females));

    if (processedAnimals.length === 0) {
      this.batchSummary.male.percentage = "0";
      this.batchSummary.female.percentage = "0";
    } else {
      this.batchSummary.male.percentage = `${parseFloat(((males.length / processedAnimals.length) * 100).toFixed(1))}`;
      this.batchSummary.female.percentage = `${parseFloat(((females.length / processedAnimals.length) * 100).toFixed(1))}`;
    }
    //this.batchSummary.all = f(processedAnimals);

    console.log("calcBatchSummary");
    console.log(JSON.stringify(this.batchSummary));
  }

  processedAnimalsForTable: (Models.ProcessedAnimal & {
    isSick: "" | "Y";
    vaccinationName: string;
  })[] = [];

  async calcProcessedAnimalsForTable() {
    let batchSetup: UploadableBatchSetup = this.expandedBatchSetups.length ? this.expandedBatchSetups[0] : undefined;
    if (!batchSetup?.processingResult?.guid) {
      this.processedAnimalsForTable = [];
      return;
    }
    const processedAnimals: Models.ProcessedAnimal[] = await this.$store.dispatch("dataManager/getProcessedAnimals", {
      processingResultGuid: batchSetup.processingResult.guid,
    });
    this.processedAnimalsForTable = processedAnimals.map((pa) => {
      let isSick: "Y" | "" = pa.vaccinations.find((vd) => vd.description === "sick") ? "Y" : "";
      let vaccinationName: string = "";
      if (pa.vaccinations.length && pa.vaccinations[0].vaccination && pa.vaccinations[0].vaccination.vaccination.description)
        vaccinationName = pa.vaccinations[0].vaccination.vaccination.description;
      return Object.assign(pa, { isSick: isSick, vaccinationName: vaccinationName });
    });
  }

  batchSetupHeaders = [
    { width: "25%", text: "Date", value: "processingResult.time" },
    { width: "25%", text: "Ref", value: "batchDetails.reference" },
    { width: "25%", text: "Owner", value: "batchDetails.customFeeder" },
    { width: "25%", text: "Qty", value: "processedAnimalsLength" },
    { width: "25%", text: "Uploaded", value: "uploaded" },
  ];

  async processingFunctionChange(val: any) {
    await this.updateBatchSetups();
  }

  async updateBatchSetups() {
    this.batchSetups = (await this.$store.dispatch("dataManager/getBatchSetups", this.processingFunction)).filter(
      (batchSetup: UploadableBatchSetup) => batchSetup.finished
    );

    for (const batchSetup of this.batchSetups) {
      if (!batchSetup.processingResult?.guid) continue;

      const processedAnimals: Models.ProcessedAnimal[] = await this.$store.dispatch("dataManager/getProcessedAnimals", {
        processingResultGuid: batchSetup.processingResult.guid,
      });

      batchSetup.processedAnimalsLength = processedAnimals.length;
    }
  }

  state: "loading" | "ready" = "loading";

  async created() {
    this.state = "loading";
    await this.updateBatchSetups();
    // this.animals = await this.$store.dispatch("dataManager/mapOverAnimals", (a: Models.Animal & { visualSgtin?: string }) => {
    //   return {
    //     breed: a.breed,
    //     gender: a.gender,
    //     sgtin: a.sgtin,
    //     customFeeder: a.customFeeder,
    //     visualSgtin: a.visualSgtin,
    //   };
    // });
    this.animals = await getAnimalListItems();
    this.state = "ready";
  }

  mounted() {
    this.$store.commit("navFuncs", { save: undefined, back: () => this.$router.push({ name: "home" }) });
  }

  destroyed() {
    this.$store.commit("navFuncs", { save: undefined, back: undefined });
  }

  get moment() {
    return this.$store.state.moment;
  }
}
