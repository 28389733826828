import * as Models from "@gigalot/data-models";
import store from "@/store";
import * as sgtinH from "@/helpers/sgtin";
import { removeAnimal } from "./animal-list";

export async function checkTagAgainstAnimalsInBatch(sgtin: string) {
  let processedAnimals: Models.ProcessedAnimal[] = await store.getters["processing/currentProcessedAnimals"]();
  //let processedAnimals: Models.ProcessedAnimal[] = await store.dispatch("processing/getProcessedAnimals", { processingResultGuid: undefined });
  let visualSgtin = sgtinH.visual(sgtin);
  let a = processedAnimals.find(pa => pa.sgtin && sgtinH.visualGcpMatch(sgtin, pa.sgtin));
  if (a) throw Error(`Tag is already in batch: (${visualSgtin})\n${a.sgtin}`);
}

export async function checkTagAgainstAnimalsInDatabase(sgtin: string) {
  if (!sgtin) throw Error("Blank sgtin given when looking up animal in database.");
  let visualSgtin = sgtinH.visual(sgtin);
  let animalsWithSameVisualSgtin = await store.dispatch("dataManager/getAnimalsByVisualSgtin", visualSgtin);
  let previousAnimal = animalsWithSameVisualSgtin.find((animal: any) => sgtinH.visualGcpMatch(sgtin, animal.sgtin));
  if (previousAnimal) {
    //check if tag was programmed within 30 days
    const daysAgo = tagAgeDays(previousAnimal.sgtin)
    if (daysAgo <= 30) {
      throw Error(`Tag was programmed recently (${daysAgo} days ago). Old tag ${previousAnimal.sgtin}, current tag ${sgtin}`)
    }
    //throw Error(`A 'live' animal with this tag is still in the database. Lost tag, report to supervisor! (${visualSgtin}) \n${sgtin}`);
    await store.dispatch("dataManager/deleteAnimal", previousAnimal.sgtin, { root: true });
    removeAnimal(previousAnimal.sgtin); //remove animal from animal list in RAM
  }
}

export function checkTagDate(sgtin: string) {
  //let start = store.state.moment(new Date(2012, 11, 31));
  let start = store.state.moment(new Date(2013, 0, 1));
  let now = store.state.moment(new Date());
  let days = now.diff(start, "days");
  if (days !== sgtinH.days(sgtin)) throw Error(`Date mismatch, days field set to ${sgtinH.days(sgtin)} but expected ${days}. (${now.format("YYYY-MM-DD")})`);
}

function tagAgeDays(sgtin: string) {
  const start = store.state.moment(new Date(2013, 0, 1));
  const now = store.state.moment(new Date());
  const numDaysUntilToday = now.diff(start, "days");
  const numDaysUntilTag = sgtinH.days(sgtin);
  const daysAgo = numDaysUntilToday - numDaysUntilTag;
  return daysAgo;
}