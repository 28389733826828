import { default as Vuex, Module, ActionContext } from "vuex";
import { openDB, DBSchema, IDBPDatabase, StoreNames } from "idb";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import { UploadableBatchSetup, UploadableHospitalResult, UploadableProcessedAnimal } from "@/models/uploadable";
import * as sgtinH from "@/helpers/sgtin";
import { animalQueue } from "@/helpers/downloaded-animal-queue";
import { addAnimalsAsTheyDownload } from "@/helpers/animal-list";

interface MyDB extends DBSchema {
  BatchSetup: {
    key: string;
    value: UploadableBatchSetup;
    indexes: { processingFunction: Models.ProcessingFunction; };
  };
  HospitalResult: {
    key: string;
    value: UploadableHospitalResult;
  };
  Animal: {
    key: string;
    value: any;
    indexes: { visualSgtin: string; };
  };
  Treatment: {
    key: string;
    value: any;
  };
  Vaccination: {
    key: string;
    value: any;
  };
  Kraal: {
    key: string;
    value: any;
  };
  CustomFeeder: {
    key: string;
    value: any;
  };
  Ailment: {
    key: string;
    value: any;
  };
  BatchDetails: /*ProcessingBatchDetails*/ {
    key: string;
    value: any;
  };
  ProcessedAnimal: {
    key: string;
    value: UploadableProcessedAnimal;
    indexes: { processingResultGuid: string; };
  };
  UnUploadedProcessedAnimalGuids: {
    key: string;
    value: { guid: string; };
  };
}

export type ObjectStoreNames = StoreNames<MyDB>;

const DB_VERSION = 3;

class DataManagerState {
  db?: Promise<IDBPDatabase<MyDB>>;
}

let initialized: boolean = false;
let callbacks: (() => void)[] = [];

function addCallback(callback: () => void) {
  if (initialized) callback();
  else callbacks.push(callback);
}

function fireCallbacks() {
  initialized = true;
  let callback: (() => void) | undefined;
  while ((callback = callbacks.shift())) callback();
}

class DataManager implements Module<DataManagerState, any> {
  namespaced = true;
  state: DataManagerState = new DataManagerState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    setDatabase(state: DataManagerState, db: Promise<IDBPDatabase<MyDB>>) {
      state.db = db;
    }
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async onAppCreated(context: ActionContext<DataManagerState, any>) {
      console.log("dataManager onAppCreated");

      const db = await openDB<MyDB>("processing-app-DB", DB_VERSION, {
        upgrade(db, oldVersion, newVersion, transaction) {
          if (oldVersion < 1) {
            //First time database has been initialized, upgrade to version 1
            const batchSetupsStore = db.createObjectStore("BatchSetup", { keyPath: "guid" });
            batchSetupsStore.createIndex("processingFunction", "processingFunction");
            db.createObjectStore("BatchDetails", { keyPath: "guid" });
            const animalStore = db.createObjectStore("Animal", { keyPath: "sgtin" });
            animalStore.createIndex("visualSgtin", "visualSgtin");
            db.createObjectStore("HospitalResult", { keyPath: "guid" });
          }

          if (oldVersion < 2) {
            //upgrade from version 1 to version 2:
            db.createObjectStore("Treatment", { keyPath: "guid" });
            db.createObjectStore("Vaccination", { keyPath: "guid" });
            db.createObjectStore("Kraal", { keyPath: "guid" });
            db.createObjectStore("CustomFeeder", { keyPath: "guid" });
            db.createObjectStore("Ailment", { keyPath: "guid" });
          }

          if (oldVersion < 3) {
            //upgrade from version 2 to version 3:
            const processedAnimalStore = db.createObjectStore("ProcessedAnimal", { keyPath: "guid" });
            processedAnimalStore.createIndex("processingResultGuid", "processingResultGuid");

            db.createObjectStore("UnUploadedProcessedAnimalGuids", { keyPath: "guid" });
          }
        }
      });

      context.commit("setDatabase", db);
      fireCallbacks();
    },
    async getBatchSetup(context: ActionContext<DataManagerState, any>, guid: string) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      return db.transaction("BatchSetup", "readonly").store.get(guid);
    },
    async getBatchSetups(context: ActionContext<DataManagerState, any>, processingFunction?: Models.ProcessingFunction) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      if (!processingFunction) return await db.transaction("BatchSetup", "readonly").store.getAll();
      else
        return await db
          .transaction("BatchSetup", "readonly")
          .store.index("processingFunction")
          .getAll(processingFunction);
    },
    async getUnfinishedBatchSetups(context: ActionContext<DataManagerState, any>, processingFunction?: Models.ProcessingFunction) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      let cursor;
      if (!processingFunction) cursor = await db.transaction("BatchSetup", "readonly").store.openCursor();
      else
        cursor = await db
          .transaction("BatchSetup", "readonly")
          .store.index("processingFunction")
          .openCursor(processingFunction);
      const ret = [];
      while (cursor) {
        if (!cursor.value.finished) ret.push(cursor.value);
        cursor = await cursor.continue();
      }
      return ret;
    },
    async getUnUploadedBatchSetups(context: ActionContext<DataManagerState, any>, processingFunction?: Models.ProcessingFunction) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      let cursor;
      if (!processingFunction) cursor = await db.transaction("BatchSetup", "readonly").store.openCursor();
      else
        cursor = await db
          .transaction("BatchSetup", "readonly")
          .store.index("processingFunction")
          .openCursor(processingFunction);
      const ret = [];
      while (cursor) {
        if (!cursor.value.uploaded) ret.push(cursor.value);
        cursor = await cursor.continue();
      }
      return ret;
    },
    async getNumItems(context: ActionContext<DataManagerState, any>, objectstore: ObjectStoreNames) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      return await db?.transaction(objectstore, "readonly").store.count() ?? 0;
    },
    async getNumUnUploadedItems(context: ActionContext<DataManagerState, any>) {

      let ret = 0;

      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      let cursor = await db.transaction("BatchSetup", "readonly").store.openCursor();
      while (cursor) {
        if (!cursor.value.uploaded && cursor.value.finished) ret++;
        cursor = await cursor.continue();
      }

      ret += await db.transaction("UnUploadedProcessedAnimalGuids", "readonly").store.count();

      let cursor2 = await db.transaction("HospitalResult", "readonly").store.openCursor();
      while (cursor2) {
        if (!cursor2.value.uploaded) ret++;
        cursor2 = await cursor2.continue();
      }

      return ret;
    },
    /*
      Gets the number of processed animals from finished batches that have been
      processed since the last data sync download.

      Used to determine if some batch details should not be shown in selection.

      Returns a map that is keyed with a batchGuid and returns the number of processed animals,
      potentially from different ProcessingResults (but for same batch details).
    */
    async getNumProcessedAnimalsSinceDownload(
      context: ActionContext<DataManagerState, any>,
      processingFunction: Models.ProcessingFunction
    ): Promise<{ [batchGuid: string]: number; }> {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      let cursor = await db
        .transaction("BatchSetup", "readonly")
        .store.index("processingFunction")
        .openCursor(processingFunction);
      let ret: { [batchGuid: string]: number; } = {};
      let guids: { batchGuid: string, processingResultGuid: string; }[] = [];
      while (cursor) {
        const batchSetup = cursor.value;
        //if (!batchSetup.uploaded && batchSetup.finished && batchSetup.processingResult) {
        if (batchSetup.finished && batchSetup.processingResult) {
          if (!ret[batchSetup.processingResult.batchGuid]) ret[batchSetup.processingResult.batchGuid] = 0;

          guids.push({ batchGuid: batchSetup.processingResult.batchGuid, processingResultGuid: batchSetup.processingResult.guid });

          //retrieve processedAnimals after cursor iteration
          //const processedAnimals = await context.dispatch("getProcessedAnimals", { processingResultGuid: batchSetup.processingResult.guid });

          //ret[batchSetup.processingResult.batchGuid] += processedAnimals.length;
        }
        cursor = await cursor.continue();
      }

      for (const { batchGuid, processingResultGuid } of guids) {
        let processedAnimals: Models.ProcessedAnimal[] = await context.dispatch("getProcessedAnimals", { processingResultGuid: processingResultGuid });
        // Filter animals that have been processed after download time
        let timeDownloaded: number | "" = context.rootState.sync.timeDownloaded;
        if (timeDownloaded !== "") {
          processedAnimals = processedAnimals.filter(pa => pa.time > timeDownloaded)
        }
        ret[batchGuid] += processedAnimals.length;
      }

      return ret;
    },
    async getUnUploadedProcessedAnimalGuids(context: ActionContext<DataManagerState, any>) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      return (await db.transaction("UnUploadedProcessedAnimalGuids").store.getAll()).map(i => i.guid);
    },
    async deleteBatchSetup(context: ActionContext<DataManagerState, any>, batchSetupGuid: string) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      return db.transaction("BatchSetup", "readwrite").store.delete(batchSetupGuid);
    },
    //if o.guid is not given then all data from object store is returned
    async getData(context: ActionContext<DataManagerState, any>, o: { guid: string; objectStore: ObjectStoreNames; }) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      if (!o.guid) return await db.transaction(o.objectStore, "readonly").store.getAll();
      else return await db.transaction(o.objectStore, "readonly").store.get(o.guid);
    },
    async getAnimal(context: ActionContext<DataManagerState, any>, sgtin?: string) {
      if (!sgtin) throw Error("getAnimal to get all animals is no longer supported.");
      if (!/^urn:epc:tag:sgtin-96:0\.[0-9]{10}\.0[0-9]{2}\.[0-9]{12}$/gm.test(sgtin)) throw Error("getAnimal: bad sgtin: " + sgtin);
      const a = animalQueue.queue.find(a => a.sgtin === sgtin);
      if (a) return a;
      //if a not found then carry on and search in idb
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      return await db.transaction("Animal", "readonly").store.get(sgtin);
    },
    async deleteAnimal(context: ActionContext<DataManagerState, any>, sgtin: string) {
      //if (!sgtin) throw Error("getAnimal to get all animals is no longer supported.");
      //if (!/^urn:epc:tag:sgtin-96:0\.[0-9]{10}\.0[0-9]{2}\.[0-9]{12}$/gm.test(sgtin)) throw Error("getAnimal: bad sgtin: " + sgtin);

      const i = animalQueue.queue.findIndex(a => a.sgtin === sgtin);
      if (i >= 0) {
        animalQueue.queue.splice(i, 1);
      }

      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      return await db.transaction("Animal", "readwrite").store.delete(sgtin);
    },
    async getAnimalsByVisualSgtin(context: ActionContext<DataManagerState, any>, visualSgtin: string) {
      if (!visualSgtin) throw Error("visualSgtin required");
      if (!context.state.db) throw Error("Database not available.");
      //Search in queue for animals
      const animals = animalQueue.queue.filter(a => sgtinH.visual(a.sgtin) === visualSgtin);
      //return animalQueue.queue.filter(a => sgtinH.visual(a.sgtin) === visualSgtin);
      //Search in idb for animals
      let db = await context.state.db;
      animals.push(...await db
        .transaction("Animal", "readonly")
        .store.index("visualSgtin")
        .getAll(visualSgtin));

      return animals;
    },
    async mapOverAnimals(context: ActionContext<DataManagerState, any>, f: (animal: Models.Animal & { visualSgtin?: string }) => any) {
      let db = await context.state.db;
      if (!db) throw Error("Database not available.");
      let cursor = await db
        .transaction("Animal", "readonly")
        .store.openCursor();
      const ret = [];
      while (cursor) {
        if (!cursor.value.finished) ret.push(f(cursor.value));
        cursor = await cursor.continue();
      }
      return ret;
    },
    //o.data can be an array of objects to be saved
    async saveData(
      context: ActionContext<DataManagerState, any>,
      o: {
        data: any;
        objectStore: ObjectStoreNames;
        onFinishedSaving?: () => Promise<void>
      }
    ) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      if (o.objectStore === "Animal") {
        if (o.onFinishedSaving) {
          animalQueue.setQueueFinishedCallback(o.onFinishedSaving);
        }
        if (!animalQueue.hasCallback()) {
          animalQueue.setItemCallback(async (animal: Models.Animal) => {
            await db.transaction(o.objectStore, "readwrite").store.put(animal);
          })
        }
        //animalQueue will start saving animals to idb as they enqueue
        addAnimalsAsTheyDownload(o.data);
        animalQueue.enqueue(o.data);
        return;
      } else if (Array.isArray(o.data)) return Promise.all(
        o.data.map(async (d: any) => {
          try {
            const ret = await db.transaction(o.objectStore, "readwrite").store.put(d);
            return ret;
          } catch (err) {
            console.error(`Error inserting into ${o.objectStore}, item: ${d}`);
            throw err;
          }
        }
        )
      );
      else return db.transaction(o.objectStore, "readwrite").store.put(o.data);
    },
    async deleteServerData(context: ActionContext<DataManagerState, any>) {
      if (!context.state.db) throw Error("Database not available.");
      animalQueue.clear();
      let db = await context.state.db;
      const t: ObjectStoreNames[] = ["Animal", "Treatment", "Vaccination", "Kraal", "CustomFeeder", "Ailment", "BatchDetails"]
      for (const i of t) {
        await db.transaction(i, "readwrite").store.clear();
      }
    },
    //if there is no o.guid given then the all the objects in the object store named o.objectStore are deleted
    async deleteData(context: ActionContext<DataManagerState, any>, o: { guid: string; objectStore: ObjectStoreNames; }) {
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      if (!o.guid) return db.transaction(o.objectStore, "readwrite").store.clear();
      else return db.transaction(o.objectStore, "readwrite").store.delete(o.guid);
    },
    addOnInitializedCallback(
      { state, commit, rootState, dispatch }: { state: DataManagerState; commit: any; rootState: any; dispatch: any },
      callback: () => void
    ) {
      addCallback(callback);
    },
    async getProcessedAnimals(context: ActionContext<DataManagerState, any>, o: { processingResultGuid: string; }) {
      console.log("dataManager/getProcessedAnimals")
      console.log(JSON.stringify(o))
      if (!o?.processingResultGuid) throw Error("getProcessedAnimals error, no processingResultGuid given.");
      if (!context.state.db) throw Error("Database not available.");
      console.log("dataManager/getProcessedAnimals await db")
      let db = await context.state.db;
      console.log("dataManager/getProcessedAnimals got db, await query")
      return await db
        .transaction("ProcessedAnimal", "readonly")
        .store.index("processingResultGuid")
        .getAll(o.processingResultGuid);
    },
    async getProcessedAnimal(context: ActionContext<DataManagerState, any>, o: { guid: string; }) {
      if (!o?.guid) throw Error("getProcessedAnimal error, no guid given.");
      if (!context.state.db) throw Error("Database not available.");
      let db = await context.state.db;
      return await db
        .transaction("ProcessedAnimal", "readonly")
        .store.get(o.guid);
    }
  };
  getters = {
    /*
    getter(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  };
}

export default new DataManager();
