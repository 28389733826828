import * as Models from "@gigalot/data-models";
import * as sgtinHelpers from "./sgtin";
import moment from "moment";

function getColourMode(sgtin: string): "single" | "multi" {
  let gcp = sgtinHelpers.gcp(sgtin);
  let feedlotInfo = Models.feedlotColourMonths.find(fi => fi.gcp === gcp);

  if (!feedlotInfo) {
    console.warn("no feedlot info found for gcp " + gcp + ", defaulting to single-colour mode");
    return "single";
  }
  return feedlotInfo.colourMode;
}

const getColourAbbr = (sgtin: string): string => getTagColour(sgtin).abbr;

export function getTagColour(sgtin: string): { name: string; hex: string; number: number; abbr: string; } {
  let gcp = sgtinHelpers.gcp(sgtin);
  let feedlotInfo = Models.feedlotColourMonths.find(fi => fi.gcp === gcp);

  const unknownTag = { number: -1, abbr: "X", name: "unknown", hex: "#000000" };

  if (!feedlotInfo) {
    console.warn("no feedlot info found for gcp " + gcp + ", defaulting to X tag");
    return unknownTag;
  }

  let colourMode = getColourMode(sgtin);
  let colourCode: number | undefined = undefined;
  if (colourMode === "multi") {
    let colourCodeCode = sgtin[36];
    colourCode = (feedlotInfo as any)["m" + colourCodeCode];
  } else if (colourMode === "single") colourCode = feedlotInfo.m1 /*same as m2, m3, ... , m6*/;
  if (colourCode === undefined) console.warn("colour code could not be determined for sgtin " + sgtin);
  let colourInfo = Models.colourCodes.find(ci => ci.number === colourCode);
  if (colourInfo) return colourInfo;
  else {
    console.warn("no colour info found for sgtin " + sgtin);
    return unknownTag;
  }
}

export const visualNumber = (sgtin: string) =>
  //getColourMode(sgtin) === "single" ? `${sgtin[36]}${sgtin.slice(-5)}`.replace(/^0+/, "") : `${sgtin.slice(-5)}`.replace(/^0+/, ""); //remove leading 0s
  getColourMode(sgtin) === "single" ? `${sgtin[36]}${sgtin.slice(-5)}` : `${sgtin.slice(-5)}`;

export const tagId = (sgtin: string) => {
  const days = sgtinHelpers.days(sgtin);
  const part1 = moment("2013-01-01")
    .add(days, "days")
    .format("YY-MM-WW");
  const part2 = getColourAbbr(sgtin);
  //include 100,000 digit if gcp is from a single coloured tag
  const part3 = getColourMode(sgtin) === "single" ? `${sgtin[36]}${sgtin.slice(-5)}` : `${sgtin.slice(-5)}`;
  return `${part1}-${part2}-${part3}`;
};
