import * as Models from "@gigalot/data-models";
import * as sgtinH from "@/helpers/sgtin";
import { UploadableBatchSetup } from "@/models/uploadable";
import _ from "lodash";
import { default as Vuex, Module, ActionContext } from "vuex";
import * as gqlQueries from "@/helpers/graphql-queries";
import router from "@/router";
import { addAnimalsAsTheyDownload, removeAnimal } from "@/helpers/animal-list";

let initialized = false;

async function init(context: ActionContext<SyncState, any>) {
  if (initialized) return;
  context.commit("message", { message: "", append: false });
  context.commit("state", "idle");

  // if Animals downloading and still saving to db then this will reset index and amount to -1
  if (context.state.busySavingAnimalsToDb) {
    context.commit("busyDownloading", { what: /*context.state.busyDownloading.what*/ "Animal" })
    // App refreshed while saving animals to idb, reset download stats in UI.
    context.commit("downloadProgress", { typename: "Animal", progress: 0 });
    context.commit("numDownloaded", { typename: "Animal", numDownloaded: 0, incremental: false });
    await context.dispatch("dataManager/deleteData", { objectStore: "Animal" }, { root: true });
  }

  const l = [
    "BatchDetails",
    "Ailment",
    "Breed",
    "CustomFeeder",
    "Kraal",
    "Treatment",
    "Vaccination",
    "Animal"
  ]

  if (l.includes(context.state.busyDownloading.what)) {
    context.commit("downloadStatus", { typename: context.state.busyDownloading.what, downloadStatus: "idle" });
  }

  context.commit("busySavingAnimalsToDb", false);
  context.commit("clearNumMessages");

  initialized = true;
}

// locationGuid for office-server
function getLocationLocalNodeGuid(context: ActionContext<SyncState, any>) {
  let locationLocalNodeGuid = context.rootState.user.location.guid;
  if (!locationLocalNodeGuid) throw Error("guid for node / local server not found");
  return locationLocalNodeGuid;
}

async function getNumAnimals(
  context: ActionContext<SyncState, any>,
  locationLocalNodeGuid: string,
  since?: number,
  until?: number) {
  let json = await context.dispatch(
    "graphQl",
    {
      gql: `query numAnimals($guid: String!, $since: Float, $until: Float) {
        numAnimals(guid: $guid, since: $since, until: $until)
      }`,
      variables: { guid: locationLocalNodeGuid, since, until },
      destination: "office-server"
    },
    { root: true }
  );
  return json.data.numAnimals;
};

async function downloadAnimalsFromOfficeServer(context: ActionContext<SyncState, any>, locationLocalNodeGuid: string, since?: number, until?: number) {
  try {
    const numAnimalsToDownload = await getNumAnimals(context, locationLocalNodeGuid, since, until);
    const numAnimalsPerDownload = context.rootState.settings.numAnimalsPerDownload;
    console.log("numAnimals", numAnimalsToDownload);

    if (numAnimalsToDownload === 0) {
      context.commit("message", { message: `No Animal data to download`, append: true });
      context.commit("downloadProgress", { typename: "Animal", progress: 1.0 });
      context.commit("downloadStatus", { typename: "Animal", downloadStatus: "done" });
      return;
    }
    //else if (numAnimals > 0) {
    //context.commit("message", { message: `Downloading data for ${numAnimalsToDownload} animals.`, append: true });
    context.commit("downloadStatus", { typename: "Animal", downloadStatus: "busy" });

    /*
      index and numAnimalsReceived should persist so that if the download 
      resumes after failure it can pick up from where it left off.

      The values must be reset to 0 when app is refreshed. If the app
      refreshes then either the animals successfully saved to idb
      or they didn't, either case the values can be set back to 0.
    */

    // download might resume back here (app hasn't refreshed)
    let numAnimalsReceived = context.state.busyDownloading.amount > 0 ? context.state.busyDownloading.amount : 0;
    // download might resume back here (app hasn't refreshed)
    let index = context.state.busyDownloading.index > 0 ? context.state.busyDownloading.index : 0;

    context.commit("busyDownloading", { what: "Animal", index, amount: numAnimalsReceived });
    context.commit("numDownloaded", { typename: "Animal", numDownloaded: 0, incremental: false });
    //const newlyDownloadedAnimals: any[] = [];

    while (numAnimalsReceived < numAnimalsToDownload) {

      const payload = {
        gql: gqlQueries.animals,
        variables: { guid: locationLocalNodeGuid, index: index++, amount: numAnimalsPerDownload, since, until },
        destination: "office-server"
        //onprogress: onprogress
      };

      console.dir(payload);

      let json = await context.dispatch("graphQl", payload, { root: true });

      if (json.errors) {
        json.errors.map((e: any) => console.error(JSON.stringify(e)))
      }

      const a = json.data.animals.map((animal: any) => (animal.visualSgtin ? animal : { ...animal, ...{ visualSgtin: sgtinH.visual(animal.sgtin) } }));
      //newlyDownloadedAnimals.push(...a);

      console.log(`saving animals...`);
      context.commit("busySavingAnimalsToDb", true);

      await context.dispatch("dataManager/saveData", {
        data: a,
        objectStore: "Animal",
        onFinishedSaving: () => {
          context.commit("busySavingAnimalsToDb", false);
          //resolve();
        }
      }, { root: true });

      numAnimalsReceived += numAnimalsPerDownload;
      if (numAnimalsReceived > numAnimalsToDownload) numAnimalsReceived = numAnimalsToDownload;
      const p = numAnimalsReceived / numAnimalsToDownload;
      context.commit("downloadProgress", { typename: "Animal", progress: p });
      context.commit("numDownloaded", { typename: "Animal", numDownloaded: a.length, incremental: true });
      context.commit("busyDownloading", { what: "Animal", index, amount: numAnimalsReceived });
    }

    context.commit("downloadStatus", { typename: "Animal", downloadStatus: "done" });
    //return newlyDownloadedAnimals;
  } catch (err) {
    context.commit("downloadStatus", { typename: "Animal", downloadStatus: "error" });
    //context.commit("message", { message: ` ❌`, append: true });
    throw err;
  }
}

async function download(context: ActionContext<SyncState, any>) {
  context.commit("downloadProgress", { typename: "Animal", progress: 0 });
  let locationLocalNodeGuid = getLocationLocalNodeGuid(context);

  if (context.state.timeDownloadStarted === "") {
    context.commit("timeDownloadStarted", Date.now());
  } else {
    // If timeDownloadStarted is a number then the download did not finish
    // Leave timeDownloadStarted and let the download resume
  }

  const f = async (
    queryName: string,
    variables: any,
    typename: string,
    mapOverData?: (item: any) => any,
    //progress is the current amount received since last callback, total would be the total amount expected
    onprogress?: (num: number, progress: number, total: number) => void
  ) => {
    try {
      const s = typename.endsWith("s") ? "" : "s"; //don't suffix s if typename ends with s (i.e BatchDetails)
      const message = `Downloading ${typename}${s}`;
      context.commit("message", { message: message, append: true });
      context.commit("busyDownloading", { what: typename })
      context.commit("downloadStatus", { typename, downloadStatus: "busy" });

      let data: any[] = [];

      console.log(`querying ${queryName}...`);
      let query;
      if (queryName === "breeds") {
        query = `query breeds($guid: String!) {
          breeds(guid: $guid)
        }`;
      } else {
        query = (Models.gql.queries as any)[queryName];
      }

      if (queryName === "breeds") console.log(query);

      const payload = {
        gql: query,
        variables: variables,
        destination: "office-server",
        onprogress: onprogress
      };

      console.dir(payload);

      let json = await context.dispatch("graphQl", payload, { root: true });
      //console.log("graphQL: " + JSON.stringify(json));
      let q;
      if (queryName === "kraals") q = "Kraals";
      else if (queryName === "batchDetails") q = "processingBatchDetails";
      else q = queryName;

      data = json.data[q];
      //}

      if (data && mapOverData) data = data.map(mapOverData);

      if (queryName === "breeds") {
        console.log(`saving breeds...`);
        context.commit(
          "updateField",
          {
            path: "breeds",
            value: data
          },
          { root: true }
        );
      } else {
        console.log(`deleting ${queryName}...`);
        await context.dispatch("dataManager/deleteData", { objectStore: typename }, { root: true });
        console.log(`saving ${queryName}...`);
        await context.dispatch("dataManager/saveData", { data: data, objectStore: typename }, { root: true });
        context.commit("numDownloaded", { typename, numDownloaded: data?.length ?? 0 });
      }
      //context.commit("subState", { subStateName: queryName, state: "success" });
      //context.commit("message", { message: ` ✔️\n`, append: true });
      context.commit("downloadStatus", { typename, downloadStatus: "done" });
    } catch (err) {
      //context.commit("message", { message: ` ❌\n`, append: true });
      //context.commit("message", { message: ` ❌\n`, append: true });
      context.commit("downloadStatus", { typename, downloadStatus: "error" });
      throw err;
    }
  };

  let totalProgress: { [key: string]: number } = {
    "Ailment": 0,
    "BatchDetails": 0,
    "Breed": 0,
    "CustomFeeder": 0,
    "Kraal": 0,
    "Treatment": 0,
    "Vaccination": 0
  };

  const onprogress = (typename: string) => (num: number, progress: number, total: number) => {
    //console.log(`num: ${num}, progress: ${progress}, total: ${total}`)
    if (num !== 1) return; //skip progress of metadata messages
    if (total === 0) return;
    totalProgress[typename] += progress;
    let p = totalProgress[typename] / total;
    context.commit("downloadProgress", { typename: typename, progress: p });
  };

  // If nothing was busy downloading then start it off with metadata
  // Otherwise it will carry on where it was and fall through the cases to proceed
  if (!context.state.busyDownloading.what) context.commit("busyDownloading", { what: "metadata" });

  const since: number | undefined = context.state.timeDownloaded === "" ? undefined : context.state.timeDownloaded;
  const until: number | undefined = context.state.timeDownloadStarted === "" ? undefined : context.state.timeDownloadStarted;

  /*
  BatchDetails
  Ailment
  Breed
  CustomFeeder
  Kraal
  Treatment
  Vaccination
  Animal
  */

  switch (context.state.busyDownloading.what) {
    case "metadata":
    // eslint-disable-next-line no-fallthrough
    case "BatchDetails":
      context.commit("downloadStatus", { typename: "BatchDetails", downloadStatus: "idle" });
    // eslint-disable-next-line no-fallthrough
    case "Ailment":
      context.commit("downloadStatus", { typename: "Ailment", downloadStatus: "idle" });
    // eslint-disable-next-line no-fallthrough
    case "Breed":
      context.commit("downloadStatus", { typename: "Breed", downloadStatus: "idle" });
    // eslint-disable-next-line no-fallthrough
    case "CustomFeeder":
      context.commit("downloadStatus", { typename: "CustomFeeder", downloadStatus: "idle" });
    // eslint-disable-next-line no-fallthrough
    case "Kraal":
      context.commit("downloadStatus", { typename: "Kraal", downloadStatus: "idle" });
    // eslint-disable-next-line no-fallthrough
    case "Treatment":
      context.commit("downloadStatus", { typename: "Treatment", downloadStatus: "idle" });
    // eslint-disable-next-line no-fallthrough
    case "Vaccination":
      context.commit("downloadStatus", { typename: "Vaccination", downloadStatus: "idle" });
    // eslint-disable-next-line no-fallthrough
    case "Animal":
      context.commit("downloadStatus", { typename: "Animal", downloadStatus: "idle" });
    // eslint-disable-next-line no-fallthrough
  }

  //TODO: be able to resume and retry between these
  switch (context.state.busyDownloading.what) {
    case "metadata":
      context.commit("busyDownloading", { what: "metadata" });
      context.commit("message", { message: `Downloading metadata`, append: true });
      let payload: any = {
        gql: `query dataExpiryHours($guid: String!) {
          dataExpiryHours(guid: $guid)
        }`,
        variables: { guid: locationLocalNodeGuid },
        destination: "office-server",
      };
      let json = await context.dispatch("graphQl", payload, { root: true });
      console.log(`dataExpiryHours: ${JSON.stringify(json)}`);
      context.commit("dataExpiryHours", json.data.dataExpiryHours);

      payload = {
        gql: `query maxSortingGates($guid: String!) {
          maxSortingGates(guid: $guid)
        }`,
        variables: { guid: locationLocalNodeGuid },
        destination: "office-server",
      };
      json = await context.dispatch("graphQl", payload, { root: true });
      console.log(`maxSortingGates: ${JSON.stringify(json)}`);
      context.commit("sortingGates/maxSortingGates", json.data.maxSortingGates, { root: true });

    //dataExpiryHours
    // eslint-disable-next-line no-fallthrough  
    // eslint-disable-next-line no-fallthrough
    case "BatchDetails":
      await f(
        "batchDetails",
        { guid: locationLocalNodeGuid },
        "BatchDetails",
        (pbd: any) => Object.assign(pbd, { guid: pbd.batchGuid }),
        onprogress("BatchDetails")
      );
    case "Ailment":
      await f("ailments", { guid: locationLocalNodeGuid, onlyActive: true }, "Ailment", undefined, onprogress("Ailment"));
    // eslint-disable-next-line no-fallthrough
    case "Breed":
      await f("breeds", { guid: locationLocalNodeGuid }, "Breed", undefined, onprogress("Breed"));
    // eslint-disable-next-line no-fallthrough
    case "CustomFeeder":
      await f("customFeeders", { guid: locationLocalNodeGuid, onlyActive: true }, "CustomFeeder", undefined, onprogress("CustomFeeder"));
    // eslint-disable-next-line no-fallthrough
    case "Kraal":
      await f("kraals", { guid: locationLocalNodeGuid }, "Kraal", undefined, onprogress("Kraal"));
    // eslint-disable-next-line no-fallthrough
    case "Treatment":
      await f("treatments", { guid: locationLocalNodeGuid, onlyActive: true }, "Treatment", undefined, onprogress("Treatment"));
    // eslint-disable-next-line no-fallthrough
    case "Vaccination":
      await f("vaccinations", { guid: locationLocalNodeGuid, onlyActive: true }, "Vaccination", undefined, onprogress("Vaccination"));
    case "remove-animals":
      if (since !== undefined && until !== undefined) {
        context.commit("busyDownloading", { what: "remove-animals" });
        context.commit("message", { message: `Downloading animals to remove`, append: true });
        payload = {
          gql: `query animalsToRemove($guid: String!, $since: Float!, $until: Float!) {
            animalsToRemove(guid: $guid, since: $since, until: $until)
          }`,
          variables: { guid: locationLocalNodeGuid, since, until },
          destination: "office-server",
        };
        json = await context.dispatch("graphQl", payload, { root: true });
        //console.log(`animalsToRemove: ${JSON.stringify(json)}`);
        const sgtins = json.data.animalsToRemove;
        context.commit("message", { message: `Removing ${sgtins.length} animals.`, append: true });
        for (const sgtin of sgtins) {
          //await context.dispatch("dataManager/deleteData", { guid: sgtin, objectStore: "Animal" }, { root: true });
          await context.dispatch("dataManager/deleteAnimal", sgtin, { root: true });
          removeAnimal(sgtin) //remove animal from animal list in RAM
        }
      }
    // eslint-disable-next-line no-fallthrough
    case "Animal":
      ////////
      // Download all animals if fresh download. Otherwise only get animals that have changed.
      context.commit("message", { message: `Downloading Animals`, append: true });
      await downloadAnimalsFromOfficeServer(context, locationLocalNodeGuid, since, until);
    // eslint-disable-next-line no-fallthrough
  }

  // Add animals from new batches that have not yet been uploaded
  // Skip over any animals already in idb
  // Future syncs should eventually get these animals from the office server with more details
  // Animals downloaded from server should overwrite any animals added from this trick
  let newBatches: UploadableBatchSetup[] = await context.dispatch("dataManager/getUnUploadedBatchSetups", "new", { root: true });
  for (let batch of newBatches) {
    if (!batch.processingResult) continue;

    const processedAnimals = await context.dispatch("dataManager/getProcessedAnimals", { processingResultGuid: batch.processingResult.guid }, { root: true });

    //for (let processedAnimal of batch.processingResult.processedAnimals) {
    for (let processedAnimal of processedAnimals) {
      const a = await context.dispatch("dataManager/getAnimal", processedAnimal.sgtin);
      if (a) {
        continue; // If animal has already been added then skip
      }
      const data: any = {
        sgtin: processedAnimal.sgtin,
        visualSgtin: sgtinH.visual(processedAnimal.sgtin || ""),
        events: [{ time: processedAnimal.time, mass: processedAnimal.mass, type: "processing_new" }]
      }
      addAnimalsAsTheyDownload(data)
      await context.dispatch("dataManager/saveData", {
        objectStore: "Animal",
        data: data
      });
    }
  }
  ////////

  context.commit("timeDownloaded", context.state.timeDownloadStarted);
  context.commit("timeDownloadStarted", "");
  context.commit("busyDownloading", { what: "" });
};

type DownloadStatus = "done" | "busy" | "error" | "idle"

class SyncState {
  timeDownloaded: number | "" = "";
  timeDownloadStarted: number | "" = "";

  busyDownloading = {
    what: "",
    index: -1,
    amount: -1
  };

  busySavingAnimalsToDb: boolean = false;

  numMessages = 0;
  messages: string[] = [];
  state: "busy" | "idle" | "error" | "success" = "idle";
  downloadProgress: { [typename: string]: number } = {
    "Animal": 0
  };
  numDownloaded: {
    [typename: string]: number
  } = {
      Ailment: 0,
      Animal: 0,
      BatchDetails: 0,
      CustomFeeder: 0,
      Kraal: 0,
      Treatment: 0,
      Vaccination: 0,
    }
  downloadStatus: {
    [typename: string]: DownloadStatus
  } = {
      Ailment: "idle",
      Animal: "idle",
      BatchDetails: "idle",
      CustomFeeder: "idle",
      Kraal: "idle",
      Treatment: "idle",
      Vaccination: "idle",
    }
  dataExpiryHours: number = 24;
}

class Sync implements Module<SyncState, any> {
  namespaced = true;
  state: SyncState = new SyncState();
  mutations = {
    /*
    mutation(state: LogState, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    timeDownloaded(state: SyncState, payload: number | "") {
      state.timeDownloaded = payload;
    },
    timeDownloadStarted(state: SyncState, payload: number | "") {
      state.timeDownloadStarted = payload;
    },
    message(state: SyncState, payload: { message: string; append?: boolean }) {
      console.log(payload.message);
      if (!payload.append) {
        state.messages = [];
        state.numMessages = 0;
      }
      if (payload.message) {
        state.messages.push(`${++state.numMessages} - ${payload.message}`);
      }
      const MAX_MESSAGES = 5;
      if (state.messages.length > MAX_MESSAGES) {
        state.messages.shift()
      }
    },
    state(state: SyncState, payload: "busy" | "idle") {
      state.state = payload;
    },
    downloadProgress(state: SyncState, payload: { typename: string; progress: number }) {
      state.downloadProgress[payload.typename] = payload.progress;
    },
    downloadStatus(state: SyncState, payload: { typename: string; downloadStatus: DownloadStatus }) {
      state.downloadStatus[payload.typename] = payload.downloadStatus;
    },
    numDownloaded(state: SyncState, payload: { typename: string; numDownloaded: number; incremental?: boolean }) {
      if (!payload.incremental) {
        state.numDownloaded[payload.typename] = payload.numDownloaded;
      } else {
        state.numDownloaded[payload.typename] += payload.numDownloaded;
      }
    },
    busyDownloading(state: SyncState, payload: { what: string, index?: number, amount?: number }) {
      state.busyDownloading = {
        what: payload.what,
        index: payload.index ?? -1,
        amount: payload.amount ?? -1,
      }
    },
    clearNumMessages(state: SyncState) {
      state.numMessages = 0;
    },
    clear(state: SyncState) {
      state.timeDownloaded = "";
      state.timeDownloadStarted = "";
      state.busyDownloading = {
        what: "",
        index: -1,
        amount: -1,
      }
      state.numMessages = 0
      state.messages = [];
      state.state = "idle";
      state.downloadProgress = {
        "Animal": 0
      };
      state.numDownloaded = {
        Ailment: 0,
        Animal: 0,
        BatchDetails: 0,
        CustomFeeder: 0,
        Kraal: 0,
        Treatment: 0,
        Vaccination: 0,
      }
      state.downloadStatus = {
        Ailment: "idle",
        Animal: "idle",
        BatchDetails: "idle",
        CustomFeeder: "idle",
        Kraal: "idle",
        Treatment: "idle",
        Vaccination: "idle",
      }
    },
    dataExpiryHours(state: SyncState, payload: number) {
      state.dataExpiryHours = payload;
    },
    busySavingAnimalsToDb(state: SyncState, payload: boolean) {
      state.busySavingAnimalsToDb = payload;
    },
  };
  actions = {
    /*
    action(context: ActionContext<LogState, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async onAppCreated(context: any) {
      console.log("sync/onAppCreated");
      await init(context);
    },
    async sync(context: ActionContext<SyncState, any>) {
      //context.commit("syncDialog", true);
      context.commit("state", "busy");
      context.commit("message", { message: "", append: false });

      if (!["cloud", "local"].includes(context.rootState.rtcSignalling)) {
        context.commit("message", { message: "Not connected, can not download.", append: false });
        context.commit("state", "error");
        return;
      }

      const pluralize = (s: string) => s.endsWith("s") ? s : s + "s";

      try {
        //download from server
        context.commit("message", { message: "Connected to server\n", append: true });
        //await context.dispatch("downloadFromServer");
        const what = context.state.busyDownloading.what;
        if (what) {
          context.commit("message", { message: `Resuming download for ${pluralize(what)}`, append: true });
        }
        await download(context);
        context.commit("message", { message: "Data downloaded from server\n", append: true });
        context.commit("message", { message: "✔️ Download successful\n", append: true });
        context.commit("state", "success");
        router.push({ name: "home" });
      } catch (err) {
        context.commit("message", { message: "❌ Tried to download from server but failed:\n", append: true });
        context.commit("message", { message: `${JSON.stringify(err)}\n`, append: true });
        context.commit("state", "error");
        return;
      }
    }
  };
  getters = {
    /*
    getter(state: SyncState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    hasDataExpired(state: SyncState) {
      if (state.timeDownloaded === "") return undefined;
      return Date.now() > state.timeDownloaded + (state.dataExpiryHours * 60 * 60 * 1000)
    },
    hasData(state: SyncState) {
      const busyDownloading = state.timeDownloadStarted !== "";
      const busySavingAnimalsToDb = state.busySavingAnimalsToDb;
      const hasData = state.timeDownloaded !== "" && state.timeDownloadStarted === ""
      return hasData || (busyDownloading && busySavingAnimalsToDb);
    },
  };
}

export default new Sync();