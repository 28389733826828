import * as Models from "@gigalot/data-models";
import { ProcessingState } from "@/store/modules/processing";
import { ActionContext } from "vuex";

export class ProcessedAnimals {
  private isRestored: boolean = false;
  private promises: { resolve: any, reject: any; }[] = [];
  private processingResultGuid?: string; //guid of batch of current animals
  private processedAnimals: Models.ProcessedAnimal[] = []; //Current animals

  clear() {
    this.processedAnimals = [];
  }

  async add(pa: Models.ProcessedAnimal, processingResultGuid: string, context: ActionContext<ProcessingState, any>) {
    this.processedAnimals.push(pa);
    await context.dispatch("dataManager/saveData", { objectStore: "ProcessedAnimal", data: { processingResultGuid: processingResultGuid, ...pa } }, { root: true });
    //TODO: send to office-server and processing-server. don't throw exceptions if sending fails but try again later
  }

  setProcessingResultGuid(processingResultGuid: string) {
    this.processingResultGuid = processingResultGuid;
  }

  async restore(processingResultGuid: string, context: ActionContext<ProcessingState, any>) {
    console.log("ProcessedAnimals restore(), processingResultGuid:", processingResultGuid);
    try {
      this.processingResultGuid = processingResultGuid;
      this.processedAnimals = await context.dispatch("dataManager/getProcessedAnimals", { processingResultGuid: processingResultGuid }, { root: true });
      console.log("ProcessedAnimals restore() processedAnimals received.")
      this.isRestored = true;
      this.promises.map(({ resolve, reject }) => { resolve(this.processedAnimals); });
    } catch (err) {
      this.promises.map(({ resolve, reject }) => { reject(err); });
    }
  }

  async get(processingResultGuid?: string, context?: ActionContext<ProcessingState, any>): Promise<Models.ProcessedAnimal[]> {
    /*
      If no processingResultGuid given then return all current animals
      If processingResultGuid is given and it matches current guid then return current animals
      If some other processingResultGuid is given then get animals from data manager.
    */
    if (processingResultGuid === undefined || processingResultGuid === this.processingResultGuid) {
      return this.processedAnimals;
    } else {
      if (!context) throw Error("context required if processingResultGuid given.");
      return await context.dispatch("dataManager/getProcessedAnimals", { processingResultGuid: processingResultGuid }, { root: true });
    }
  }

  async getCurrentAnimals(): Promise<Models.ProcessedAnimal[]> {
    return new Promise(((resolve, reject) => {
      if (this.isRestored) {
        resolve(this.processedAnimals);
        return;
      } else {
        this.promises.push({ resolve, reject });
      }
    }));
  }
}
