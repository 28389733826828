import { default as Vuex, Module, ActionContext } from "vuex";
import lodash from "lodash";
import ReconnectingWebSocket from "reconnecting-websocket";

const vuexMapFields = require("vuex-map-fields");

export const { mapFields: mapAllflexFields } = vuexMapFields.createHelpers({
  getterType: "allflex/getField",
  mutationType: "allflex/updateField"
});

class AllflexState {
  connected: boolean = false;
  allflex: string = "";
}

let webSocket: ReconnectingWebSocket | undefined = undefined;

class Allflex implements Module<AllflexState, any> {
  namespaced = true;
  state: AllflexState = new AllflexState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    reset(state: AllflexState) {
      state.connected = false;
      state.allflex = "";
    },
    setConnected(state: AllflexState, connected: boolean) {
      state.connected = connected;
    },
    allflex(state: AllflexState, allflex: string) {
      state.allflex = allflex;
    },
    updateField: vuexMapFields.updateField
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    connect(context: ActionContext<AllflexState, any>) {
      webSocket = new ReconnectingWebSocket(context.rootGetters["settings/allflexAddress"]());
      webSocket.onopen = event => {
        context.commit("setConnected", true);
      };
      webSocket.onerror = event => {
        context.commit("setConnected", false);
        console.log("allflex webSocket error: ", event);
      };
      webSocket.onclose = event => {
        context.commit("setConnected", false);
      };
      webSocket.onmessage = message => {
        if (!context.state.connected) context.commit("setConnected", true);
        //console.log(message.data);
        //context.commit("addMass", +message.data);
        //addMass(context, +message.data);
        context.commit("allflex", message.data);
        console.log("allflex onmessage " + message.data);
      };
    },
    disconnect(context: ActionContext<AllflexState, any>) {
      if (webSocket) webSocket.close();
      context.commit("setConnected", false);
    }
  };
  getters = {
    /*
    getter(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    getField: vuexMapFields.getField
  };
}

export default new Allflex()
