
import { Component, Vue, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import AnimalEventHistory from "@/components/AnimalEventHistory.vue";
import * as Models from "@gigalot/data-models";
import { initializeChart, drawChart } from "../scale-graph";
import * as sgtinH from "@/helpers/sgtin";
import { mapFields } from "@/store";
import lodash from "lodash";

@Component({
  components: {
    DimssaButton,
    AnimalEventHistory,
    Keypress: () => import("vue-keypress"),
  },
  computed: {
    ...mapFields(["currentHospitalResult.morbidity.brokenNeedle"]),
  },
})
export default class Hospital extends Vue {
  animal: Models.Animal | "" = "";
  owner: string = "";

  notes = "";
  notesChange(notes: any) {
    this.notes = notes;
    this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.notes", value: [notes] });
  }

  hasRetag() {
    let currentHospitalResult = this.$store.state.currentHospitalResult;
    return currentHospitalResult && currentHospitalResult.morbidity && currentHospitalResult.morbidity.retag;
  }

  clear() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: () => {
        this.$store.commit("popup/hide");
        this.$store.commit("log/recent", "");
        this.finishButtonState = "ready";
        this.readTagButtonState = "ready";
        this.$store.commit("scale/reset");
        this.ailmentChange("");
        this.treatmentChange("");
        this.temperatureChange("");
        this.sourceKraalIdChange("");
        this.destinationKraalIdChange("");
        this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.sgtin", value: "" });
        this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.retag", value: undefined });
        this.determineVisualSgtin();
        this.animal = "";
        this.owner = "";
        this.gender = "";
        this.breed = "";
      },
    });
  }

  ailmentChange(ailment: any) {
    this.ailment = ailment;
    let ailments: Models.Ailment[] = [];
    if (ailment !== "") ailments = [ailment];
    this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.ailments", value: ailments });
  }
  ailment: Models.Ailment | "" = "";

  treatmentChange(treatment: any) {
    this.treatment = treatment;

    let appliedTreatments: Models.AppliedTreatment[] = [];
    if (treatment) {
      appliedTreatments.push(new Models.AppliedTreatment());
      appliedTreatments[0].treatment = treatment;
    }
    Vue.set(this.$store.state.currentHospitalResult.morbidity, "appliedTreatment", appliedTreatments);
    this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.appliedTreatment", value: appliedTreatments });
    this.determineAppliedDoses();
  }

  treatment: Models.Treatment | "" = "";

  temperatureChange(temperature: any) {
    temperature = temperature === "" || isNaN(temperature) ? undefined : parseFloat(temperature);
    //console.log(temperature);
    this.$store.commit("updateField", {
      path: "currentHospitalResult.morbidity.temperature",
      value: temperature,
    });
    this.temperature = temperature !== undefined ? temperature : "";
  }

  temperature: number | "" = "";

  sourceKraalIdChange(sourceKraalId: any) {
    this.sourceKraalId = sourceKraalId;
    this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.sourceKraalId", value: sourceKraalId === "" ? undefined : sourceKraalId });
  }

  sourceKraalId: Models.Kraal | "" = "";

  destinationKraalIdChange(destinationKraalId: any) {
    this.destinationKraalId = destinationKraalId;
    this.$store.commit("updateField", {
      path: "currentHospitalResult.morbidity.destinationKraalId",
      value: destinationKraalId === "" ? undefined : destinationKraalId,
    });
  }

  destinationKraalId: Models.Kraal | "" = "";

  gender: string = "";
  breed: string = "";

  scaleActivityToggle: boolean = false;
  currentMass: number | "" = "";
  hasFocus: { [ref: string]: boolean } = {};

  isInputInFocusOrZeroScalePopupDisplayed() {
    for (let b of Object.values(this.hasFocus)) if (b) return true;
    if (this.$store.state.scale.zeroScalePopup) return true;
    return false;
  }

  onKeyEscape() {
    for (let ref in this.hasFocus) (this.$refs[ref] as any).blur();
  }

  onKeyEnter() {
    for (let ref in this.hasFocus) (this.$refs[ref] as any).blur();
  }

  selectAilment() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    (this.$refs["select-ailment"] as any).focus();
    (this.$refs["select-ailment"] as any).activateMenu();
  }

  selectTreatment() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    (this.$refs["select-treatment"] as any).focus();
    (this.$refs["select-treatment"] as any).activateMenu();
  }

  enterTemperature() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    (this.$refs["enter-temperature"] as any).focus();
  }

  selectSourceKraal() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    (this.$refs["select-source-kraal"] as any).focus();
    (this.$refs["select-source-kraal"] as any).activateMenu();
  }

  selectHospitalKraal() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    (this.$refs["select-hospital-kraal"] as any).focus();
    (this.$refs["select-hospital-kraal"] as any).activateMenu();
  }

  captureMass() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    try {
      this.$store.commit("log/message", { message: "Capturing mass...", type: "info" });
      this.$store.dispatch("scale/captureMass");
    } catch (err) {
      this.$store.commit("log/message", { message: "Error: " + err.message, type: "error" });
    }
  }

  retagAllowed: boolean = false;
  readTagButtonState: ButtonState = "ready";
  // confirmTagButtonState: ButtonState = "ready";
  async readRfidTag() {
    if (this.isInputInFocusOrZeroScalePopupDisplayed()) return;
    this.retagAllowed = false;
    let currentHospitalResult = this.$store.state.currentHospitalResult;
    if (!this.hasRetag())
      //don't reset if retag exists
      this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.sgtin", value: "" });
    this.determineVisualSgtin();
    this.breed = "";
    this.gender = "";
    this.owner = "";
    this.readTagButtonState = "busy";
    // this.confirmTagButtonState = "disabled";
    try {
      this.$store.commit("log/message", { message: "Reading tag... ", type: "info" });
      let sgtin;
      try {
        sgtin = await this.$store.dispatch("readTag"); //readTag does error checking and throws exceptions
      } catch (err) {
        if (err && err.message && err.message.toLowerCase().includes("no tag found")) {
          this.retagAllowed = true;
        }
        throw err;
      }

      if (this.hasRetag()) {
        //animal has been retagged
        if (!currentHospitalResult.morbidity.sgtin) {
          throw Error(`Animal's retag tag has not yet been programmed.`);
        } else if (sgtin === currentHospitalResult.morbidity.sgtin) {
          this.determineVisualSgtin();
          this.readTagButtonState = "success";
          this.$store.commit("log/message", { message: `Tag read successfully.\n(${sgtin})`, type: "info" });
          this.breed = "Re-tag";
          this.gender = "Re-tag";
          this.owner = "Re-tag";
        } else {
          throw Error(`Animal has been retagged with ${sgtinH.visual(currentHospitalResult.morbidity.sgtin)} but got ${sgtinH.visual(sgtin)} instead.`);
        }
      } else {
        //not retagged
        Vue.set(this.$store.state.currentHospitalResult.morbidity, "sgtin", sgtin);
        this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.sgtin", value: sgtin });
        this.determineVisualSgtin();

        this.animal = (await this.$store.dispatch("dataManager/getAnimal", sgtin)) || "";
        if (!this.animal) throw Error(`Animal not recognized: ${sgtin}`);
        if (this.animal && this.animal.breed) this.breed = this.animal.breed;
        else this.breed = "N/A";
        if (this.animal && this.animal.gender)
          this.gender = this.animal.gender ? `${this.animal.gender[0].toUpperCase()}${this.animal.gender.slice(1).toLowerCase()}` : this.animal.gender;
        else this.gender = "N/A";
        if (this.animal && this.animal.customFeeder) this.owner = `${this.animal.customFeeder.name} ${this.animal.customFeeder.surname}`;
        else this.owner = "N/A";
        this.retagAllowed = false;

        this.readTagButtonState = "success";
        this.$store.commit("log/message", { message: `Tag read successfully.\n(${sgtin})`, type: "info" });
      }
    } catch (err) {
      this.readTagButtonState = "error";
      if (!(currentHospitalResult && currentHospitalResult.morbidity && currentHospitalResult.morbidity.retag))
        this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.sgtin", value: "" });
      this.determineVisualSgtin();
      this.$store.commit("log/message", { message: "Error reading tag: " + (err.message ? err.message : err), type: "error" });
    }
  }

  beforeCreate() {
    if (!this.$store.getters["getField"]("currentHospitalResult")) {
      let hospitalResult: Models.HospitalResult = new Models.HospitalResult(Date.now(), this.$store.getters["user/getUpstreamMetadata"]());
      hospitalResult.morbidity = new Models.Morbidity();
      this.$store.commit("updateField", {
        path: "currentHospitalResult",
        value: hospitalResult,
      });
    }
  }

  async created() {
    //reset sgtin, don't reset if retag exists
    if (!this.hasRetag()) this.$store.commit("updateField", { path: "currentHospitalResult.morbidity.sgtin", value: "" });

    this.$store.commit("log/recent", "");

    this.treatments = await this.$store.dispatch("dataManager/getData", { objectStore: "Treatment" });
    this.ailments = await this.$store.dispatch("dataManager/getData", { objectStore: "Ailment" });
    this.kraals = await this.$store.dispatch("dataManager/getData", { objectStore: "Kraal" });

    let morbidity = this.$store.getters["getField"]("currentHospitalResult").morbidity;

    //load treatment from vuex
    if (morbidity.ailments.length === 0) this.ailment = "";
    else this.ailment = morbidity.ailments[0];

    if (morbidity.appliedTreatment.length === 0) this.treatment = "";
    else this.treatment = morbidity.appliedTreatment[0].treatment;

    this.temperature = morbidity.temperature === undefined ? "" : morbidity.temperature;

    this.sourceKraalId = morbidity.sourceKraalId === undefined ? "" : morbidity.sourceKraalId;

    this.destinationKraalId = morbidity.destinationKraalId === undefined ? "" : morbidity.destinationKraalId;

    if (this.currentHospitalResult.morbidity?.notes.length) this.notes = this.currentHospitalResult.morbidity.notes[0];

    this.determineAppliedDoses();
    this.determineVisualSgtin();
  }

  async mounted() {
    this.$store.commit("scale/reset");
    await this.$store.dispatch("scale/connect");
    this.$store.commit("scale/massCallback", this.setMass);

    this.$store.commit("allflex/reset");
    this.$store.dispatch("allflex/connect");

    initializeChart(800);
  }

  destroyed() {
    this.$store.commit("scale/reset");
    this.$store.dispatch("scale/disconnect");
    this.$store.commit("scale/massCallback", undefined);

    this.$store.commit("allflex/reset");
    this.$store.dispatch("allflex/disconnect");
  }

  firstMassCaptured: boolean = false;

  delta: number = 250; //ms

  zeroScaleTimer() {
    this.$store.commit("scale/zeroScaleCurrentTime", this.$store.state.scale.zeroScaleCurrentTime + this.delta);

    if (!this.$store.state.scale.connected || this.currentMass === "" || Math.abs(this.currentMass) > 2) this.$store.commit("scale/zeroScaleCurrentTime", 0);

    if (this.$store.state.scale.zeroScaleCurrentTime > this.$store.state.scale.zeroScaleMaxTime) {
      this.$store.commit("scale/zeroScalePopup", false);
    } else lodash.debounce(this.zeroScaleTimer, this.delta)();
  }

  drawGraphCount = 0;
  drawLightCount = 0;

  setMass(mass: number) {
    if (!this.firstMassCaptured) {
      this.firstMassCaptured = true;
      //check if scale has been zeroed
      if (Math.abs(mass) > 2 && mass < this.$store.state.scale.minMass) {
        //if (mass < this.$store.state.scale.minMass) {
        this.$store.commit("scale/zeroScalePopup", true);
        this.$store.commit("scale/zeroScaleCurrentTime", 0);
        lodash.debounce(this.zeroScaleTimer)();
      }
    }

    this.currentMass = mass;

    let scaleMassText = document.getElementById("scaleMassText");
    if (scaleMassText) scaleMassText.innerHTML = `${mass} kg`;

    this.drawGraphCount++;
    if (this.drawGraphCount >= 2) {
      this.drawGraphCount = 0;
      let scale = this.$store.state.scale;
      let hLines = [{ value: scale.minMass, colour: "grey", id: "horizontal-graph-line" }];
      if (scale.massCaptureState === "captured") hLines.push({ value: scale.capturedMass, colour: "lime", id: "min-graph-line" });
      drawChart(this.$store.getters["scale/historyMassQueue"](), hLines, scale.massQueueSize);
    }

    this.drawLightCount++;
    if (this.drawLightCount >= 10) {
      this.drawLightCount = 0;
      this.scaleActivityToggle = !this.scaleActivityToggle;
      let scaleActivityCircle = document.getElementById("scaleActivityCircle");
      if (scaleActivityCircle) (scaleActivityCircle as any).attributes.fill.nodeValue = this.scaleActivityColour;
    }
  }

  finishButtonState: ButtonState = "ready";

  get morbidityComplete() {
    return (
      this.morbidity.ailments.length &&
      this.morbidity.appliedTreatment.length &&
      this.morbidity.sourceKraalId &&
      this.morbidity.destinationKraalId &&
      this.$store.state.scale.capturedMass !== undefined &&
      this.morbidity.sgtin
    );
  }

  checkMorbidityCompleteness() {
    if (this.morbidity.ailments.length === 0) throw Error("No ailment selected");
    if (this.morbidity.appliedTreatment.length === 0) throw Error("No treatment selected");
    //if (this.morbidity.temperature === undefined) throw Error("No temperature entered");
    if (!this.morbidity.sourceKraalId) throw Error("No 'from' kraal selected");
    if (!this.morbidity.destinationKraalId) throw Error("No hospital kraal selected");
    if (this.$store.state.scale.capturedMass === undefined) throw Error("Mass not captured");
    if (!this.morbidity.sgtin) throw Error("UHF Tag not read");
    if (this.readTagButtonState !== "success") throw Error("Tag was not read successfully");
  }

  async finish() {
    //if nothing has been filled in then just leave view
    if (
      !this.ailment &&
      !this.treatment &&
      !this.sourceKraalId &&
      !this.destinationKraalId &&
      !(this.currentHospitalResult.morbidity && this.currentHospitalResult.morbidity.sgtin)
    ) {
      this.$router.push({ name: "home" });
      return;
    }
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: async () => {
        this.$store.commit("popup/hide");
        try {
          this.finishButtonState = "busy";

          this.checkMorbidityCompleteness(); //throws exceptions if morbidity is not yet ready

          if (this.currentHospitalResult.morbidity) {
            this.currentHospitalResult.morbidity.mass = this.$store.state.scale.capturedMass;
            this.currentHospitalResult.morbidity.station = this.$store.state.processingStation;
            this.currentHospitalResult.morbidity.time = Date.now();
          } else throw Error("this.currentHospitalResult.morbidity undefined when trying to finish() hospital");
          await this.$store.dispatch("dataManager/saveData", { data: this.currentHospitalResult, objectStore: "HospitalResult" });
          this.$store.commit("numUnUploadedItems", "increment");
          this.$store.dispatch("upload/upload");

          this.$store.commit("updateField", { path: "currentHospitalResult", value: undefined });
          this.notes = "";
          this.finishButtonState = "success";
          this.$router.push({ name: "home" });
        } catch (err) {
          this.finishButtonState = "error";
          this.$store.commit("log/message", { message: "Error: " + (err.message ? err.message : err), type: "error" });
        }
      },
    });
  }

  determineAppliedDoses() {
    console.log("determineAppliedDoses");

    if (!this.currentHospitalResult.morbidity) return;
    if (this.currentHospitalResult.morbidity.appliedTreatment.length === 0) return;
    let appliedTreatment = this.currentHospitalResult.morbidity.appliedTreatment[0];
    let treatment = appliedTreatment.treatment;
    let mass = this.$store.state.scale.capturedMass;
    if (!treatment) {
      Vue.set(this.$store.state.currentHospitalResult.morbidity, "appliedTreatment", []);
      this.$store.commit("updateField", {
        path: "currentHospitalResult.morbidity.appliedTreatment",
        value: [],
      });
      return;
    }

    appliedTreatment.appliedDoses = [];
    if (mass !== undefined) {
      for (let dose of treatment.doses) {
        let appliedDose = new Models.AppliedDose();
        if (!dose.item) throw Error(`${treatment.description} has a dose that has no dispensary item`);
        appliedDose.item = dose.item;
        appliedDose.unit = dose.unit;
        if (!dose.amount) {
          throw Error(`${treatment.description} has a dose that does not have amount set`);
        }
        if (dose.type === "cc-mass-dependent") {
          if (!dose.kgPerDose) throw Error(`${treatment.description} has a dose that is cc-mass-dependent but does not have kgPerDose set`);
          //TODO: Math.ceil might not work for other feedlots. So far this is good for Sernick. We might need this to be configurable
          appliedDose.actualDose = Math.ceil((mass / dose.kgPerDose) * dose.amount);
        } else {
          appliedDose.actualDose = dose.amount;
        }
        appliedTreatment.appliedDoses.push(appliedDose);
      }
    }

    Vue.set(this.$store.state.currentHospitalResult.morbidity, "appliedTreatment", [appliedTreatment]);
    this.$store.commit("updateField", {
      path: "currentHospitalResult.morbidity.appliedTreatment",
      value: [appliedTreatment],
    });

    if (this.morbidity.appliedTreatment.length === 0) this.doses = [];
    else this.doses = this.morbidity.appliedTreatment[0].treatment.doses;

    if (this.morbidity.appliedTreatment.length === 0) this.appliedDoses = [];
    else this.appliedDoses = this.morbidity.appliedTreatment[0].appliedDoses;
  }

  @Watch("$store.state.scale.capturedMass")
  onCapturedMassChanged(newVal: any, oldVal: any) {
    if (!isNaN(newVal)) this.$store.commit("log/message", { message: "Mass captured successfully.", type: "info" });
    this.determineAppliedDoses();
  }

  doses: Models.Dose[] = [];

  get doseTypes(): { [doseDescription: string]: "cc-mass-dependent" | "constant" } {
    let ret: { [doseDescription: string]: "cc-mass-dependent" | "constant" } = {};
    for (let dose of this.doses) {
      if (dose.item && dose.type) ret[dose.item.description] = dose.type;
      else throw Error("TODO: fix error handling for when dose not found");
    }
    return ret;
  }

  appliedDoses: Models.AppliedDose[] = [];

  get appliedDosesMassDependent() {
    return this.appliedDoses.filter((ad) => this.doseTypes[ad.item.description] === "cc-mass-dependent");
  }

  get appliedDosesMassConstant() {
    return this.appliedDoses.filter((ad) => this.doseTypes[ad.item.description] === "constant");
  }

  get scaleActivityColour() {
    return this.scaleActivityToggle ? "lime" : "white";
  }

  get moment() {
    return this.$store.state.moment;
  }

  get colClass() {
    return this.$store.state.lightDarkMode === "light" ? "grey lighten-1" : "grey darken-4";
  }

  treatments: Models.Treatment[] = [];
  ailments: Models.Ailment[] = [];
  kraals: Models.Kraal[] = [];

  @Watch("$store.state.scale.massCaptureState")
  massCaptureStateChange(newVal: any, oldVal: any) {
    if (newVal === "failed") this.$store.commit("log/message", { message: "Failed to capture mass.", type: "error" });
  }

  get captureMassButtonState(): ButtonState {
    if (!this.$store.state.scale.connected) return "error";

    switch (this.$store.state.scale.massCaptureState) {
      case "not-capturing":
        return "ready";
      case "capturing":
        return "busy";
      case "captured":
        return "success";
      case "failed":
        return "error";
      default:
        return "ready";
    }
  }

  get currentHospitalResult(): Models.HospitalResult {
    return this.$store.getters["getField"]("currentHospitalResult");
  }

  get morbidity(): Models.Morbidity {
    if (!this.currentHospitalResult.morbidity) throw Error("get morbidity() Error: currentHospitalResult has no morbidity");
    return this.currentHospitalResult.morbidity;
  }

  visualSgtin: string = "";
  determineVisualSgtin() {
    let sgtin = this.$store.getters["getField"]("currentHospitalResult.morbidity.sgtin");
    if (!sgtin) this.visualSgtin = "";
    else this.visualSgtin = sgtinH.visual(sgtin);
  }
}
