
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as Models from "@gigalot/data-models";

@Component
export default class AnimalDraftIcons extends Vue {
  state?: string = "Load"; // "Load", "Weigh", "Send"
  dark: boolean = true;
  @Watch("$store.state.lightDarkMode")
  OnDarkChanged(newVal: any, oldVal: any) {
    if (newVal === "dark") this.dark = true;
    else this.dark = false;
  }
  @Watch("$store.state.drafting.draftState")
  OnStateChanged(newVal: any, oldVal: any) {
    this.state = newVal;
  }
}
